<script lang="ts">
    import Dialog, { Title as DTitle, Content as Content, Actions, InitialFocus } from "@smui/dialog";
    import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
    import Button, { Label } from "@smui/button";
    import Switch from "@smui/switch";
    import { Icon } from "@smui/icon-button";
    import Textfield from "@smui/textfield";
    import Checkbox from "@smui/checkbox";
    import FormField from "@smui/form-field";
    import { mdiMagnify, mdiClose } from "@mdi/js";
    import ConfirmationDialog from "../../dialogs/ConfirmationDialog.svelte";

    import { _ } from "svelte-i18n";

    import { createEventDispatcher } from "svelte";

    export let items = [];
    export let open;
    export let title;
    export let headerColors = "tescoblue white-text";
    export let big = false;
    export let suppliersFetching = false;

    let checkedAll = false;
    let tescoTeamChecked = false;
    let confirmDialogOpen = false;
    let confirmDialogText = "";
    let confirmDialogValueConfirmable = 0;
    let confirmDialogTitle = "";

    const dispatch = createEventDispatcher();

    // Filter
    let supplierSearch = null;
    let filteredItems = [];

    // Changes
    $: console.log({ items });
    $: buttonSaveEnabled = [...items].filter((x) => x.send).length > 0 /* || tescoTeamChecked */;
    $: console.log({ suppliersFetching });

    $: tableHead = [
        { columnId: "supplier", label: "supplier", numeric: false, extraClasses: "" },
        //{ columnId: "supplierName", label: "supplier_name", numeric: false, extraClasses: " text-left " },
        { columnId: "status", label: "status", numeric: false, extraClasses: " text-left " },
        { columnId: "send", label: "send", numeric: false, extraClasses: " text-center " },
    ];

    $: filteredItems = items?.filter((item) => {
        return item.supplierId?.toString().includes(supplierSearch) || (item.supplierName ?? "").toLowerCase().includes(supplierSearch?.toLowerCase() ?? "");
    });

    function checkUncheckAll() {
        console.log("================= checkUncheckAll ==============");
        console.log(checkedAll);
        filteredItems.forEach((i) => {
            console.log({ i });
            i.send = !checkedAll;
        });
        filteredItems = filteredItems;
    }

    function clickedSendDS() {
        console.log("================= clickedSendDS ==============");
        let data = [...items].filter((x) => x.send).map((i) => ({ supplierId: i.supplierId, lastPurchaseHistId: i.lastPurchaseHistId }));
        open = false;
        confirmDialogOpen = true;
        confirmDialogText = "do_you_want_to_send_ds_to_suppliers";
        confirmDialogValueConfirmable = data.length;
        confirmDialogTitle = "send_ds";
        console.log("================= END - clickedSendDS ==============");
    }

    function confirmSendDS() {
        console.log("================= confirmSendDS ==============");
        let data = [...items].filter((x) => x.send).map((i) => ({ supplierId: i.supplierId, lastPurchaseHistId: i.lastPurchaseHistId }));
        if (tescoTeamChecked) {
            data.push({ supplierId: -1, lastPurchaseHistId: null });
        }
        dispatch("sendDS", data);
    }
</script>

<Dialog
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="data-changed-title"
    aria-describedby="data-changed-content"
    class="pa-2"
    surface$style={big ? "width: 850px; max-width: calc(100vw - 32px);" : ""}
>
    <DTitle id="data-changed-title" class={headerColors}>{title}</DTitle>
    <Content class="pb-0">
        <div>
            <DataTable stickyHeader table$aria-label="CE Products list" style="width: 100%;" class="report mt-4">
                <Head>
                    <Row>
                        {#each tableHead as head (head.columnId)}
                            <Cell numeric={head.numeric} columnId={head.columnId} class="datatable-header {head.extraClasses}" colspan={head.columnId == "supplier" ? 2 : 1}>
                                {$_(head.label)}
                            </Cell>
                        {/each}
                    </Row>
                    <Row>
                        <Cell columnId="supplier" class="datatable-header" colspan="2">
                            <Textfield
                                variant="outlined"
                                bind:value={supplierSearch}
                                maxlength="50"
                                placeholder=""
                                class="white w-100 small flex-1 pl-1"
                                input$class="mnw-100"
                                size="40"
                            >
                                {#if supplierSearch && supplierSearch > ""}
                                    <Icon
                                        tag="svg"
                                        viewBox="0 0 24 24"
                                        class="gray-text text-field-leading-icon mr-1"
                                        slot="leadingIcon"
                                        on:click={() => {
                                            supplierSearch = null;
                                        }}
                                    >
                                        <path fill="grey" d={mdiClose} />
                                    </Icon>
                                {:else}
                                    <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon mr-1" slot="leadingIcon">
                                        <path fill="grey" d={mdiMagnify} />
                                    </Icon>
                                {/if}
                            </Textfield>
                        </Cell>
                        <Cell class="datatable-header"></Cell>
                        <Cell class="datatable-header text-center">
                            <Switch bind:checked={checkedAll} on:click={checkUncheckAll} />
                        </Cell>
                    </Row>
                </Head>
                <Body>
                    {#if filteredItems.length > 0}
                        {#each filteredItems as row (row.supplierId)}
                            <Row>
                                <Cell class="text-center">{row.supplierId}</Cell>
                                <Cell class="text-left">{row.supplierName}</Cell>
                                <Cell class="text-left">{$_(row.status)}</Cell>
                                <Cell class="text-center">
                                    <Switch disabled={row.disabled} bind:checked={row.send} />
                                </Cell>
                            </Row>
                        {/each}
                    {:else}
                        <Row class="datatable-content">
                            <Cell colspan="10" class="text-center" style="width: 100%">{$_("no_data_found")}</Cell>
                        </Row>
                    {/if}
                </Body>
            </DataTable>

            <FormField class="mt-2">
                <Checkbox bind:checked={tescoTeamChecked} />
                <span slot="label">{$_("send_ds_to_tesco_team")}</span>
            </FormField>
        </div>
    </Content>

    <Actions class="pr-4 pb-4 gap-1 space-around">
        <Button action="close" class="tescored tescored-text outlined" on:click={() => dispatch("cancel")}>
            <Label>{$_("cancel")}</Label>
        </Button>
        <Button action="save" use={[InitialFocus]} class={buttonSaveEnabled ? "tescoblue white-text" : "grey white-text"} disabled={!buttonSaveEnabled} on:click={clickedSendDS}>
            <Label>{$_("send")}</Label>
        </Button>
    </Actions>
</Dialog>

<ConfirmationDialog
    open={confirmDialogOpen}
    title={$_(confirmDialogTitle)}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={true}
    itemData={null}
    headerColors="tescoblue white-text"
    on:reject={() => {
        confirmDialogOpen = false;
        open = true;
    }}
    on:confirm={() => {
        confirmDialogOpen = false;
        confirmSendDS();
    }}
>
    {$_(confirmDialogText, { values: { supplierCount: confirmDialogValueConfirmable } })}
</ConfirmationDialog>
