<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import Button, { Label } from "@smui/button";
    import Dialog, { Title as DTitle, Content as DContent, Actions, InitialFocus } from "@smui/dialog";
    import { _ } from "svelte-i18n";
    import type { SuppRmsLink, UserManagementContact } from "../../../../types/Management";

    import DialogRmsSuppDetail from "./DialogRmsSuppDetail.svelte";
    import DialogRmsSuppContact from "./DialogRmsSuppContact.svelte";
    export let presentCountries;
    export let suppRmsData: SuppRmsLink = {
        id: 0,
        supplierId: 0,
        supplierName: "",
        country: "",
        rmsSupplierNo: 0,
        rmsSupplierName: "",
        currency: "",
        status: 0,
        orgContact: [],
        contact: [],
        supplierDetails: [],
        tradingPartnerId: "",
    };

    let contacts: UserManagementContact[] = [];

    export let open = false;
    export let title = "";
    export let noBtnText = "cancel";
    export let yesBtnText = "add";
    export let headerColors = "tescoblue white-text";
    export let big = false;
    export let dataValid = false;
    export let suppRmsDialogType = "edit";
    export let subOpen = false;

    let selectedContacts: UserManagementContact[] = [];

    const dispatch = createEventDispatcher();

    function confirmationCloseHandler(e: CustomEvent<{ action: string }>) {
        if (!suppRmsData.supplierDetails) {
            suppRmsData.supplierDetails.length = 0;
        }

        if (selectedContacts && selectedContacts?.length > 0) {
            const newContacts = selectedContacts.map((contact) => ({
                id: null,
                supplierId: suppRmsData?.supplierId ? suppRmsData?.supplierId : null,
                supplierLinkId: suppRmsData?.id ? suppRmsData?.id : null,
                uuid: contact.uuid,
                email: contact.email,
                firstName: contact.firstName,
                lastName: contact.lastName,
                roleID: contact.roleID,
                roleCode: contact.roleCode,
            }));
            suppRmsData.supplierDetails = [...suppRmsData.supplierDetails, ...newContacts];
            selectedContacts.length = 0;
            contacts = [];
        } else {
            console.log("Nothing to add");
            selectedContacts.length = 0;
            contacts = [];
        }
    }
</script>

<Dialog
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    fullscreen
    aria-labelledby="add-supplier-contact"
    aria-describedby="supplier-contact-data"
    class="pa-2"
    surface$style={big ? "width: 875px; max-width: calc(100vw - 32px);" : ""}
>
    <DTitle id="add-supplier-rms-contact" class={headerColors}>{title}</DTitle>

    <DContent id="supplier-rms-contact-data" class="mt-4">
        <DialogRmsSuppDetail bind:suppRmsData bind:dataValid {suppRmsDialogType} {presentCountries} bind:subOpen />
    </DContent>
    <Actions class="pr-4 gap-1 space-around">
        <Button action="close" class="tescored tescored-text outlined" on:click={() => dispatch("reject")}>
            <Label>{noBtnText}</Label>
        </Button>
        <Button
            action="save"
            defaultAction
            use={[InitialFocus]}
            disabled={!dataValid}
            class={dataValid ? "tescoblue white-text" : "grey white-text"}
            on:click={() => dispatch("confirm", suppRmsData)}
        >
            <Label>{yesBtnText}</Label>
        </Button>
    </Actions>

    <Dialog
        bind:open={subOpen}
        slot="over"
        scrimClickAction=""
        escapeKeyAction=""
        aria-labelledby="over-fullscreen-confirmation-title"
        aria-describedby="over-fullscreen-confirmation-content"
        surface$style={big ? "width: 875px; max-width: calc(100vw - 32px);" : ""}
        on:SMUIDialog:closed={confirmationCloseHandler}
    >
        <DTitle id="over-fullscreen-confirmation-title" class={headerColors}>{$_("add_supplier_rms_contact")}</DTitle>

        <DContent id="over-fullscreen-confirmation-content">
            <DialogRmsSuppContact {suppRmsData} bind:selectedContacts bind:contacts />
        </DContent>
        <Actions>
            <Button action="cancel" class="tescored tescored-text outlined">
                <Label>{$_("cancel")}</Label>
            </Button>
            <Button action="accept" class={selectedContacts.length > 0 ? "tescoblue white-text" : "grey white-text"} disabled={!selectedContacts.length}>
                <Label>{$_("add")}</Label>
            </Button>
        </Actions>
    </Dialog>
</Dialog>
