<script>
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";
    import Flex from "svelte-flex";
    import Textfield from "@smui/textfield";
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiBackspaceOutline, mdiDownloadCircleOutline } from "@mdi/js";
    import FormField from "@smui/form-field";
    import Checkbox from "@smui/checkbox";

    import CurrencyFilterBar from "../../common/CurrencyFilterBar.svelte";

    export let currencySearchArray = [];
    export let validFromSearch = "";
    export let validToSearch = "";
    export let validNow = false;

    let excludeCur = ["EUR"]; // Exclude EUR

    const dispatch = createEventDispatcher();
</script>

<Flex direction="row" justify="start" align="center" class="w-100 gray lighten-4 h-100 gap-05">
    <Flex direction="row" justify="start" align="center" class="flex-wrap gap-05">
        <CurrencyFilterBar bind:selection={currencySearchArray} title={$_("currency")} size="15" multiselect {excludeCur} />

        <Textfield
            variant="outlined"
            type="date"
            title={$_("valid_from")}
            bind:value={validFromSearch}
            validateOnValueChange
            useNativeValidation
            updateInvalid
            input$emptyValueUndefined
            input$autocomplete="valid_from"
            prefix="{$_('valid_from')}: "
            class="white small"
            on:click={() => {
                validNow = false;
            }}
        />

        <Textfield
            variant="outlined"
            type="date"
            title={$_("valid_to")}
            bind:value={validToSearch}
            validateOnValueChange
            useNativeValidation
            updateInvalid
            input$emptyValueUndefined
            input$autocomplete="valid_to"
            prefix="{$_('valid_to')}: "
            class="white small"
            on:click={() => {
                validNow = false;
            }}
        />
    </Flex>

    <FormField>
        <Checkbox
            bind:checked={validNow}
            touch
            on:click={() => {
                validFromSearch = "";
                validToSearch = "";
            }}
        />
        <span slot="label">{$_("currently_valid_exchange")}</span>
    </FormField>

    <Button
        title={$_("clear")}
        class="tescoblue white-text"
        on:click={() => {
            currencySearchArray.length = 0;
            validFromSearch = "";
            validToSearch = "";
            validNow = false;
        }}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="pa-0 ma-0">
            <path fill="currentColor" d={mdiBackspaceOutline} />
        </Icon>
    </Button>

    <Button
        class="tescoblue white-text pr-4"
        title={$_("search")}
        on:click={() => {
            dispatch("loadList", {
                currencySearchArray: currencySearchArray,
                validFromSearch: validFromSearch,
                validToSearch: validToSearch,
                validNow: validNow,
            });
        }}
    >
        <svg viewBox="0 0 24 24" width="20" height="20">
            <path fill="currentColor" d={mdiDownloadCircleOutline} />
        </svg>
        {$_("search")}
    </Button>
</Flex>
