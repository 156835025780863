<script>
    import { createEventDispatcher } from "svelte";
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiDownloadCircleOutline } from "@mdi/js";
    import { _ } from "svelte-i18n";

    const dispatch = createEventDispatcher();
</script>

<Button class="tescoblue white-text pr-4" title={$_("show_report")} data-qa="ds-show-report-btn" style="min-width:fit-content;" on:click={() => dispatch("get")}>
    <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
        <path fill="currentColor" d={mdiDownloadCircleOutline} />
    </Icon>
    {$_("show")}
</Button>
