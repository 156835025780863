<script lang="ts">
  import Checkbox from "@smui/checkbox";
  import { _ } from "svelte-i18n";
  import DataTable, { Head, Body, Row, Cell, Pagination, Label } from "@smui/data-table";
  import CircularProgress from "@smui/circular-progress";
  import Select, { Option } from "@smui/select";
  import IconButton from "@smui/icon-button";
  import type { ProductsForDelegation } from "../../../../types/Management";

  export let productItemList: ProductsForDelegation[] = [];
  export let selectedProducts: ProductsForDelegation[] = [];
  export let tableLoad = false;
  export let tableSearchMessage = false;

  // Pagging
  let rowsPerPage = 10;
  let currentPage = 0;

  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, productItemList.length);
  $: slice = productItemList.slice(start, end);
  $: lastPage = Math.max(Math.ceil(productItemList.length / rowsPerPage) - 1, 0);
  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }
</script>

<DataTable stickyHeader table$aria-label="Products for delegation" style="width: 100%;">
  <Head>
    <Row>
      <Cell columnId="checkbox" class="datatable-header pl-0 pr-0 sticky-col" checkbox>
        <Checkbox />
      </Cell>
      <Cell columnId="id" class="datatable-header">
        <Label>{$_("id")}</Label>
      </Cell>
      <Cell columnId="description" class="datatable-header">
        <Label>{$_("description")}</Label>
      </Cell>
    </Row>
  </Head>
  <Body>
    {#if tableLoad}
      <Row class="datatable-content">
        <Cell colspan="10" class="text-center">
          <CircularProgress style="height: 32px; width: 32px; margin-right: 1rem;" indeterminate />
          {$_("loading_wait")}
        </Cell>
      </Row>
    {:else if slice.length > 0}
      {#each slice as delegateRow (delegateRow.id)}
        <Row>
          <Cell class="pl-0 pr-0 sticky-col" checkbox>
            <Checkbox bind:group={selectedProducts} value={delegateRow} valueKey={delegateRow.id} />
          </Cell>
          <Cell>{delegateRow.id}</Cell>
          <Cell>
            {delegateRow.description}
          </Cell>
        </Row>
      {/each}
    {:else if tableSearchMessage}
      <Row class="datatable-content">
        <Cell colspan="10" class="text-center" style="width: 100%">{$_("delegated_user_without_products")}</Cell>
      </Row>
    {:else}
      <Row class="datatable-content">
        <Cell colspan="10" class="text-center" style="width: 100%">{$_("user_country_not_selected")}</Cell>
      </Row>
    {/if}
  </Body>

  <Pagination slot="paginate">
    <svelte:fragment slot="rowsPerPage">
      <Label>{$_("rows_per_page")}</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        <Option value={10}>10</Option>
        <Option value={25}>25</Option>
        <Option value={100}>100</Option>
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      {start + 1}-{end} of {productItemList.length}
    </svelte:fragment>

    <IconButton class="material-icons" action="first-page" title={$_("first_page")} on:click={() => (currentPage = 0)} disabled={currentPage === 0}>first_page</IconButton>
    <IconButton class="material-icons" action="prev-page" title={$_("prev_page")} on:click={() => currentPage--} disabled={currentPage === 0}>chevron_left</IconButton>
    <IconButton class="material-icons" action="next-page" title={$_("next_page")} on:click={() => currentPage++} disabled={currentPage === lastPage}>chevron_right</IconButton>
    <IconButton class="material-icons" action="last-page" title={$_("last_page")} on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}
      >last_page</IconButton
    >
  </Pagination>
</DataTable>
