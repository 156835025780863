<script lang="ts">
    import DataTable, { Head, Body, Row, Cell } from "@smui/data-table";
    import Button from "@smui/button";
    import IconButton, { Icon } from "@smui/icon-button";
    import { mdiPencilBoxOutline, mdiDelete } from "@mdi/js";
    import Tooltip, { Wrapper, Content } from "@smui/tooltip";
    import { _ } from "svelte-i18n";
    import Flex from "svelte-flex";
    import Switch from "@smui/switch";
    import CircularProgress from "@smui/circular-progress";

    import ConfirmationDialog from "../../../dialogs/ConfirmationDialog.svelte";
    import ConfirmationDialogOver from "./ConfirmationDialogOver.svelte";
    import DTInputCell from "../../common/DTInputCell.svelte";

    import { onMount } from "svelte";
    import { getSupplierLink, getContact, deleteRow, createRow, updateRow } from "../../../../api/Supplier";
    import { snackbarSuccess, snackbarWarning } from "../../../../stores/AppStatus";
    import { handleApiError } from "../../lib/errorHandler";

    import type { SuppRmsLink, CeSupplier } from "../../../../types/Management";

    let suppRmsData: SuppRmsLink = {
        id: 0,
        supplierId: 0,
        supplierName: "",
        country: "",
        rmsSupplierNo: 0,
        rmsSupplierName: "",
        currency: "",
        status: 0,
        orgContact: [],
        contact: [],
        supplierDetails: [],
        tradingPartnerId: "",
    };
    export let ceSuppData: CeSupplier = {
        id: 0,
        name: "",
        status: 0,
    };

    // All linked suppliers RMS to the Supplier CE
    let rmsLink: SuppRmsLink[] = [];
    export let onClose;
    export let backClicked = false;
    export let ceSupplierDialogType;

    let subOpen = false;
    let loadSupplierLinkData = false;
    let rmskDataCheck = false;
    let buttonSaveDisabled = true;

    let suppRmsDialogType = "edit";
    let suppRmsDialogOpen = false;

    // Tooltip position fix
    let tooltipXpos;
    let tooltipYpos;

    // Fetch data all RMS suppliers linked to CE supplier
    onMount(() => {
        if (ceSupplierDialogType === "edit") {
            loadSupplierLinkData = true;

            getSupplierLink(null, ceSuppData.id)
                .then((suppLinkResponse) => {
                    rmsLink = suppLinkResponse;
                })
                .catch((err) => {
                    console.error("Error fetching getSupplierLink:", err);
                    if (err?.cause?.response?.status == 401) {
                        throw err;
                    }
                });

            // Time needed to ensure data is loaded in DataTable
            setTimeout(function () {
                loadSupplierLinkData = false;
            }, 350);
        }
    });

    // New Supp Dialog
    let presentCountries = {};
    let isMissingCountry;
    const requiredCountries = new Set(["CZ", "SK", "HU"]);

    const openRmsDialog = (item = null, type) => {
        console.log("openRmsDialog");
        // Dialog type
        type = item ? "edit" : "add";
        presentCountries = [];
        if (type === "add") {
            presentCountries = new Set(rmsLink.map((link) => link.country));
            isMissingCountry = [...requiredCountries].some((country) => !presentCountries.has(country));
            console.log("openRmsDialog", { presentCountries });
            // Open dialog if rmsLink is empty or a country is missing
            if (rmsLink.length === 0 || isMissingCountry) {
                suppRmsDialogOpen = true;
            } else {
                // All countryes are already set
                $snackbarWarning.text = $_("supp_rms_link_warning");
                $snackbarWarning.element && $snackbarWarning.element.open();
                suppRmsDialogOpen = false;
                presentCountries = {};
            }
        } else {
            suppRmsDialogOpen = true;
        }
        // Setup dialog data
        if (suppRmsDialogOpen) {
            if (item) {
                suppRmsData = {
                    ...item,
                    orgContact: item.supplierDetails || [],
                };
            } else {
                suppRmsData = {
                    id: 0,
                    supplierId: ceSuppData.id,
                    supplierName: "",
                    country: "",
                    rmsSupplierNo: null,
                    rmsSupplierName: "",
                    currency: "",
                    status: null,
                    orgContact: [],
                    contact: [],
                    supplierDetails: [],
                    tradingPartnerId: "",
                };
            }
        }
    };

    // CE Status change
    let isActive = ceSuppData.status === 1;
    $: {
        let newStatus = isActive ? 1 : 0;
        ceSuppData = { ...ceSuppData, status: newStatus };
    }

    // Delete dialog
    let showConfirmationDialog = false;
    let confirmationDialogQuestion = "";
    type ConfDialog = { id: number; contact: [] };
    let confirmationDialogData: ConfDialog = { id: 0, contact: [] };

    const showDeleteRowConfirmation = (input) => {
        confirmationDialogData = { id: input.id, contact: input.contact };
        confirmationDialogQuestion = $_("do_you_want_delete_rms_supplier_link", {
            values: { country: input.country, id: input.rmsSupplierNo, name: input.rmsSupplierName },
        });
        showConfirmationDialog = true;
    };

    const deleteRmsRow = async (id) => {
        console.log("delete", confirmationDialogData, id);
        if (confirmationDialogData && id) {
            const deleteContacts = async () => {
                // Iterate over each contact in the confirmationDialogData.contact array
                for (const contact of confirmationDialogData.contact) {
                    try {
                        console.log("Deleting contact", contact.id);
                        // Delete each contact by its ID
                        await deleteRow("supplier/contact", contact.id);
                    } catch (error) {
                        console.error("Error deleting contact:", error);
                        // Throw an error to halt the process
                        if (error?.cause?.response?.status == 401) {
                            throw error;
                        }
                        throw new Error(`Failed to delete contact ${contact.id}: ${error.message}`);
                    }
                }
            };

            const deleteRmsLink = async () => {
                try {
                    // Delete supplier link from db
                    await deleteRow("supplier/link", id);
                    // Delete from screen
                    rmsLink = rmsLink.filter((x) => x.id !== id);

                    $snackbarSuccess.text = $_("supp_link_deleted");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                } catch (error) {
                    console.log(error);
                    if (!handleApiError($_("supp_link_deleted_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                }
            };

            try {
                // First, delete all contacts if any
                if (confirmationDialogData.contact.length > 0) {
                    await deleteContacts();
                }
                // Then, delete the RMS link
                await deleteRmsLink();
            } catch (error) {
                console.error("Error in the deletion process:", error);
                if (!handleApiError($_("deletion_process_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            }
        }
    };

    function handleRmsStatusChange(rmsSupplier) {
        rmsSupplier.status = rmsSupplier.status === 1 ? 0 : 1;
        console.log("RMS status change", rmsSupplier);

        // Update RMS supplier
        updateRow("supplier/link", rmsSupplier)
            .then((res) => {
                rmsLink = rmsLink;

                $snackbarSuccess.text = $_("rms_status_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.log(error);
                if (!handleApiError($_("rms_status_update_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            });
    }

    async function saveRmsSuppChanges(data, type = "") {
        if (type === "add") {
            try {
                const linkRes = await createRow("supplier/link", data);
                if (Array.isArray(linkRes) && linkRes.length > 0) {
                    // Add new ID from the response to the supplier
                    data.id = linkRes[0].id;

                    // Check if there are contacts to process
                    if (Array.isArray(data.contact) && data.contact.length > 0) {
                        const createContacts = await Promise.all(
                            data.contact.map(async (contact) => {
                                if (!contact.id) {
                                    // Check if the contact does not have an ID
                                    contact.supplierLinkId = data.id;

                                    const response = await createRow("supplier/contact", contact);
                                    if (Array.isArray(response) && response.length > 0 && response[0].hasOwnProperty("id")) {
                                        contact.id = response[0].id; // Update the contact's ID with the new ID
                                    } else {
                                        console.error("Unexpected response format or creation failed", response);
                                    }
                                }
                                return contact;
                            }),
                        );

                        // Update data.contact with the responses
                        data.contact = createContacts;
                        $snackbarSuccess.text = $_("supp_link_created");
                        $snackbarSuccess.element && $snackbarSuccess.element.open();
                    } else {
                        $snackbarSuccess.text = $_("supp_link_created");
                        $snackbarSuccess.element && $snackbarSuccess.element.open();
                    }

                    // Add the new data row to rmsLink, including updated contacts
                    rmsLink = [...rmsLink, data];
                } else {
                    console.error("Unexpected response structure:", linkRes);
                }
            } catch (error) {
                console.log(error);
                if (!handleApiError($_("supp_link_create_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            }
        } else if (type === "edit") {
            try {
                const index = rmsLink.findIndex((row) => row.id === data.id);
                console.log({ data });

                // Original contacts
                const originalContacts = data.orgContact || [];
                // Updated contacts
                const updatedContacts = data.supplierDetails || [];

                // List to be deleted
                const contactsToDelete = originalContacts.filter((orgContact) => !updatedContacts.some((updContact) => updContact.uuid === orgContact.uuid));

                // List to be added
                const contactsToAdd = updatedContacts.filter((updContact) => !originalContacts.some((orgContact) => orgContact.uuid === updContact.uuid));

                // Log for debugging
                console.log("Contacts to delete:", contactsToDelete);
                console.log("Contacts to add:", contactsToAdd);

                // If no changes, skip
                if (contactsToDelete.length === 0 && contactsToAdd.length === 0) {
                    console.log("No contact changes to apply");
                    return;
                }

                // Delete contacts
                await Promise.all(
                    contactsToDelete.map(async (contact) => {
                        await deleteRow("supplier/contact", contact.id);
                    }),
                );

                // Add contacts
                const addedContacts = await Promise.all(
                    contactsToAdd.map(async (contact) => {
                        if (!contact.id) {
                            const response = await createRow("supplier/contact", {
                                ...contact,
                                supplierLinkId: data.id,
                            });

                            if (Array.isArray(response) && response.length > 0 && response[0].hasOwnProperty("id")) {
                                contact.id = response[0].id;
                            } else {
                                console.error("Unexpected response format or creation failed", response);
                            }
                        }
                        return contact;
                    }),
                );

                // Merge the unchanged contacts + newly added contacts
                const unchangedContacts = originalContacts.filter((orgContact) => updatedContacts.some((updContact) => updContact.uuid === orgContact.uuid));

                // Combine the unchanged ones with newly added ones to produce the latest set
                const finalContacts = [...unchangedContacts, ...addedContacts];

                // Update data.supplierDetails with the newly created IDs
                data.supplierDetails = finalContacts;
                rmsLink[index] = data;

                $snackbarSuccess.text = $_("contact_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            } catch (error) {
                console.log("Error updating contacts:", error);
                if (!handleApiError($_("contact_update_failed", { values: { reason: error.message } }), error, "non-fatal")) {
                    throw error;
                }
            }
        } else {
            console.log("Wrong type while supplier rms link creation");
        }
    }

    function saveCeSupplier() {
        if (ceSupplierDialogType === "add" && ceSuppData.id === null) {
            createRow("supplier/", ceSuppData)
                .then((res) => {
                    $snackbarSuccess.text = $_("supp_created");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();

                    if (Array.isArray(res) && res.length > 0) {
                        // Add new ID from the response to the supplier
                        ceSuppData.id = res[0].id;
                    } else {
                        // Handle the situation when res or res is not as expected
                        console.error("Unexpected response structure:", res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("supp_create_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        } else if (ceSupplierDialogType === "edit") {
            // Edit the existing item in the supp
            updateRow("supplier/", ceSuppData)
                .then((res) => {
                    $snackbarSuccess.text = $_("supp_updated");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("supp_update_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        }
    }

    function enableSaveButton() {
        console.log("================= enableSaveButton ==============");
        buttonSaveDisabled = false;
    }

    $: if (backClicked) {
        backClicked = false;
        onClose();
    }

    // Function to get unique buyers
    function getUniqueSuppliers(item) {
        // Check if buyerDetails array exists and is not empty
        if (!item.supplierDetails || item.supplierDetails.length === 0) {
            return [];
        }

        // Use Map to get unique buyers by uuid
        const uniqueBuyersMap = new Map();
        item.supplierDetails.forEach((buyer) => {
            if (!uniqueBuyersMap.has(buyer.uuid)) {
                uniqueBuyersMap.set(buyer.uuid, buyer);
            }
        });

        // Sort by full name (lastName + firstName)
        return Array.from(uniqueBuyersMap.values()).sort((a, b) => {
            const nameA = `${a.lastName} ${a.firstName}`.toLowerCase();
            const nameB = `${b.lastName} ${b.firstName}`.toLowerCase();
            return nameA.localeCompare(nameB);
        });
    }

    // RMS Link Table Head
    const tableHeadSuppLink = [
        { columnId: "actions", label: "", numeric: false, extraClasses: "" },
        { columnId: "country", label: $_("rms_supplier_country"), numeric: false, extraClasses: "" },
        { columnId: "rmsSupplierNo", label: $_("rms_supplier_no"), numeric: false, extraClasses: "" },
        { columnId: "rmsSupplierName", label: $_("rms_supplier_name"), numeric: false, extraClasses: "" },
        { columnId: "status", label: $_("rms_supplier_status"), numeric: false, extraClasses: "" },
        { columnId: "contact", label: $_("contactemail"), numeric: false, extraClasses: "" },
    ];
</script>

<Flex direction="column" align="start" class="gap-1">
    <Flex justify="start" align="end" class="gap-1">
        <!-- CE Supp Table -->
        <DataTable class="input-form no-border grey lighten-3" table$aria-label="Supplier CE detail">
            <Body>
                {#if ceSuppData.id !== null}
                    <Row>
                        <Cell class="form-label">{$_("ce_supplier_no")}</Cell>
                        <DTInputCell colspan={2} class="grey lighten-2" value={ceSuppData.id} editable={false} numeric={false} />
                    </Row>
                {/if}
                <Row>
                    <Cell class="form-label">{$_("ce_supplier_name")}</Cell>
                    <DTInputCell colspan={2} class="white" bind:value={ceSuppData.name} editable={true} numeric={false} on:change={enableSaveButton} />
                </Row>
                {#if ceSuppData.id !== null}
                    <Row>
                        <Cell class="form-label">{$_("ce_supplier_status")}</Cell>
                        <Cell class="white">
                            <Switch bind:checked={isActive} on:SMUISwitch:change={enableSaveButton} />
                            {ceSuppData.status === 1 ? $_("active") : $_("inactive")}
                        </Cell>
                    </Row>
                {/if}
            </Body>
        </DataTable>

        <Button on:click={saveCeSupplier} class={!buttonSaveDisabled ? "tescoblue white-text pr-4 nowrap" : "grey white-text"} title={$_("save")} disabled={buttonSaveDisabled}>
            <Icon class="material-icons">save</Icon>
            {$_("save")}
        </Button>
    </Flex>

    {#if ceSuppData.id !== null}
        <hr class="w-100" style="color: var(--mdc-theme-primary)" />

        <!-- RMS Supp Table -->
        <Button
            title={$_("link_rms_supplier")}
            class="tescoblue-text white-tescoblue-outlined pr-4 nowrap"
            on:click={() => {
                suppRmsDialogType = "add";
                openRmsDialog(null, "add");
            }}
        >
            <Icon class="material-icons">add_circle</Icon>
            {$_("link_rms_supplier")}
        </Button>

        <DataTable stickyHeader table$aria-label="Supplier RMS Link List" style="width: 100%;">
            <Head>
                <Row>
                    {#each tableHeadSuppLink as head (head.columnId)}
                        <Cell class="datatable-header">
                            {$_(head.label)}
                        </Cell>
                    {/each}
                </Row>
            </Head>
            <Body>
                {#if loadSupplierLinkData}
                    <Row class="datatable-content">
                        <Cell colspan="10" class="text-center">
                            <CircularProgress style="height: 32px; width: 32px; margin-right: 1rem;" indeterminate />
                            {$_("loading_wait")}
                        </Cell>
                    </Row>
                {:else if rmsLink && rmsLink.length > 0}
                    {#each rmsLink as rmsSupplier (rmsSupplier.id)}
                        <Row>
                            {#each tableHeadSuppLink as head (head.columnId)}
                                <Cell>
                                    {#if head.columnId === "actions"}
                                        <!-- Edit button -->
                                        <IconButton
                                            title={$_("edit")}
                                            size="button"
                                            class="tescoblue-text opacity-1"
                                            on:click={() => {
                                                suppRmsDialogType = "edit";
                                                console.log("edit button rmsSupplier", { rmsSupplier });
                                                openRmsDialog(rmsSupplier, suppRmsDialogType);
                                            }}
                                        >
                                            <svg viewBox="0 0 24 24" class="tescoblue-text">
                                                <path fill="currentColor" d={mdiPencilBoxOutline} />
                                            </svg>
                                        </IconButton>
                                        <!-- Delete button -->
                                        <IconButton title={$_("edit")} size="button" class="tescoblue-text opacity-1" on:click={() => showDeleteRowConfirmation(rmsSupplier)}>
                                            <svg viewBox="0 0 24 24" class="pa-0 ma-0">
                                                <path fill="currentColor" d={mdiDelete} />
                                            </svg>
                                        </IconButton>
                                    {:else if head.columnId === "status"}
                                        <Switch checked={rmsSupplier.status === 1} on:click={() => handleRmsStatusChange(rmsSupplier)} />
                                        {rmsSupplier.status === 1 ? $_("active") : $_("inactive")}
                                    {:else if head.columnId === "contact"}
                                        {#if rmsSupplier.supplierDetails && rmsSupplier.supplierDetails.length > 0}
                                            <!-- Suppliers contact -->
                                            {#if getUniqueSuppliers(rmsSupplier).length > 0}
                                                {#if getUniqueSuppliers(rmsSupplier).length === 1}
                                                    <!-- Display only one supplier -->
                                                    <span>{getUniqueSuppliers(rmsSupplier)[0].lastName} {getUniqueSuppliers(rmsSupplier)[0].firstName}</span>
                                                {:else}
                                                    <!-- Multiple supplier with tooltip -->
                                                    <Wrapper rich style="position: unset !important; --tooltip-top: {tooltipYpos}px; --tooltip-left: {tooltipXpos}px;">
                                                        <span>{getUniqueSuppliers(rmsSupplier)[0].lastName} {getUniqueSuppliers(rmsSupplier)[0].firstName} ...</span>
                                                        <!-- Tooltip on mouse position -->
                                                        <Tooltip
                                                            style="position: fixed !important;left: var(--tooltip-left) !important;top: var(--tooltip-top) !important;transform: translateX(-100%);"
                                                            surface$style="max-width: max-content"
                                                        >
                                                            <Content style="max-width: fit-content;">
                                                                {#each getUniqueSuppliers(rmsSupplier) as supplier}
                                                                    <div>{supplier.lastName} {supplier.firstName}</div>
                                                                {/each}
                                                            </Content>
                                                        </Tooltip>
                                                    </Wrapper>
                                                {/if}
                                            {:else}
                                                <span>-</span> <!-- When there are no suppliers -->
                                            {/if}
                                        {/if}
                                    {:else}
                                        {rmsSupplier[head.columnId]}
                                    {/if}
                                </Cell>
                            {/each}
                        </Row>
                    {/each}
                {:else}
                    <Row class="datatable-content">
                        <Cell colspan="10" class="text-center" style="width: 100%">{$_("no_data_found_supp_link")}</Cell>
                    </Row>
                {/if}
            </Body>
        </DataTable>
    {/if}
</Flex>

<ConfirmationDialogOver
    open={suppRmsDialogOpen}
    title={suppRmsDialogType == "add" ? $_("add_supplier_rms_link") : $_("edit_supplier_rms_link")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("save")}
    big={true}
    headerColors="tescoblue white-text"
    dataValid={rmskDataCheck}
    bind:subOpen
    {suppRmsData}
    bind:presentCountries
    {suppRmsDialogType}
    on:reject={() => {
        suppRmsDialogOpen = false;
        suppRmsData = {
            id: 0,
            supplierId: 0,
            supplierName: "",
            country: "",
            rmsSupplierNo: 0,
            rmsSupplierName: "",
            currency: "",
            status: 0,
            orgContact: [],
            contact: [],
            supplierDetails: [],
            tradingPartnerId: "",
        };
        presentCountries = {};
    }}
    on:confirm={(event) => {
        presentCountries = {};
        suppRmsDialogOpen = false;
        saveRmsSuppChanges(event.detail, suppRmsDialogType);
    }}
></ConfirmationDialogOver>

<!-- Delete Dialog -->
<ConfirmationDialog
    open={showConfirmationDialog}
    title={$_("delete")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    itemData={null}
    headerColors="tescoblue white-text"
    on:reject={() => {
        showConfirmationDialog = false;
    }}
    on:confirm={() => {
        showConfirmationDialog = false;
        deleteRmsRow(confirmationDialogData.id);
    }}
>
    {confirmationDialogQuestion}
</ConfirmationDialog>
