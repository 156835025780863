<script>
    import { createEventDispatcher } from "svelte";
    import DTInputCell from "../common/DTInputCell.svelte";
    import Checkbox from "@smui/checkbox";
    import Tooltip, { Wrapper, Content } from "@smui/tooltip";
    import { mdiCheckboxOutline, mdiCheckboxBlankOutline } from "@mdi/js";
    import { _ } from "svelte-i18n";

    export let value;
    export let promoFlag;
    export let disabled = false;
    export let conversionRatio = 1;
    export let price;
    export let currency = "EUR";
    export let priceEur;
    export let pricePromo;
    export let pricePromoEur;

    const dispatch = createEventDispatcher();

    // Tooltip positioning
    let tooltipXpos = 0;
    let tooltipYpos = 0;

    function handleFocusOrHover(event) {
        const el = event.detail?.element || event.currentTarget;
        if (!el) return;

        const rect = el.getBoundingClientRect();
        tooltipXpos = rect.left;
        tooltipYpos = rect.top + rect.height;
    }

    function displayPrice(price, currency, priceEur) {
        return !priceEur || priceEur == price ? `${price} ${currency}` : `${price} ${currency} (${priceEur} EUR)`;
    }
</script>

<Wrapper
    rich
    style="position: unset !important; --tooltip-top: {tooltipYpos}px; --tooltip-left: {tooltipXpos}px"
    on:focusin={handleFocusOrHover}
    on:mouseenter={handleFocusOrHover}
>
    <div class="datatable-cell {promoFlag && !disabled ? 'yellow' : ''}">
        <Checkbox
            bind:checked={promoFlag}
            disabled={disabled || !pricePromo}
            on:change={() => {
                dispatch("promoFlagChange");
            }}
            aria-label="Promotion price checkbox"
        />

        <DTInputCell
            bind:value
            editable={!disabled}
            class="small {disabled ? 'row-deleted' : ''}"
            on:change={() => {
                dispatch("change");
            }}
        />
    </div>

    <!-- Tooltip -->
    <Tooltip style="position: fixed !important; left: calc(var(--tooltip-left)) !important; top: var(--tooltip-top) !important;" surface$style="max-width: max-content">
        <Content style="max-width: fit-content;">
            {#if !pricePromo}
                <strong>{$_("no_promo_price")}:</strong>
                {displayPrice(price, currency, priceEur)}
                {$_("will_be_used")}
            {:else if promoFlag}
                <u>
                    <svg viewBox="0 0 24 24" class="tescoblue-text" style="width: 1.2rem; height: 1.2rem;">
                        <path fill="currentColor" d={mdiCheckboxOutline} />
                    </svg>
                    <strong> {$_("promo_price")}:</strong>
                    {displayPrice(pricePromo, currency, pricePromoEur)}
                    {$_("will_be_used")}
                </u>
                <br />
                <svg viewBox="0 0 24 24" class="tescoblue-text" style="width: 1.2rem; height: 1.2rem;">
                    <path fill="currentColor" d={mdiCheckboxBlankOutline} />
                </svg>
                <strong> {$_("standard_price")}:</strong>
                {displayPrice(price, currency, priceEur)}
            {:else}
                <svg viewBox="0 0 24 24" class="tescoblue-text" style="width: 1.2rem; height: 1.2rem;">
                    <path fill="currentColor" d={mdiCheckboxBlankOutline} />
                </svg>
                <strong> {$_("promo_price")}:</strong>
                {displayPrice(pricePromo, currency, pricePromoEur)}
                <br />
                <u>
                    <svg viewBox="0 0 24 24" class="tescoblue-text" style="width: 1.2rem; height: 1.2rem;">
                        <path fill="currentColor" d={mdiCheckboxOutline} />
                    </svg>
                    <strong> {$_("standard_price")}:</strong>
                    {displayPrice(price, currency, priceEur)}
                    {$_("will_be_used")}
                </u>
            {/if}
        </Content>
    </Tooltip>
</Wrapper>

{#if conversionRatio != 1}
    <div class="converted-value {disabled ? 'row-deleted' : ''}">
        {value * conversionRatio}
    </div>
{/if}

<style>
    .datatable-cell {
        display: flex;
        align-items: center;
    }
    .converted-value {
        font-size: 0.9em;
        color: gray;
        text-align: right;
        margin-left: auto;
    }
</style>
