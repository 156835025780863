<script lang="ts">
  import DataTable, { Head, Body, Row, Cell, Pagination, Label } from "@smui/data-table";
  import Select, { Option } from "@smui/select";
  import IconButton, { Icon } from "@smui/icon-button";
  import Checkbox from "@smui/checkbox";
  import CircularProgress from "@smui/circular-progress";
  import Tooltip, { Wrapper, Content } from "@smui/tooltip";
  import Flex from "svelte-flex";
  import { _ } from "svelte-i18n";
  import { mdiPencilBoxOutline, mdiDelete, mdiAlert, mdiAlertCircle, mdiHistory } from "@mdi/js";

  import { dcList } from "../../../stores/AppConfig";
  import { nvl, getFieldErrMsg, existsError, existErrors } from "../Upload/Upload.js";
  import type { AmendmentCountryItems } from "../../../types/AmendmentCountry";

  import { createEventDispatcher } from "svelte";

  // Items
  export let items: AmendmentCountryItems[] = [];
  export let filteredItems: AmendmentCountryItems[] = [];

  // Screen mode
  export let screenMode;

  // Filters
  export let selectedCountry = [];
  export let selectedDC = [];
  export let ceTpnSearch = "";
  export let tpnbSearch = "";
  export let tpnbDescSearch = "";
  export let productDescEnSearch = "";
  //export let deliveryDateSearch = '';
  export let selectedStatus = [];
  export let fileNameSearch = "";
  export let statusSearchErrorsArray = [];
  export let selectedItems = [];
  export let canInsert = false;

  // Flags
  export let canUpdate = false;
  export let canDelete = false;

  // Data Status
  export let dataValid = false;
  export let loadingData = false;

  const dispatch = createEventDispatcher();
  let checkedAll = false;

  // Tooltip position fix
  let tooltipXpos;
  let tooltipYpos;

  // Pagging
  let rowsPerPage = 10;
  let currentPage = 0;

  let editable;
  let deletable;

  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, filteredItems.length);
  $: slice = filteredItems.slice(start, end);
  $: lastPage = Math.max(Math.ceil(filteredItems.length / rowsPerPage) - 1, 0);
  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  /*
  $: {
    console.log("------ACDataTable-------");
    console.log({ items });
    console.log({ selectedItems });
    console.log({ slice });
  }
*/

  $: {
    editable = (canInsert || canUpdate) && filteredItems.length > 0;
    //console.log({editable});
  }

  $: {
    deletable = canDelete && filteredItems.length > 0;
    //console.log({deletable});
  }

  $: {
    selectedItems = [...filteredItems].filter((i) => i.selected);
    //console.log({selectedItems});
  }

  $: console.log({ editable });

  // Filter
  $: filteredItems = items?.filter((item) => {
    //console.log("==============     FILTER     =============");

    const ceTpnMatch = (item.productId ?? "").toString().includes(ceTpnSearch);
    const tpnbMatch = (item.tpnb ?? "").toString().includes(tpnbSearch);
    const productNameEnMatch = (item.productDescription ?? "").toLowerCase().includes(productDescEnSearch?.toLowerCase() ?? "");
    const tpnbNameMatch = (item.tpnbDescription ?? "").toLowerCase().includes(tpnbDescSearch?.toLowerCase() ?? "");
    //const deliveryDateMatch = (item.deliveryDate ?? "") == (deliveryDateSearch ?? "");
    const fileNameMatch = (item.fileName ?? "").toLowerCase().includes(fileNameSearch.toLowerCase() ?? "");

    // arrays
    const statusMatch = selectedStatus.length > 0 ? selectedStatus.some((unitId) => item.status?.includes(unitId)) : true;
    const selectedCountryMatch = selectedCountry.length > 0 ? selectedCountry.some((unitId) => item.country?.includes(unitId)) : true;

    // Convert item.warehouseId to a DC code for comparison
    const itemDcCode = findDcCodeById(item.warehouseId);
    const dcMatch = selectedDC.length > 0 ? selectedDC.includes(itemDcCode) : true;

    // Check for any errors or warnings
    let errorsMatch = true;
    if (statusSearchErrorsArray.length > 0) {
      const errorsExist = item.errors?.some((errorItem) => statusSearchErrorsArray.some((searchError) => searchError.message === errorItem.message));
      const warningsExist = item.warnings?.some((warningItem) => statusSearchErrorsArray.some((searchError) => searchError.message === warningItem.message));
      errorsMatch = errorsExist || warningsExist;
    }
    //console.log({ceTpnMatch});
    //console.log({tpnbMatch});
    //console.log({productNameEnMatch});
    //console.log({deliveryDateMatch});
    //console.log({fileNameMatch});
    //console.log({errorsExist});
    //console.log({warningsExist});
    //console.log({tpnbNameMatch});
    //console.log({statusMatch});
    //console.log({selectedCountryMatch});
    //console.log({dcMatch});
    //console.log({filteredItems});
    return (
      ceTpnMatch && productNameEnMatch /*&& deliveryDateMatch*/ && tpnbMatch && tpnbNameMatch && statusMatch && selectedCountryMatch && dcMatch && errorsMatch && fileNameMatch
    );
  });

  // Function to find DC code by ID
  function findDcCodeById(dcId) {
    const dc = $dcList.find((d) => d.id === dcId);
    return dc ? dc.code : "Unknown";
  }

  function checkUncheckAll() {
    console.log("================= checkUncheckAll ==============");
    console.log(checkedAll);
    filteredItems.forEach((i) => {
      i.selected = !checkedAll;
    });
    selectedItems = [...filteredItems].filter((i) => i.selected);
  }

  function errorsExist(data) {
    console.log("================= errorsExist ==============");
    console.log(data);
    var res = false;
    filteredItems.forEach((i) => {
      if (i.errors && Array.isArray(i.errors) && i.errors.length > 0) {
        res = true;
      }
    });
    return res;
  }

  // Table Head
  let tableHead = [
    { columnId: "error", label: "error", numeric: false, extraClasses: "right-splitter" },
    { columnId: "dc", label: "dc", numeric: false, extraClasses: "right-splitter" },
    { columnId: "productId", label: "tpn_en_tpnb", numeric: false, extraClasses: "right-splitter" },
    { columnId: "productDescription", label: "product_en_tpnb", numeric: false, extraClasses: "right-splitter" },
    { columnId: "deliveryDate", label: "delivery_date", numeric: false, extraClasses: "right-splitter" },
    { columnId: "units", label: "units", numeric: false, extraClasses: "right-splitter" },
    { columnId: "originalVol", label: "original_purchased_vol", numeric: true, extraClasses: "right-splitter wrap-spaces" },
    { columnId: "amendVol", label: "amend_vol", numeric: true, extraClasses: "right-splitter wrap-spaces" },
    { columnId: "confirmedVol", label: "confirmed_vol", numeric: true, extraClasses: "right-splitter wrap-spaces" },
    { columnId: "status", label: "status", numeric: false, extraClasses: "right-splitter" },
    { columnId: "countryComment", label: "country_comment", numeric: false, extraClasses: "right-splitter wrap-spaces" },
    { columnId: "userComment", label: "user_comment", numeric: false, extraClasses: "right-splitter wrap-spaces" },
  ];
</script>

<DataTable stickyHeader table$aria-label="CA Table" class="report" style="width: 100%; max-height: calc(100vh - 320px);">
  <Head>
    <Row>
      {#if editable || deletable}
        <Cell columnId="checkbox" class="datatable-header pl-0 pr-0 right-splitter" checkbox>
          <Checkbox bind:checked={checkedAll} on:click={checkUncheckAll} />
          <IconButton
            size="button"
            class="tescoblue-text opacity-1"
            on:click={() => {
              dispatch("batchDeleteAmendmentCountryRows");
            }}
          >
            <Icon tag="svg" viewBox="0 0 24 24" class="tescoblue white-text">
              <path fill="currentColor" d={mdiDelete} />
            </Icon>
          </IconButton>
        </Cell>
      {/if}
      {#each tableHead as head (head.columnId)}
        {#if head.columnId !== "error" || screenMode === "upload" || errorsExist(slice)}
          <Cell numeric={head.numeric} columnId={head.columnId} class="datatable-header {head.extraClasses}" style="width: 100px">
            <Label>{$_(head.label)}</Label>
          </Cell>
        {/if}
      {/each}
    </Row>
  </Head>
  <Body>
    {#if loadingData}
      <Row>
        <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">
          <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
        </td>
      </Row>
    {:else if slice.length == 0}
      {#if !dataValid}
        <Row>
          <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("load_week_data_or_import")}</td>
        </Row>
      {:else}
        <Row>
          <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center">{$_("no_data_for_week")}</td>
        </Row>
      {/if}
    {:else}
      {#each slice as item (item.id)}
        <Row>
          {#if editable || deletable}
            <!-- Actions -->
            <Cell class="pl-0 pr-0 right-splitter-sub" checkbox>
              <Flex direction="row" justify="start">
                <Checkbox bind:checked={item.selected} />
                <!-- Edit -->
                <IconButton
                  title={$_("edit")}
                  size="button"
                  disabled={existsError(item, "week", "E") ||
                    existsError(item, "tpnb", "E") ||
                    existsError(item, "productId", "E") ||
                    !canUpdate ||
                    !["new", "uploaded"].includes(item.status)}
                  on:click={() => dispatch("editAmendmentCountryRow", item)}
                >
                  <Icon
                    tag="svg"
                    viewBox="0 0 24 24"
                    class={!existsError(item, "week", "E") && !existsError(item, "tpnb", "E") && !existsError(item, "productId", "E") && ["new", "uploaded"].includes(item.status)
                      ? "tescoblue-text"
                      : "grey lighten-2"}
                  >
                    <path fill="currentColor" d={mdiPencilBoxOutline} />
                  </Icon>
                </IconButton>
                <!-- Delete -->
                <IconButton
                  title={$_("delete")}
                  size="button"
                  disabled={!canDelete || !["new", "uploaded"].includes(item.status)}
                  on:click={() => dispatch("deleteAmendmentCountryRow", item.id)}
                >
                  <Icon tag="svg" viewBox="0 0 24 24" class={!["new", "uploaded"].includes(item.status) || (!canDelete && screenMode !== "upload") ? "" : "tescoblue-text"}>
                    <path fill="currentColor" d={mdiDelete} />
                  </Icon>
                </IconButton>
                {#if screenMode === "edit"}
                  <!-- History -->
                  <IconButton
                    title={$_("history")}
                    size="button"
                    on:click={() => dispatch("history", item.id)}
                    disabled={screenMode !== "edit" || item.status === "new" || item.status === "in_progress"}
                  >
                    <Icon
                      tag="svg"
                      viewBox="0 0 24 24"
                      class={screenMode !== "edit" || item.status === "new" || item.status === "new" || item.status === "in_progress" ? "" : "tescoblue-text"}
                    >
                      <path fill="currentColor" d={mdiHistory} />
                    </Icon>
                  </IconButton>
                {/if}
              </Flex>
            </Cell>
          {/if}
          {#if screenMode == "upload" || errorsExist(slice)}
            <!-- Errors, Warnings-->
            <Cell class="right-splitter-sub" style="overflow: visible">
              <Flex direction="row">
                {#if existErrors(item, "E")}
                  <Wrapper rich style="position: unset !important; --tooltip-top: {tooltipYpos}px; --tooltip-left: {tooltipXpos}px">
                    <svg
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      role="button"
                      tabindex="0"
                      aria-label="Show errors"
                      on:mouseenter={(event) => {
                        tooltipYpos = event.clientY;
                        tooltipXpos = event.clientX;
                      }}
                      on:keydown={(event) => {
                        if (event.key === "Enter") {
                          tooltipYpos = event.clientY;
                          tooltipXpos = event.clientX;
                        }
                      }}
                    >
                      <path fill="red" d={mdiAlertCircle} />
                    </svg>
                    <Tooltip
                      style="position: fixed !important; left: calc(var(--tooltip-left, 9.5rem) + 1rem) !important; top: var(--tooltip-top, unset) !important;"
                      surface$style="max-width: max-content"
                    >
                      <Content style="max-width: fit-content;">
                        {@html item.errors ? item.errors.map((x) => $_(x.message)).join("<br>") : null}
                      </Content>
                    </Tooltip>
                  </Wrapper>
                {/if}

                {#if existErrors(item, "W")}
                  <Wrapper rich style="position: unset !important; --tooltip-top: {tooltipYpos}px; --tooltip-left: {tooltipXpos}px">
                    <svg
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      role="button"
                      tabindex="0"
                      aria-label="Show warnings"
                      on:mouseenter={(event) => {
                        tooltipYpos = event.clientY;
                        tooltipXpos = event.clientX;
                      }}
                      on:keydown={(event) => {
                        if (event.key === "Enter") {
                          tooltipYpos = event.clientY;
                          tooltipXpos = event.clientX;
                        }
                      }}
                    >
                      <path fill="orange" d={mdiAlert} />
                    </svg>
                    <Tooltip
                      style="position: fixed !important; left: calc(var(--tooltip-left, 9.5rem) + 1rem) !important; top: var(--tooltip-top, unset) !important;"
                      surface$style="max-width: max-content"
                    >
                      <Content style="max-width: fit-content;">
                        {@html item.warnings ? item.warnings.map((x) => $_(x.message)).join("<br>") : null}
                      </Content>
                    </Tooltip>
                  </Wrapper>
                {/if}
              </Flex>
            </Cell>
          {/if}
          <!-- DC -->
          <Cell class="right-splitter-sub">{findDcCodeById(item.warehouseId)}</Cell>
          <!-- productId / TPNB-->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && (existsError(item, "productId", "E") || existsError(item, "tpnb", "E"))}
              <span class="red-text" title={$_(getFieldErrMsg(item, "productId", "E") || getFieldErrMsg(item, "tpnb", "E"))}>
                {item.productId ?? ""}<br />{item.tpnb ?? ""}
              </span>
            {:else}
              <span>
                {item.productId ?? ""}<br />{item.tpnb ?? ""}
              </span>
            {/if}
          </Cell>

          <!-- Product Description -->
          <Cell class="right-splitter-sub">{item.productDescription ?? ""}<br />{item.tpnbDescription ?? ""}</Cell>
          <!-- Delivery Date -->
          <Cell class="text-center right-splitter-sub">
            {#if screenMode == "upload" && existErrors(item, "E") && item.errors.map((x) => x.field).includes("deliveryDate")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "deliveryDate", "E"))}>{nvl(new Date(item.deliveryDate).toAppDate(), "")}</span>
            {:else}
              <span>{nvl(new Date(item.deliveryDate).toAppDate(), "")}</span>
            {/if}
          </Cell>
          <!-- Units -->
          <Cell class="text-center right-splitter-sub">
            {#if screenMode == "upload" && existErrors(item, "E") && item.errors.map((x) => x.field).includes("units")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "units", "E"))}>{$_(nvl(item.units, ""))}</span>
            {:else}
              <span>{$_(nvl(item.units, ""))}</span>
            {/if}
          </Cell>
          <!-- Original Purchased Volume -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "originalVol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "originalVol", "E"))}>{item.originalVol ?? ""}</span>
            {:else}
              <span>{item.originalVol ?? ""}</span>
            {/if}
          </Cell>
          <!-- Amended Volume -->
          <Cell class="text-right right-splitter-sub">
            {#if screenMode == "upload" && existsError(item, "amendVol", "E")}
              <span class="red-text" title={$_(getFieldErrMsg(item, "amendVol", "E"))}>{item.amendVol ?? ""}</span>
            {:else}
              <span>{item.amendVol ?? ""}</span>
            {/if}
          </Cell>
          <!-- Supplier Confirmed Volume -->
          <Cell class="text-right right-splitter-sub">
            <span>{item.confirmedVol ?? ""}</span>
          </Cell>
          <Cell class="right-splitter-sub" title={item.status == "error" && item.errorMessage && item.errorMessage != "" ? $_(nvl(item.errorMessage, "")) : ""}
            >{$_(nvl(item.status, ""))}</Cell
          >
          <Cell class="right-splitter-sub">{item.countryComment ?? ""}</Cell>
          <Cell class="right-splitter-sub">{item.userComment ?? ""}</Cell>
          <!--           <Cell class="right-splitter-sub">{item.fileName ?? ""}</Cell> -->
        </Row>
      {/each}
    {/if}
  </Body>

  <Pagination slot="paginate">
    <svelte:fragment slot="rowsPerPage">
      <Label>{$_("rows_per_page")}</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        <Option value={10}>10</Option>
        <Option value={25}>25</Option>
        <Option value={100}>100</Option>
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      {start + 1}-{end} of {filteredItems.length}
    </svelte:fragment>

    <IconButton class="material-icons" action="first-page" title="First page" on:click={() => (currentPage = 0)} disabled={currentPage === 0}>first_page</IconButton>
    <IconButton class="material-icons" action="prev-page" title="Prev page" on:click={() => currentPage--} disabled={currentPage === 0}>chevron_left</IconButton>
    <IconButton class="material-icons" action="next-page" title="Next page" on:click={() => currentPage++} disabled={currentPage === lastPage}>chevron_right</IconButton>
    <IconButton class="material-icons" action="last-page" title="Last page" on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}>last_page</IconButton>
  </Pagination>
</DataTable>
