<script>
    import { _ } from "svelte-i18n";
    import Flex from "svelte-flex";
    import Paper from "@smui/paper";

    import { handleApiError } from "../../lib/errorHandler";

    import { createDC, updateDC, deleteDC } from "../../../../api/LOV";
    import { dcList, countries } from "../../../../stores/AppConfig";
    import { snackbarSuccess } from "../../../../stores/AppStatus";

    import ConfirmationDialog from "../../../dialogs/ConfirmationDialog.svelte";

    import DCLDataTable from "./DCLDataTable.svelte";
    import DCLAddButton from "./DCLAddButton.svelte";
    import DCLDetailDialog from "./DCLDetailDialog.svelte";

    let showDetailDialog = false;
    let detailDialogMode = "add";

    let showConfirmationDialog = false;
    let confirmationDialogQuestion = "";
    let confirmationDialogData = {};

    let rowData = {};
    let backupRowData = {};

    const getNextId = (list) => {
        let maxId =
            list
                .map((x) => x.id)
                .sort((a, b) => a - b)
                .at(-1) || 0;
        maxId++;
        return maxId;
    };

    const prepareNewRow = () => {
        rowData = {
            id: getNextId($dcList),
            country: null,
            code: null,
            site: null,
            live: true,
            name: null,
            location: null,
            type: "LOCAL",
            participatin: null,
            displayOrder: null,
        };

        detailDialogMode = "add";
        showDetailDialog = true;
    };

    const editRow = (id) => {
        rowData = $dcList.filter((dc) => dc.id == id).at(0);
        backupRowData = { ...rowData };
        detailDialogMode = "edit";
        showDetailDialog = true;
    };

    const showDeleteRowConfirmation = (id) => {
        confirmationDialogData = { id: id };
        confirmationDialogQuestion = $_("do_you_want_delete_dc");
        showConfirmationDialog = true;
    };

    const deleteRow = () => {
        if (confirmationDialogData && confirmationDialogData?.id) {
            deleteDC(confirmationDialogData?.id)
                .then((res) => {
                    console.log({ res });
                    $dcList = [...$dcList.filter((x) => x.id != confirmationDialogData?.id)];

                    console.log({ $dcList });
                    $snackbarSuccess.text = $_("deleted");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("delete_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        }
    };

    const revertChanges = (rowData, type) => {
        if (type == "edit") {
            $dcList = [...$dcList.filter((x) => x.id != rowData.id), backupRowData].sort((x, y) => (x.id > y.id ? 1 : -1));

            // Trigger screen refresh
            $dcList = $dcList;
        }

        console.log({ $dcList });
    };

    const saveChanges = (rowData, type) => {
        console.log("saveChanges: type: ", type, " Data: ", rowData);

        // Update type acording code
        const isCountry = $countries.some((country) => country.id === rowData.code);
        rowData.type = isCountry ? "COUNTRY" : "LOCAL";

        if (type == "add") {
            createDC(rowData)
                .then((res) => {
                    console.log({ res });
                    // Trigger screen refresh
                    rowData.id = res.at(0)?.id;
                    $dcList = [...$dcList, rowData].sort((x, y) => (x.id > y.id ? 1 : -1));
                    $dcList = $dcList;

                    console.log({ $dcList });
                    $snackbarSuccess.text = $_("created");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("create_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        } else {
            updateDC(rowData)
                .then((res) => {
                    console.log({ res });
                    // Trigger screen refresh
                    $dcList = $dcList;

                    console.log({ $dcList });
                    $snackbarSuccess.text = $_("updated");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        }
    };
</script>

<section class="w-100 header tescoblue-border-bottom-thin sticky-week-header">
    <Flex direction="row" class="grey lighten-3 tescoblue-text w-100 bs-bb pr-4 gap-1 pt-3 pb-3 pl-3 pr-3" justify="between">
        <Flex direction="row" justify="between" class="gap-1 w-100">
            <div class="w-100 font-weight-bold">
                {$_("dc_list")}
            </div>

            <Flex direction="row" justify="end" class="w-100 gap-1">
                <DCLAddButton on:click={prepareNewRow} />
            </Flex>
        </Flex>
    </Flex>
</section>

<section class="data w-100">
    <Paper class="grey lighten-3 w-100 bs-bb pt-3">
        <div class="smui-paper__content bs-bb">
            <DCLDataTable
                rows={$dcList}
                on:editRow={(e) => {
                    console.log({ e });
                    editRow(e.detail);
                }}
                on:deleteRow={(e) => {
                    showDeleteRowConfirmation(e.detail);
                }}
            />
        </div>
    </Paper>
</section>

<ConfirmationDialog
    open={showDetailDialog}
    title={detailDialogMode == "add" ? $_("add_dc") : $_("edit_dc")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={true}
    itemData={null}
    headerColors="tescoblue white-text"
    dataValid={rowData.country && rowData.code && rowData.name && rowData.location}
    on:reject={() => {
        showDetailDialog = false;
        revertChanges(rowData, detailDialogMode);
    }}
    on:confirm={() => {
        showDetailDialog = false;
        saveChanges(rowData, detailDialogMode);
    }}
>
    <DCLDetailDialog bind:rowData />
</ConfirmationDialog>

<ConfirmationDialog
    open={showConfirmationDialog}
    title={$_("delete")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    itemData={null}
    headerColors="tescoblue white-text"
    on:reject={() => {
        showConfirmationDialog = false;
    }}
    on:confirm={() => {
        showConfirmationDialog = false;
        deleteRow();
    }}
>
    {confirmationDialogQuestion}
</ConfirmationDialog>
