<script>
    import { createEventDispatcher } from "svelte";

    import Button, { Label } from "@smui/button";
    import Dialog, { Title as DTitle, Content as DContent, Actions, InitialFocus } from "@smui/dialog";

    export let open = false;
    export let title = "";
    export let noBtnText = "cancel";
    export let yesBtnText = "save";
    export let headerColors = "tescoblue white-text";
    export let big = false;
    export let itemData = null;
    export let dataValid = true;

    const dispatch = createEventDispatcher();
</script>

<Dialog
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="data-changed-title"
    aria-describedby="data-changed-content"
    class="pa-2"
    surface$style={big ? "width: 850px; max-width: calc(100vw - 32px);" : ""}
>
    <DTitle id="data-changed-title" class={headerColors}>{title}</DTitle>
    <DContent id="data-changed-content" class="mt-4">
        <slot></slot>
    </DContent>
    <Actions class="pr-4 gap-1 space-around">
        <Button data-qa="confirmation-dialog-close" action="close" class="tescored tescored-text outlined" on:click={() => dispatch("reject")}>
            <Label>{noBtnText}</Label>
        </Button>
        <Button
            data-qa="confirmation-dialog-save"
            action="save"
            default
            defaultAction
            use={[InitialFocus]}
            disabled={!dataValid}
            class={dataValid ? "tescoblue white-text" : "grey white-text"}
            on:click={() => dispatch("confirm", itemData)}
        >
            <Label>{yesBtnText}</Label>
        </Button>
    </Actions>
</Dialog>

<style>
</style>
