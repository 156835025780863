<script lang="ts">
  import Select, { Option } from "@smui/select";
  import Dialog, { Title, Content, Actions, InitialFocus } from "@smui/dialog";
  import Button, { Label } from "@smui/button";
  import Textfield from "@smui/textfield";
  import { _ } from "svelte-i18n";
  import Flex from "svelte-flex";

  import { createEventDispatcher } from "svelte";

  import { rejectPurchaseReasons } from "../../../stores/AppConfig";

  // Temporary prototype variables

  // Base variables
  export let open;
  export let data = {};
  export let headerColors = "tescoblue white-text";
  export let big = false;

  // Variables to save
  export let selectedRejectReason = "";
  export let rejectComment = "";

  let setupDone = false;
  let dialogType = "reject";
  let reasonStatus = "rejected";
  let listEl;
  let menuWidth;
  let menuBottom;

  const dispatch = createEventDispatcher();

  $: if (data) {
    if (!setupDone) {
      // console.log('---- Init ----');
      // console.log({data});
      selectedRejectReason = data?.reason ? data.reason : "";
      rejectComment = data?.comment ? data.comment : "";
      dialogType = data?.type ? data.type : dialogType;
      reasonStatus = data?.fcStatus ? data.fcStatus : "rejected";
      setupDone = true;
      // console.log({dialogType});
      // console.log({reasonStatus});
    }
  } else {
    // console.log('---- Outro ----');
    setupDone = false;
  }

  $: {
    console.log("------- REJECT REASON POPUP ---------");
    console.log({ $rejectPurchaseReasons });
    console.log({ data });
    console.log({ dialogType });
    console.log({ reasonStatus });
    // console.log({setupDone});
  }
</script>

<Dialog
  bind:open
  scrimClickAction=""
  escapeKeyAction=""
  aria-labelledby="mandatory-title"
  aria-describedby="mandatory-content"
  class="pa-2"
  surface$style="min-height:400px; min-width: 400px; {big ? 'width: 1920px; max-width: calc(100vw - 32px);' : ''}"
>
  <Title id="data-changed-title" class={headerColors}>
    {$_(dialogType == "reject" ? "reject_forecast_purchase" : "undo_reject_forecast_purchase")}
  </Title>
  <Content id="data-changed-content" class="mt-4">
    <Flex direction="column" justify="center" align="center" class=" gap-05 w-100 gray lighten-4 h-100 pt-2 flex-1">
      {#if setupDone}
        {#if $rejectPurchaseReasons.filter((x) => x.statuses.includes(reasonStatus)).length > 0}
          <!-- Reason of rejection -->
          <Select
            variant="outlined"
            label={$_(reasonStatus + "_reason")}
            class="white small ma-5 with-parameter w-inherit"
            bind:value={selectedRejectReason}
            bind:this={listEl}
            key={(listItem) => `${listItem ? listItem.id : ""}`}
            menu$class="mdc-menu-surface--fixed with-parameter__menu"
            menu$style="max-width: fit-content !important;"
            style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
            on:focusin={() => {
              menuWidth = listEl?.getElement()?.clientWidth;
              menuBottom = listEl?.getElement()?.getBoundingClientRect()?.bottom;
            }}
            on:click={() => {
              menuWidth = listEl?.getElement()?.clientWidth;
              menuBottom = listEl?.getElement()?.getBoundingClientRect()?.bottom;
            }}
          >
            <Option value="" />
            {#each $rejectPurchaseReasons.filter((x) => x.statuses.includes(reasonStatus)) as rejectReasonItem (rejectReasonItem.id)}
              <Option value={rejectReasonItem.id}>{$_(rejectReasonItem.name)}</Option>
            {/each}
          </Select>
        {/if}
        <!-- Comment -->
        <Textfield
          variant="outlined"
          bind:value={rejectComment}
          maxlength="50"
          placeholder=""
          title={$_("comment")}
          class="white small ma-5 w-100"
          input$class="mnw-100"
          size="40"
          label={$_("comment")}
        ></Textfield>
      {/if}
    </Flex>
  </Content>
  <Actions>
    <Button
      action="close"
      class="tescored tescored-text outlined"
      on:click={() => {
        data = null;
        setupDone = false;
        dispatch("rejectPurchasePopupCancel");
      }}
    >
      <Label>{$_("cancel")}</Label>
    </Button>
    <Button
      action="save"
      default
      defaultAction
      disabled={reasonStatus != "purchased" && !selectedRejectReason}
      class="{reasonStatus != 'purchased' && !selectedRejectReason ? 'grey' : 'tescoblue'} white-text"
      on:click={() => {
        data = null;
        setupDone = false;
        dispatch("rejectPurchasePopupSave", { reason: selectedRejectReason, comment: rejectComment });
      }}
    >
      <Label>{$_(dialogType == "reject" ? "reject" : "undo_reject")}</Label>
    </Button>
  </Actions>
</Dialog>
