<script>
    import Button, { Group } from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiFileUploadOutline, mdiCartArrowDown, mdiCogOutline } from "@mdi/js";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();

    export let disableConfirmButton = false;
    export let disableAutobuyButton = false;
</script>

<Flex direction="row" justify="start" class="gap-1">
    <Group>
        <Button
            class="{disableAutobuyButton ? 'grey' : 'tescoblue'} white-text pr-4"
            title={$_("autobuy")}
            disabled={disableAutobuyButton}
            on:click={() => dispatch("autobuyPurchase")}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
                <path fill="currentColor" d={mdiCartArrowDown} />
            </Icon>
            {$_("autobuy")}
        </Button>

        <Button class="tescoblue white-text icon-1x left-splitter-white" title={$_("setup_autobuy")} on:click={() => dispatch("autobuySetup")}>
            <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
                <path fill="currentColor" d={mdiCogOutline} />
            </Icon>
        </Button>
    </Group>
    <Button
        class="{disableConfirmButton ? 'grey' : 'tescoblue'} white-text pr-4"
        title={disableConfirmButton ? $_("not_all_purchases") : $_("confirm_purchase")}
        disabled={disableConfirmButton}
        on:click={() => dispatch("confirmPurchase")}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
            <path fill="currentColor" d={mdiFileUploadOutline} />
        </Icon>
        {$_("confirm")}
    </Button>
</Flex>
