<script lang="ts">
    import { onMount, createEventDispatcher } from "svelte";
    import { writable } from "svelte/store";
    import Paper from "@smui/paper";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import { handleApiError } from "./lib/errorHandler";

    import {
        getForecast as apiGetForecast,
        createForecast as apiCreateForecast,
        updateForecast as apiUpdateForecast,
        deleteForecast as apiDeleteForecast,
        deleteForecasts as apiDeleteForecasts,
    } from "../../api/Forecast";

    import { updatePurchase as apiUpdatePurchase, getPurchase as apiGetPurchase } from "../../api/Purchase";
    import { createNotificationJob as apiCreateNotificationJob } from "../../api/Notification";

    import { updateWeekStatus, getWeeks } from "../../api/Weeks";

    import { isActionEnabled } from "./lib/Functions";
    import WeekInfo from "./common/WeekInfo.svelte";
    import FilesUploadDialog from "./common/FilesUploadDialog.svelte";
    import GetDataButton from "./common/GetDataButton.svelte";
    import ChangeWeekStatusButton from "./common/ChangeWeekStatusButton.svelte";
    import AddItemButton from "./common/AddItemButton.svelte";
    import UploadButton from "./common/UploadButton.svelte";
    import ClearFCButton from "./common/ClearButton.svelte";
    import SaveFCButton from "./Forecasts/SaveFCButton.svelte";
    import FCFilterBar from "./Forecasts/FCFilterBar.svelte";
    import FCDataTable from "./Forecasts/FCDataTable.svelte";
    import { snackbarSuccess, snackbarWarning, selectedWeek, authenticatedUser, autoLoadData } from "../../stores/AppStatus";
    import { dcList, enableNotifications } from "../../stores/AppConfig";
    // Upload data
    import { uploadFiles } from "./Upload/Upload";
    import { validateUploadedFCData, duplicityCheckUploadedFCData } from "./Upload/ValidateForecast";
    import { forecastNotes } from "../../stores/AppConfig";

    // Add item dialog
    import ConfirmationDialog from "../dialogs/ConfirmationDialog.svelte";
    import FcItemDetail from "./Forecasts/FCItemDetail.svelte";
    import FullscreenLoader from "../elementary/FullscreenLoader.svelte";

    import type { ForecastItems } from "../../types/Forecast";

    export let selectedDC = [];
    export let showUploadDialog = false;

    // Search
    let fileNameSearch = "";
    let tpnbSearch = "";
    let ceTpnSearch = "";
    let tpnbDescSearch = "";
    let ceDescSearch = "";
    let statusSearchArray = [];
    let statusErrors = writable([]);
    let statusSearchErrorsArray = [];

    let fcScreenMode = "edit"; // Possible values = ['edit', 'upload']
    let fcDialogOpen = false;
    let fcDialogType = "edit";
    let fcItems: ForecastItems[] = [];
    let item: ForecastItems;
    let checkboxItems = [];

    let deleteId = "";
    let deleteCount = 0;

    let forecastDeleteConfirmationOpen = false;

    // File file upload
    let filesUploadDialogOpen = false;
    let filesUploadState;
    let filesStatus = [];
    let filesUploadCount = 0;
    let dataLoaded = false;
    let loadingData = false;
    let unsavedRowsExists = false;
    let rowsValid = false;
    let dataValid = false;

    // Loading...
    let apiCallProcessing = false;
    let apiCallProcessingMessage = "";

    let canInsert = false;
    let canUpdate = false;
    let canDelete = false;
    let dmlPossible = false;

    const dispatch = createEventDispatcher();

    $: rowsValid = fcItems && Array.isArray(fcItems) && [...fcItems].filter((x) => x.errors && x.errors.length > 0).length == 0;
    $: uploadFinishedCount = filesStatus ? filesStatus.filter((x) => ["done", "error", "rejected"].includes(x.uploadStatus)).length : 0;
    $: validationFinishedCount = filesStatus ? filesStatus.filter((x) => ["done", "error", "rejected"].includes(x.validationStatus)).length : 0;
    $: console.log({ selectedDC });

    $: if ($autoLoadData) {
        getFCData();
        $autoLoadData = false;
    }

    // create a list of errors while upload mode
    $: if (fcScreenMode === "upload") {
        // All errors and warnings
        const allErrorsAndWarnings = fcItems.flatMap((item) => [
            ...(item.errors ? item.errors.filter((error) => error !== null && error !== undefined) : []),
            ...(item.warnings ? item.warnings.filter((warning) => warning !== null && warning !== undefined) : []),
        ]);

        const uniqueErrors = Array.from(new Map(allErrorsAndWarnings.map((errorOrWarning) => [errorOrWarning.message, errorOrWarning])).values());

        statusErrors.set(uniqueErrors);
    } else {
        statusErrors.set([]); // Clear statusErrors if not in 'upload' mode
    }

    // Find warehouse name
    function findWarehouseName(warehouseId) {
        const warehouse = $dcList.find((dc) => dc.id === warehouseId);
        return warehouse ? warehouse.name : "";
    }

    const changeStatus = async (e) => {
        try {
            console.log("changeStatus()", { e });
            apiCallProcessing = true;
            apiCallProcessingMessage = $_("processing_wait");

            //console.log({ $selectedWeek });

            const newWeekStatus = "forecasted";

            /*             // get week
            const getDbWeeks = await getWeeks(statusChange.tesco_year);
            console.log({getDbWeeks}, $selectedWeek); */

            apiCallProcessing = false;

            const updateWeekRes = await updateWeekStatus({ id: $selectedWeek.id, status: newWeekStatus });
            //console.log({ updateWeekRes });

            if (updateWeekRes) {
                if (enableNotifications && e?.detail?.notifyUsersFlag) {
                    apiCreateNotificationJob($selectedWeek.tesco_year, $selectedWeek.tesco_week, "FORECASTED", null, null, null, null, null, null);
                }

                selectedWeek.update((current) => {
                    return { ...current, status: newWeekStatus };
                });

                // Show success message
                $snackbarSuccess.text = $_("updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
                apiCallProcessing = false;
            } else {
                apiCallProcessing = false;
            }
        } catch (e) {
            console.error("Error in changeStatus()", e);
            if (!handleApiError($_("updated_failed"), e, "non-fatal")) throw e;
        }
    };

    const getFCData = async () => {
        checkboxItems.length = 0;
        try {
            loadingData = true;
            fcScreenMode = "edit";
            const res = await apiGetForecast(null, $selectedWeek.tesco_year, $selectedWeek.tesco_week);
            console.log({ res });
            res.forEach((x) => {
                x.warehouseCode = $dcList.filter((w) => w.id == x.warehouseId).at(0)?.code;
                x.countryOrder = x.country.at(0).replace("C", "1").replace("S", "2").replace("H", "4");
            });
            fcItems = [
                ...res.sort((a, b) =>
                    [a.descriptionEn, a.countryOrder, a.warehouseCode].join("#").toLowerCase() > [b.descriptionEn, b.countryOrder, b.warehouseCode].join("#").toLowerCase()
                        ? 1
                        : -1,
                ),
            ];
            if (fcItems.length == 0) {
                dataValid = true;
                console.log("getFCData().empty");
            } else {
                dataValid = false;
                console.log({ fcItems });
            }
            dispatch("getDataRequest");
            unsavedRowsExists = false;
            dataLoaded = true;
            loadingData = false;
        } catch (error) {
            console.log("getFCData().error");
            console.log(error);
            loadingData = false;
            if (!handleApiError($_("get_data_failed"), error, "non-fatal")) throw error;
        }
    };

    const uploadProgress = (fileName, status, message) => {
        if (fileName) {
            filesStatus.forEach((x) => {
                if (x.fileName == fileName) {
                    x.uploadStatus = status;
                    if (message) {
                        x.errorMessage = message;
                    }
                }
            });
        }
        console.log({ filesStatus });
        filesStatus = filesStatus;
    };

    const validationProgress = (fileName, status, message) => {
        if (fileName) {
            filesStatus.forEach((x) => {
                if (x.fileName == fileName) {
                    x.validationStatus = status;
                    if (message) {
                        x.errorMessage = message;
                    }
                }
            });
        }
        console.log({ filesStatus });
        filesStatus = filesStatus;
    };

    const validateFiles = async (files, validationProgressCallBack) => {
        if (files && files.length > 0) {
            filesUploadCount = files.length;
            const filePromises = files.map(async (f) => {
                validationProgressCallBack(f.fileName, "processing");

                try {
                    if (f.errors && f.errors.size > 0) {
                        validationProgressCallBack(f.fileName, "rejected");
                        let ret = [{ fileName: [f.fileName], errors: f.errors }];
                        console.log({ ret });
                        return ret;
                    } else {
                        let fcData = [...f.items.flatMap((x) => x)];
                        let ret = await validateUploadedFCData(fcData, $selectedWeek, $forecastNotes);

                        validationProgressCallBack(f.fileName, "done");
                        return ret;
                    }
                } catch (error) {
                    console.error("Error during file validation for file:", f.fileName, error);
                    validationProgressCallBack(f.fileName, "error", error.message);
                    throw error;
                }
            });

            return Promise.all(
                filePromises.map((p) =>
                    p.catch((e) => {
                        console.log("Error in file promise:", e);
                        return null; // Return a specific value if error occurs?
                    }),
                ),
            );
        }
    };

    const uploadFCData = async (files) => {
        console.log("================= uploadFCData ==============");
        try {
            filesUploadDialogOpen = true;
            filesUploadState = "started";
            filesStatus.length = 0;
            fcScreenMode = "upload";
            fcItems.length = 0;
            checkboxItems.length = 0;

            for (let f = 0; f < files.length; f++) {
                filesStatus.push({ fileName: files.item(f).name, uploadStatus: "waiting", validationStatus: "waiting" });
            }
            filesStatus = [...filesStatus];
            console.log({ filesStatus });

            const results = await uploadFiles(files, "FC", uploadProgress);
            console.log("Uploaded result: ", { results });
            if (results && filesUploadState == "started") {
                try {
                    const validationResults = await validateFiles(results, validationProgress);
                    console.log({ validationResults });
                    if (validationResults) {
                        for (let fileRows of validationResults) {
                            console.log({ fileRows });
                            if (fileRows && fileRows.at(0)?.productId) {
                                for (let itemRow of fileRows) {
                                    fcItems.push(itemRow);
                                }
                            }
                        }
                        if (filesUploadState == "started") {
                            await duplicityCheckUploadedFCData(fcItems);
                            fcItems = fcItems;
                        } else {
                            fcItems.length = 0;
                        }
                    }
                } catch (error) {
                    console.log("Validation Fails!", { error });
                }
            }

            if (filesUploadState == "started") {
                filesUploadState = "finished";
                console.log("Uploading XLS data finished");
                unsavedRowsExists = true;
                dataLoaded = true;
            } else {
                // cancell requested
                filesUploadDialogOpen = false;
                filesUploadState = "cancelled";
            }
        } catch (error) {
            filesUploadDialogOpen = false;
            console.log({ error });
            if (!handleApiError($_(error.message + " - " + error.cause, { values: { reason: error.message } }), error, "non-fatal")) throw error;
        }
    };

    const clearAllData = () => {
        console.log("================= clearAllData ==============");
        fcItems.length = 0;
        checkboxItems.length = 0;
        fcScreenMode = "edit";
        filesUploadDialogOpen = false;
        dataLoaded = false;
        unsavedRowsExists = false;
    };

    const editForecast = (mode, data) => {
        console.log("================= editForecast ==============");
        fcDialogOpen = true;
        fcDialogType = "edit";
        fcScreenMode = mode;
        item = resetForecastItem();
        if (!data.errors && mode === "edit") {
            data = { ...data, errors: [] };
        }
        item = { ...data };
    };

    function resetForecastItem(): ForecastItems {
        return {
            id: null,
            year: $selectedWeek.tesco_year,
            week: $selectedWeek.tesco_week,
            country: "",
            warehouseId: null,
            productId: null,
            productDetailId: null,
            descriptionEn: "",
            tpnb: "",
            description: "",
            units: "",
            buyer: "",
            technicalManager: "",
            flexVol: 0,
            d1Vol: 0,
            d2Vol: 0,
            d3Vol: 0,
            d4Vol: 0,
            d5Vol: 0,
            d6Vol: 0,
            d7Vol: 0,
            totalVol: 0,
            d1AmendVol: 0,
            d2AmendVol: 0,
            d3AmendVol: 0,
            d4AmendVol: 0,
            d5AmendVol: 0,
            d6AmendVol: 0,
            d7AmendVol: 0,
            purchasedVol: 0,
            purchasedAmendVol: 0,
            note: "",
            status: "new",
            reasonCode: "",
            userComment: "",
            fileName: "",
            errors: [],
            warnings: [],
            buyerDetails: [],
        };
    }

    const deleteFc = async (mode, ids) => {
        console.log("================= deleteFC ==============");
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("processing_wait");

        if (Array.isArray(ids) && ids.length > 0) {
            if (mode === "edit") {
                try {
                    await apiDeleteForecasts(ids);

                    // Removing successfully deleted IDs
                    fcItems = fcItems.filter((item) => !ids.includes(item.id));
                    checkboxItems.length = 0;

                    // Show success message
                    $snackbarSuccess.text = $_("product_detail_deleted");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                    apiCallProcessing = false;
                } catch (error) {
                    checkboxItems.length = 0;
                    apiCallProcessing = false;
                    console.error("Bulk deletion error", error);
                    // Show error message
                    if (!handleApiError($_("delete_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                }
            } else {
                console.log("Array upload");
                // Filter fcItems correctly
                fcItems = fcItems.filter((item) => !ids.includes(item.id));
                checkboxItems.length = 0;
                forecastDeleteConfirmationOpen = false;
                if (fcItems.length === 0) {
                    unsavedRowsExists = false;
                    fcScreenMode = "edit";
                } else if (fcItems.length > 1) {
                    await duplicityCheckUploadedFCData(fcItems);
                }
                apiCallProcessing = false;
            }
        } else {
            if (mode === "edit" && ids) {
                try {
                    await apiDeleteForecast(ids);
                    console.log("DB Deletion success of single row");

                    // Remove the deleted item from fcItems and checkboxItems
                    fcItems = fcItems.filter((item) => item.id !== ids);
                    checkboxItems.length = 0;

                    // Perform duplicity check after deletion
                    if (fcItems.length === 0) {
                        unsavedRowsExists = false;
                        fcScreenMode = "edit";
                    } else if (fcItems.length > 1) {
                        await duplicityCheckUploadedFCData(fcItems);
                    }

                    // Show success message
                    $snackbarSuccess.text = $_("forecast_deleted");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                    apiCallProcessing = false;
                } catch (error) {
                    console.log({ error });
                    checkboxItems.length = 0;
                    apiCallProcessing = false;

                    // Show error message
                    if (!handleApiError($_("delete_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                }
            } else {
                // Remove the deleted item from fcItems and checkboxItems
                fcItems = fcItems.filter((item) => item.id !== ids);
                checkboxItems.length = 0;

                // Perform duplicity check after deletion
                if (fcItems.length === 0) {
                    unsavedRowsExists = false;
                    fcScreenMode = "edit";
                } else if (fcItems.length > 1) {
                    await duplicityCheckUploadedFCData(fcItems);
                }
            }

            forecastDeleteConfirmationOpen = false;
            apiCallProcessing = false;
        }
    };

    // Purchase status update to 'prepared' if forecast gets updated to 'updated'
    function handlePurchaseUpdates(forecastId): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            console.log("================= handlePurchaseUpdates ==============");
            apiGetPurchase(null, null, null, null, null, null, null, null, null, null, forecastId)
                .then((purchaseRes) => {
                    // Extract "confirmed" purchases and change to "prepared"
                    const confirmedPurchases = purchaseRes.filter((p) => p.status === "confirmed").map((purchase) => ({ ...purchase, status: "prepared" }));
                    console.log("confirmedPurchases: ", confirmedPurchases);
                    Promise.all(confirmedPurchases.map((purchase) => apiUpdatePurchase(purchase)))
                        .then((updateResults) => {
                            console.log("All purchases updated successfully:", updateResults);
                            resolve();
                        })
                        .catch((updatePurchaseError) => {
                            console.error("Error updating one or more purchases:", updatePurchaseError);
                            reject(updatePurchaseError);
                        });
                })
                .catch((purchaseError) => {
                    console.error("Error fetching purchases:", purchaseError);
                    reject(purchaseError);
                });
        });
    }

    const saveForecastRow = (type, mode, row) => {
        console.log("================= saveForecastRow ==============");
        console.log({ type });
        console.log({ mode });
        console.log({ row });
        checkboxItems.length = 0;
        if (type === "add" && mode === "edit") {
            // Add New Forecast
            apiCreateForecast(row)
                .then((res) => {
                    console.log({ res });
                    row.id = res[0].id;
                    fcItems.push(row);
                    fcItems = fcItems;
                    $snackbarSuccess.text = $_("forecast_created");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("create_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        } else {
            // Edit in mode edit or upload
            // Update dataTable data
            console.log("else");
            // In edit mode update data directly
            if (mode == "edit") {
                let statusChanged = false;
                if (row.status !== "new") {
                    row.status = "updated";
                    row.reasonCode = "";
                    statusChanged = true;
                }

                apiUpdateForecast(row)
                    .then((res) => {
                        console.log({ res });
                        // Update the row in fcItems array
                        const index = fcItems.findIndex((item) => item.id === row.id);
                        if (index !== -1) {
                            fcItems[index] = { ...fcItems[index], ...row };
                            fcItems = [...fcItems];
                        }

                        // If status was changed and the forecast update was successful change the status in purchase too
                        if (statusChanged && res) {
                            handlePurchaseUpdates(row.id)
                                .then(() => {
                                    $snackbarSuccess.text = $_("product_detail_updated");
                                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                                })
                                .catch((error) => {
                                    if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                                });
                        } else {
                            $snackbarSuccess.text = $_("product_detail_updated");
                            $snackbarSuccess.element && $snackbarSuccess.element.open();
                        }
                    })
                    .catch((updateForecastError) => {
                        fcDialogOpen = false;
                        console.log(updateForecastError);
                        if (!handleApiError($_("update_failed", { values: { reason: updateForecastError.message } }), updateForecastError, "non-fatal")) throw updateForecastError;
                    });
            } else if (mode == "upload") {
                // Update the row in fcItems array
                // Type Edit, mode Upload
                console.log("row", row, { fcItems });
                const index = fcItems.findIndex((item) => item.id === row.id);
                console.log("index", index);
                if (index !== -1) {
                    fcItems[index] = { ...fcItems[index], ...row };
                    fcItems = [...fcItems];
                    console.log("row", row, { fcItems });
                }
                fcDialogOpen = false;
            }
        }
    };

    const saveAllUploadedData = (data) => {
        console.log("================= saveAllUploadedData ==============");
        console.log({ data });

        checkboxItems.length = 0;

        apiCallProcessing = true;
        apiCallProcessingMessage = $_("processing_wait");

        // Set all id to null
        const preparedData = data.map((item) => ({
            ...item,
            id: null,
        }));

        // Create and update if exists
        apiCreateForecast(preparedData)
            .then((res) => {
                console.log({ res });

                // If status was changed to "updated (in Validation) change the purcahse status too
                const purchaseUpdatePromises = res.map((item) => {
                    if (item.id !== null && item.status === "updated") {
                        return handlePurchaseUpdates(item.id).catch((error) => {
                            console.error("Error updating purchase:", error);
                            return { error, itemId: item.id }; // Return error and item ID to handle later
                        });
                    }
                    return Promise.resolve(null);
                });

                // Wait for all purchase updates
                Promise.all(purchaseUpdatePromises).then((purchaseUpdateResults) => {
                    const purchaseErrors = purchaseUpdateResults.filter((result) => result && result.error);

                    // Restore the id if res did not provide the id
                    const restoredRes = res.map((item, index) => {
                        if (item.id === null && data[index] && data[index].id !== null) {
                            return { ...item, id: data[index].id };
                        }
                        return item;
                    });

                    // Check for errors or warnings in response
                    const itemsWithIssues = restoredRes.filter((item) => (item.errors && item.errors.length > 0) || (item.warnings && item.warnings.length > 0));

                    if (itemsWithIssues.length > 0 || purchaseErrors.length > 0) {
                        // Show just items with some issues and handle purchase errors
                        fcItems = itemsWithIssues;

                        let errorMessage = "Data where already present, please check";
                        if (purchaseErrors.length > 0) {
                            const purchaseErrorReasons = purchaseErrors.map((pe) => `Item ID ${pe.itemId}: ${pe.error.message}`).join(", ");
                            errorMessage += `; Purchase update errors: ${purchaseErrorReasons}`;
                        }

                        $snackbarWarning.text = $_("create_failed", { values: { reason: errorMessage } });
                        $snackbarWarning.element && $snackbarWarning.element.open();
                    } else {
                        // Else show all processed rows
                        fcItems = restoredRes;
                        $snackbarSuccess.text = $_("forecast_created");
                        $snackbarSuccess.element && $snackbarSuccess.element.open();
                        unsavedRowsExists = false;
                        fcScreenMode = "edit";
                    }
                    apiCallProcessing = false;
                });
            })
            .catch((error) => {
                apiCallProcessing = false;
                console.log(error);
                if (!handleApiError($_("create_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            });
    };

    const handleEvents = async (event) => {
        console.log("================= handleEvents ==============");
        console.log({ event });
        switch (event.type) {
            case "fcDialogCancel":
                console.log("Event Cancel Forecast");
                fcDialogOpen = false;
                break;
            case "ForecastDialogSave":
                console.log("Event Save Forecast Row");
                fcDialogOpen = false;
                // Update Product Detail table on screen
                fcItems.filter((x) => x.id == event.detail.item.id)[0] = event.detail.item;
                saveForecastRow(event.detail.type, fcScreenMode, event.detail.item);
                break;
            case "editForecastRow":
                console.log("Event Edit Forecast Row");
                editForecast(fcScreenMode, event.detail);
                break;
            case "deleteForecastRow":
                console.log("Event Delete Forecast Row");
                deleteId = null;
                deleteId = event.detail;
                deleteCount = 1;
                forecastDeleteConfirmationOpen = true;
                break;
            case "deleteSelectedForecastRow":
                console.log("Event Delete multiple Forecast Rows");
                deleteId = null;
                deleteId = event.detail;
                deleteCount = deleteId.length;
                forecastDeleteConfirmationOpen = true;
                break;
            case "openFileDialogConfirmed":
                console.log("Event Import Forecast");
                uploadFCData(event.detail);
                break;
            case "saveAllData":
                console.log("Event Save All Imported Forecast");
                saveAllUploadedData(fcItems);
                break;
            case "clearAllData":
                console.log("Event Clear All Imported Forecast");
                clearAllData();
                break;
            default:
                console.log("Unknown event: " + event.type);
        }
    };

    const weekDataInvalidated = () => {
        dataLoaded = false;
        fcItems.length = 0;
        checkboxItems.length = 0;
        unsavedRowsExists = false;
        updatePrivileges();
    };

    onMount(() => {
        updatePrivileges();
        checkboxItems.length = 0;
    });

    const updatePrivileges = () => {
        if ($selectedWeek && $selectedWeek.status) {
            canInsert = isActionEnabled("forecasts", "insert", $selectedWeek.status);
            canUpdate = isActionEnabled("forecasts", "edit", $selectedWeek.status);
            canDelete = isActionEnabled("forecasts", "delete", $selectedWeek.status);
            dataValid = false;
        } else {
            canInsert = canUpdate = canDelete = false;
        }
        dmlPossible = canInsert && canUpdate && canDelete;
    };
    //$: console.log("canInsert", canInsert, "canUpdate",canUpdate,"canDelete",canDelete);
</script>

<section class="fc main-data-view">
    <section class="w-100 header top-splitter">
        <Flex direction="row" class="grey lighten-3 tescoblue-text bs-bb pr-4 w-100 gap-1">
            <WeekInfo disabled={fcScreenMode == "upload"} on:weekChanged={weekDataInvalidated} on:weekStatusChanged={updatePrivileges} />
            <GetDataButton approvalNeeded={unsavedRowsExists} approvalMessage={unsavedRowsExists ? $_("discard_unsaved_changes") : ""} on:get={getFCData} />
            {#if !$authenticatedUser.isReadOnly}
                <Flex direction="row" justify="end" class="gap-1">
                    <ChangeWeekStatusButton
                        approvalNeeded={true}
                        approvalMessage={$_("do_you_want_open_buying?", { values: { week: $selectedWeek.tesco_week } })}
                        showNotifyFlag={enableNotifications}
                        title={"open_buying"}
                        disabled={($selectedWeek && $selectedWeek.status && $selectedWeek.status !== "open") || $authenticatedUser.role !== "admin"}
                        on:changeStatus={changeStatus}
                    />
                    {#if fcScreenMode != "upload"}
                        <AddItemButton
                            disabled={!canInsert}
                            hint={dataLoaded ? "" : $_("load_week_data_first")}
                            on:addItemBtnClicked={() => {
                                item = resetForecastItem();
                                fcDialogType = "add";
                                fcDialogOpen = true;
                            }}
                        />
                        <UploadButton bind:dialogOpen={showUploadDialog} disabled={!canInsert} on:openFileDialogConfirmed={handleEvents} />
                    {/if}
                    {#if fcScreenMode == "upload"}
                        <ClearFCButton title={$_("forecast")} on:clearAllData={handleEvents} />
                        <SaveFCButton dataValidFlag={fcScreenMode != "upload" || (fcScreenMode == "upload" && rowsValid)} on:saveAllData={handleEvents} />
                    {/if}
                </Flex>
            {/if}
        </Flex>
    </section>

    <section class="filters tescoblue-border-top-thin">
        <Paper class="grey lighten-3 w-100 bs-bb pt-0 pb-1 mt-0 mb-0">
            <div class="smui-paper__content bs-bb">
                <section class="mt-2 pt-2 w-100">
                    <FCFilterBar
                        bind:ceTpnSearch
                        bind:ceDescSearch
                        bind:tpnbSearch
                        bind:tpnbDescSearch
                        bind:selectedDC
                        bind:statusSearchArray
                        bind:fileNameSearch
                        bind:statusSearchErrorsArray
                        {fcScreenMode}
                        {statusErrors}
                    />
                </section>
            </div>
        </Paper>
    </section>

    <section class="tescoblue-border-top-thin">
        <Paper class="grey lighten-3 w-100 bs-bb pt-3">
            <div class="smui-paper__content bs-bb table-container">
                <FCDataTable
                    items={fcItems}
                    {loadingData}
                    {tpnbSearch}
                    {ceTpnSearch}
                    {tpnbDescSearch}
                    {ceDescSearch}
                    {statusSearchErrorsArray}
                    {fileNameSearch}
                    {canUpdate}
                    {canDelete}
                    {dmlPossible}
                    bind:dataValid
                    bind:checkboxItems
                    selectedDC={selectedDC.map((x) => x.id)}
                    statusSearchArray={statusSearchArray.map((x) => x.id)}
                    screenMode={fcScreenMode}
                    on:editForecastRow={handleEvents}
                    on:deleteForecastRow={handleEvents}
                    on:deleteSelectedForecastRow={handleEvents}
                />
            </div>
        </Paper>
    </section>
</section>
<FullscreenLoader open={apiCallProcessing} text={apiCallProcessingMessage} />

{#key item}
    <FcItemDetail
        open={fcDialogOpen}
        title={fcDialogType == "add" ? $_("add_item") : $_("edit_item")}
        item={{ ...item }}
        screenMode={fcScreenMode}
        type={fcDialogType}
        dcList={$dcList}
        warehouseNameForDisplay={findWarehouseName(item?.warehouseId)}
        on:reject={() => {
            item = resetForecastItem();
            fcDialogOpen = false;
        }}
        on:ForecastDialogSave={handleEvents}
    ></FcItemDetail>
{/key}

<ConfirmationDialog
    bind:open={forecastDeleteConfirmationOpen}
    title={$_("delete_forecast")}
    noBtnText={$_("no")}
    itemData={null}
    yesBtnText={$_("yes")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        forecastDeleteConfirmationOpen = false;
    }}
    on:confirm={() => {
        deleteFc(fcScreenMode, deleteId);
        forecastDeleteConfirmationOpen = false;
    }}
>
    {$_("delete_selected?", { values: { rows: deleteCount } })}
</ConfirmationDialog>

<FilesUploadDialog
    bind:filesUploadDialogOpen
    bind:filesUploadState
    {uploadFinishedCount}
    {filesUploadCount}
    {filesStatus}
    {validationFinishedCount}
    items={fcItems}
    on:clearAllData={handleEvents}
/>
