<script>
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiFileUploadOutline } from "@mdi/js";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";

    import ConfirmationDialog from "../../dialogs/ConfirmationDialog.svelte";
    import InfoDialog from "../../dialogs/InfoDialog.svelte";

    export let dataValidFlag = true;

    let dialogOpen = false;
    let errDialogOpen = false;

    const dispatch = createEventDispatcher();
</script>

<Flex direction="row" justify="start" class="gap-1">
    <Button
        class="tescoblue white-text pr-4 nowrap"
        title={$_("save_quote_sheet")}
        on:click={() => {
            console.log({ dataValidFlag });
            if (dataValidFlag) {
                console.log("dialogOpen");
                dialogOpen = true;
            } else {
                console.log("errDialogOpen");
                errDialogOpen = true;
            }
        }}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
            <path fill="currentColor" d={mdiFileUploadOutline} />
        </Icon>
        {$_("save_quote_sheet")}
    </Button>
</Flex>

<ConfirmationDialog
    open={dialogOpen}
    title={$_("save_quote_sheet")}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={false}
    itemData={null}
    headerColors="tescoblue white-text"
    on:reject={() => {
        dialogOpen = false;
    }}
    on:confirm={() => {
        dialogOpen = false;
        dispatch("saveAllData");
    }}
>
    {$_("save_all_uploaded_quote_sheet_data")}
</ConfirmationDialog>

<InfoDialog
    open={errDialogOpen}
    title={$_("save_quote_sheet")}
    btnText={$_("close")}
    big={false}
    headerColors="tescoblue white-text"
    on:close={() => {
        errDialogOpen = false;
    }}
>
    {$_("please_first_resolve_invalid_rows")}
</InfoDialog>
