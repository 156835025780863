<script>
    import Dialog, { Title as DTitle, Header, Content as DContent } from "@smui/dialog";
    import Button from "@smui/button";
    import { Label } from "@smui/common";
    import { _ } from "svelte-i18n";

    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();

    export let title;
    export let noBtnText;
    export let yesBtnText;
    export let headerColors = "tescoblue white-text";
    export let open = false;
    export let big = false;

    const dialogConfirmed = () => {
        dispatch("confirmed");
        open = false;
    };
</script>

<Dialog
    bind:open
    aria-labelledby="data-changed-title"
    aria-describedby="data-changed-content"
    class="pa-2"
    surface$style={big ? "width: 850px; max-width: calc(100vw - 32px);" : ""}
>
    <DTitle id="data-changed-title" class={headerColors}>{title}</DTitle>
    <DContent id="data-changed-content" class="mt-4">
        {$_("save_autofill_data")}
    </DContent>

    <div class="mdc-dialog__actions gap-1 pr-4 mr-4">
        <Button
            action="close"
            class="tescored tescored-text outlined"
            on:click={() => {
                open = false;
                dispatch("cancelled");
            }}
        >
            <Label>
                {noBtnText}
            </Label>
        </Button>
        <Button action="save" default defaultAction class="tescoblue white-text" on:click={dialogConfirmed}>
            <Label>
                {yesBtnText}
            </Label>
        </Button>
    </div>
</Dialog>
