<script lang="ts">
    import Autocomplete from "../../extra/Autocomplete.svelte";
    import { onMount } from "svelte";
    import Textfield from "@smui/textfield";
    import { Icon } from "@smui/icon-button";
    import { mdiMagnify, mdiCloseCircleOutline } from "@mdi/js";
    import { _ } from "svelte-i18n";
    import { classMap } from "@smui/common/internal";
    import { buyersList } from "../../../stores/Buyers";
    import type { Buyers } from "../../../types/Buyers";

    export let uuidOutput = "";
    export let icon = true;
    export let disableFields: boolean = false;
    export let clear: boolean = false; // Signal to clear

    let className = "";
    export { className as class };

    export let label = null;
    export let variant: "outlined" | "standard" | "filled" = "outlined";
    export let insideDataTable = false;

    // Trailing icon ready for implementation
    let trailingIcon = false;

    // Set menu width according to parent element
    let edEl;
    let menuWidth;
    let menuBottom;

    // Get buyer list
    let options: Buyers[] = $buyersList.sort((a, b) => a.lastName.localeCompare(b.lastName));
    let value: Buyers | undefined = undefined;
    let text = "";

    // Find matching buyer and preselect it
    onMount(() => {
        if (uuidOutput) {
            value = options.find((o) => o.uuid === uuidOutput) || undefined;
        } else {
            value = undefined;
        }
    });

    $: if (clear) {
        clear = false;
        value = undefined;
        uuidOutput = "";
    }

    function handleAutocompleteChange(detail) {
        uuidOutput = detail.detail?.uuid || "";
    }

    function handleFocusIn() {
        menuWidth = edEl?.getElement()?.clientWidth;
        menuBottom = edEl?.getElement()?.getBoundingClientRect()?.bottom;
    }
</script>

<Autocomplete
    data-qa="buyer-search-autocomplete"
    {options}
    getOptionLabel={(option) => (option ? `${option.lastName} ${option.firstName}` : "")}
    bind:text
    bind:value
    bind:this={edEl}
    disabled={disableFields}
    selectOnExactMatch={true}
    class={classMap({
        [className]: true,
    })}
    menu$class={insideDataTable ? "mdc-menu-surface--fixed with-parameter__menu" : ""}
    on:focusin={handleFocusIn}
    style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
    on:click={() => {
        menuWidth = edEl?.getElement()?.clientWidth;
    }}
    on:SMUIAutocomplete:selected={(event) => {
        handleAutocompleteChange(event);
    }}
>
    <Textfield
        {variant}
        data-qa="buyer-search-autocomplete-textfield"
        bind:value={text}
        withLeadingIcon={icon}
        withTrailingIcon={trailingIcon}
        label={label ? $_(label) : null}
        input$autocomplete="BuyerSearch"
        disabled={disableFields}
        class={classMap({
            [className]: true,
        })}
        on:change={() => {
            uuidOutput = "";
        }}
    >
        <svelte:fragment slot="leadingIcon">
            {#if icon}
                <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon">
                    <path fill="currentColor" d={mdiMagnify} />
                </Icon>
            {/if}
        </svelte:fragment>
        <svelte:fragment slot="trailingIcon">
            {#if trailingIcon}
                <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-trailing-icon">
                    <path fill="currentColor" d={mdiCloseCircleOutline} />
                </Icon>
            {/if}
        </svelte:fragment>
    </Textfield>
</Autocomplete>
