<script lang="ts">
    import Paper from "@smui/paper";
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import SuppDataTable from "./SuppDataTable.svelte";
    import FilterSuppDataTable from "./FilterSuppDataTable.svelte";
    import { authenticatedUser } from "../../../../stores/AppStatus";

    import { getSupplier } from "../../../../api/Supplier";
    import type { CeSupplier } from "../../../../types/Management";

    let showDetails = false;
    let backClicked = false;
    let addSupp;

    // Table loading
    let tableLoad = false;
    let tableSearchMessage = true;
    let ceSuppliers: CeSupplier[] = [];

    const handleEvents = (event) => {
        console.log("handleEvents");
        console.log(event.type);
        let data;
        switch (event.type) {
            case "loadSupplierList":
                tableLoad = true;
                data = event.detail;
                console.log(data);
                apiLoadCeSupplier(data.id, data.name, data.status, data.rmsSupplierNo);
                break;
            case "clearSupplierList":
                ceSuppliers = [];
                tableSearchMessage = true;
                tableLoad = false;
                break;
            default:
                console.log("Unknown event: " + event.type);
        }
    };

    async function apiLoadCeSupplier(id, name, status, rmsSupplierNo) {
        try {
            ceSuppliers = await getSupplier(id, name, status, rmsSupplierNo);
            if (ceSuppliers.length == 0) {
                console.log("apiLoadCeSupplier().empty");
                tableSearchMessage = false;
            }
            tableLoad = false;
        } catch (error) {
            console.error("Error fetching suppliers:", error);
            if (error?.cause?.response?.status == 401) {
                throw error;
            }
        }
    }
</script>

<section class="header w-100 top-splitter">
    <Flex direction="row" class="grey lighten-3 tescoblue-text w-100 bs-bb pr-4 gap-1 pt-3 pb-3 pl-3" justify="between">
        <Flex direction="row" class="gap-1 w-100">
            {#if showDetails}
                <Button
                    on:click={() => {
                        backClicked = true;
                    }}
                    class="tescoblue white-text pr-4 nowrap"
                    title={$_("back")}
                    style="width: 7rem;"
                >
                    <Icon class="material-icons">arrow_back_ios_new</Icon>
                    {$_("back")}
                </Button>
            {/if}
            <div class="nowrap font-weight-bold">
                {$_("suppliers_management")}
            </div>
            <Flex direction="row" justify="end" class="w-100">
                <section hidden={showDetails}>
                    <Button
                        title={$_("add_supp")}
                        disabled={!$authenticatedUser.isAdmin}
                        class="{!$authenticatedUser.isAdmin ? 'grey-text white-grey-outlined' : 'tescoblue-text white-tescoblue-outlined'} pr-4 nowrap"
                        on:click={() => addSupp.openDetails()}
                    >
                        <Icon class="material-icons">add_circle</Icon>
                        {$_("add_supp")}
                    </Button>
                </section>
            </Flex>
        </Flex>
    </Flex>
</section>

{#if !showDetails}
    <section class="filters tescoblue-border-top-thin">
        <Paper class="grey lighten-3 w-100 bs-bb">
            <Flex direction="column" class="gap-1" align="stretch">
                <section hidden={showDetails} class="w-100">
                    <FilterSuppDataTable on:loadSupplierList={handleEvents} on:clearSupplierList={handleEvents} />
                </section>
            </Flex>
        </Paper>
    </section>
{/if}

<section class="data w-100 tescoblue-border-top-thin">
    <Paper class="grey lighten-3 w-100 bs-bb pt-3">
        <Flex direction="column" class="gap-1" align="stretch">
            <SuppDataTable bind:showDetails bind:backClicked bind:this={addSupp} bind:ceSuppliers bind:tableLoad bind:tableSearchMessage />
        </Flex>
    </Paper>
</section>
