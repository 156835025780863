<script>
    import Select, { Option } from "@smui/select";
    import Dialog, { Title, Header, Content } from "@smui/dialog";
    import Button from "@smui/button";
    import Textfield from "@smui/textfield";
    import { Label } from "@smui/common";
    import { _ } from "svelte-i18n";
    import Flex from "svelte-flex";

    import { rejectPurchaseReasons } from "../../../stores/AppConfig";

    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();

    export let open = false;
    export let insideDialog = false;
    export let fcStatus;
    export let selectedPurchaseDiffReason;
    export let purchaseDiffComment;

    let listEl;
    let menuWidth;
    let menuBottom;

    const dialogConfirmed = () => {
        dispatch("confirmed");
        open = false;
    };

    $: {
        console.log("---- ----");
        console.log({ open });
        console.log({ fcStatus });
        console.log({ selectedPurchaseDiffReason });
        console.log({ purchaseDiffComment });
    }
</script>

<Dialog bind:open slot={insideDialog ? "over" : ""} class="pa-2" surface$style="min-height:300px; min-width: 400px;">
    <Header>
        <Title id="over-fullscreen-confirmation-title" class="tescoblue white-text">
            {$_(fcStatus)}
        </Title>
    </Header>
    <Content id="over-fullscreen-confirmation-content" style="display:flex;">
        {#key fcStatus}
            <Flex direction="column" align="start">
                <!-- Reason of rejection -->
                <Select
                    variant="outlined"
                    label={$_("reason")}
                    class="white small mt-5 mb-5 with-parameter w-100"
                    bind:value={selectedPurchaseDiffReason}
                    bind:this={listEl}
                    key={(listItem) => `${listItem ? listItem.id : ""}`}
                    style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
                    menu$class="mdc-menu-surface--fixed with-parameter__menu"
                    menu$style="max-width: fit-content !important;"
                    on:focusin={() => {
                        menuWidth = listEl?.getElement()?.clientWidth;
                        menuBottom = listEl?.getElement()?.getBoundingClientRect()?.bottom;
                    }}
                    on:click={() => {
                        menuWidth = listEl?.getElement()?.clientWidth;
                        menuBottom = listEl?.getElement()?.getBoundingClientRect()?.bottom;
                    }}
                >
                    <Option value="" />
                    {#each $rejectPurchaseReasons.filter((x) => x.statuses.includes(fcStatus)) as reasonCodeItem (reasonCodeItem.id)}
                        <Option value={reasonCodeItem.id}>{$_(reasonCodeItem.name)}</Option>
                    {/each}
                </Select>

                <!-- Comment -->
                <Textfield
                    variant="outlined"
                    bind:value={purchaseDiffComment}
                    maxlength="500"
                    placeholder=""
                    title={$_("comment")}
                    class="white small"
                    style="min-width: 500px;"
                    input$class="mnw-100"
                    input$emptyValueUndefined
                    size="40"
                    label={$_("comment")}
                ></Textfield>
            </Flex>
        {/key}
    </Content>
    <div class="mdc-dialog__actions gap-1 pr-4 mr-4">
        <Button
            action="close"
            class="tescored tescored-text outlined"
            on:click={() => {
                open = false;
                dispatch("cancelled");
            }}
        >
            <Label>{$_("cancel")}</Label>
        </Button>
        <Button
            action="save"
            default
            defaultAction
            disabled={!selectedPurchaseDiffReason}
            class="{selectedPurchaseDiffReason ? 'tescoblue' : 'grey'} white-text"
            on:click={dialogConfirmed}
        >
            <Label>{$_("ok")}</Label>
        </Button>
    </div>
</Dialog>
