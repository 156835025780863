<script>
    import { appVersion } from "../stores/AppConfig";

    import Flex from "svelte-flex";
</script>

<Flex direction="row" class="w-100 grey lighten-3 pl-2 pr-2 pt-1 pb-1" justify="between">
    <div></div>
    <div>©2025 - <b>CE Tesco</b></div>
    <div class="fs-08rem">{$appVersion}</div>
</Flex>
