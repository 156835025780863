<script lang="ts">
  import { _ } from "svelte-i18n";
  import DataTable, { Head, Body, Row, Cell, Pagination, Label, SortValue } from "@smui/data-table";
  import IconButton, { Icon } from "@smui/icon-button";
  import { mdiPencilBoxOutline, mdiDelete } from "@mdi/js";
  import Flex from "svelte-flex";

  import { createEventDispatcher } from "svelte";

  export let rows = [];

  const dispatch = createEventDispatcher();

  type DCs = {
    id: number;
    country: string;
    name: string;
    code: string;
    address: string;
  };
</script>

<DataTable stickyHeader table$aria-label="DC list" class="report" style="width: 100%; max-height: calc(100vh - 250px);">
  <Head>
    <Row>
      <Cell columnId="actions" class="datatable-header">
        <Label></Label>
      </Cell>
      <Cell numeric columnId="id" class="datatable-header">
        <Label>{$_("id")}</Label>
      </Cell>
      <Cell columnId="country" class="datatable-header text-center">
        <Label>{$_("country")}</Label>
      </Cell>
      <Cell columnId="code" class="datatable-header">
        <Label>{$_("code")}</Label>
      </Cell>
      <Cell columnId="type" class="datatable-header">
        <Label>{$_("type")}</Label>
      </Cell>
      <Cell columnId="site" class="datatable-header">
        <Label>{$_("site")}</Label>
      </Cell>
      <Cell columnId="name" class="datatable-header">
        <Label>{$_("live")}</Label>
      </Cell>
      <Cell columnId="name" class="datatable-header">
        <Label>{$_("name")}</Label>
      </Cell>
      <Cell columnId="location" class="datatable-header">
        <Label>{$_("location")}</Label>
      </Cell>
      <Cell columnId="participation" class="datatable-header">
        <Label>{$_("participation")}</Label>
      </Cell>
      <Cell columnId="display_order" class="datatable-header">
        <Label>{$_("display_order")}</Label>
      </Cell>
    </Row>
  </Head>
  <Body>
    {#if !rows || rows.length == 0}
      <Row>
        <td colspan="3" class="mdc-data-table__cell" style="width: 100%">{$_("no_data")}</td>
      </Row>
    {:else}
      {#each rows.sort((x, y) => (Number(x.id) > Number(y.id) ? 1 : -1)) as dc (dc.id)}
        <Row>
          <Cell class="pa-0 ma-0">
            <Flex direction="row" class="gap-0">
              <IconButton
                title={$_("edit")}
                size="button"
                class="tescoblue-text opacity-1 pa-0 ma-0"
                on:click={() => {
                  dispatch("editRow", dc.id);
                }}
              >
                <Icon tag="svg" viewBox="0 0 24 24" class="pa-0 ma-0">
                  <path fill="currentColor" d={mdiPencilBoxOutline} />
                </Icon>
              </IconButton>
              <IconButton
                title={$_("delete")}
                size="button"
                class="tescoblue-text opacity-1 pa-0 ma-0"
                on:click={() => {
                  dispatch("deleteRow", dc.id);
                }}
              >
                <Icon tag="svg" viewBox="0 0 24 24" class="pa-0 ma-0">
                  <path fill="currentColor" d={mdiDelete} />
                </Icon>
              </IconButton>
            </Flex>
          </Cell>
          <Cell numeric>{dc.id}</Cell>
          <Cell class="text-center">{dc.country}</Cell>
          <Cell>{dc.code}</Cell>
          <Cell>{dc.type}</Cell>
          <Cell>{dc.site > 0 ? dc.site : ""}</Cell>
          <Cell>{dc.live ? $_("yes") : $_("no")}</Cell>
          <Cell>{dc.name}</Cell>
          <Cell>{dc.location}</Cell>
          <Cell numeric>{dc.participation}</Cell>
          <Cell numeric>{dc.displayOrder}</Cell>
        </Row>
      {/each}
    {/if}
  </Body>
</DataTable>
