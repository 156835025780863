<script>
    import Paper, { Title, Content } from "@smui/paper";
    import Flex from "svelte-flex";
    import Button from "@smui/button";
    import FormField from "@smui/form-field";
    import Textfield from "@smui/textfield";
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import Checkbox from "@smui/checkbox";
    import { format } from "date-fns";
    import { _ } from "svelte-i18n";
    import Chip, { Set, Text } from "@smui/chips";
    import { handleApiError } from "./lib/errorHandler";

    import { snackbarSuccess, snackbarError, snackbarWarning, authenticatedUser } from "../../stores/AppStatus";
    import { roles, dashboardList, weekStatuses, notificationMap, enableNotifications, appParameters } from "../../stores/AppConfig";
    import { weeks } from "../../stores/Weeks";
    import { getForecast as apiGetForecast } from "../../api/Forecast";
    import {
        getWeek as apiGetWeek,
        getWeekDashboard as apiGetWeekDashboard,
        updateWeekStatus as apiUpdateWeekStatus,
        updateWeekDeadlineNotification as apiUpdateWeekDeadlineNotification,
    } from "../../api/Weeks";
    import { createNotificationJob as apiCreateNotificationJob } from "../../api/Notification";

    import ConfirmationDialog from "../dialogs/ConfirmationDialog.svelte";
    import QSSummaryCard from "./QuoteSheets/QSSummaryCard.svelte";
    import QSSummarySupplierCard from "./QuoteSheets/QSSummarySupplierCard.svelte";
    import FCSummaryCard from "./Forecasts/FCSummaryCard.svelte";
    import AmSummaryCard from "./SupplierAmendments/AmSummaryCard.svelte";
    import TescoAppLogo from "../../components/elementary/TescoAppLogo.svelte";
    import FullscreenLoader from "../elementary/FullscreenLoader.svelte";
    import { mdiNewspaperVariantMultiple } from "@mdi/js";
    
    export let pageEmpty = false;
    export let refreshFlag = false;

    let confirmChangeWeekText;
    let confirmChangeWeekTitle;
    let confirmChangeWeekValues = {};
    let confirmChangeWeekNotifyUsers = false;
    let confirmChangeWeekOpen = false;
    let confirmChangeWeekId;
    let confirmChangeWeekNewStatus;
    let confirmChangeWeekNotifyFlagVisible = false;

    let confirmChangeDeadlineWeekId = null;
    let confirmChangeDeadlineOpen = false;
    let confirmChangeDeadlineQsDeadlineDate = null;
    let confirmChangeDeadlineQsDeadlineNotifDate = null;
    let confirmChangeDeadlineAmendmentNotifTime = null;
    let oldConfirmChangeDeadlineQsDeadlineDate = null;
    let oldConfirmChangeDeadlineQsDeadlineNotifDate = null;
    let oldConfirmChangeDeadlineAmendmentNotifTime = null;
    let confirmChangeDeadlineOpenWeekNotifSent = null;
    let confirmChangeDeadlineQsDeadlineNotifSent = null;
    let confirmChangeDeadlineForecastedNotifSent = null;
    let confirmChangeDeadlinePurchasedNotifSent = null;
    let confirmChangeDeadlineMinDate = null;
    let confirmChangeDeadlineMaxDate = null;
    let confirmChangeDeadlineDataValid = false;
    let notSentString = "not_sent_yet";

    // Loading...
    let apiCallProcessing = false;
    let apiCallDeadlineProcessing = false;

    $: if (refreshFlag) {
        refreshDashboard();
        refreshFlag = false;
    }

    async function refreshDashboard() {
        console.log("================= refreshDashboard ==============");
        apiCallProcessing = true;
        // console.log("B:", {$dashboardList});
        const ret = await apiGetWeekDashboard();
        console.log({ ret });
        if (ret && Array.isArray(ret)) {
            ret.forEach((w) => {
                if (w.forecasts && Array.isArray(w.forecasts)) {
                    w.forecasts.forEach((r) => {
                        r.fcPurchasedLines = r.fcPurchasedLines + r.fcPurchasedLessLines + r.fcPurchasedMoreLines;
                    });
                }
            });
            $dashboardList = [...ret];
            // console.log("A:", {$dashboardList});
        } else {
            console.log("$dashboardList is empty");
        }
        apiCallProcessing = false;
    }

    async function changeWeekStatus(weekId, year, week, currStatus) {
        console.log("================= changeWeekStatus ==============");
        console.log({ weekId });
        console.log({ year });
        console.log({ week });
        console.log({ currStatus });

        var newStatus = $weekStatuses[$weekStatuses.findIndex((x) => x.id === currStatus) + 1].id;
        console.log({ newStatus });

        confirmChangeWeekId = weekId;
        confirmChangeWeekNewStatus = newStatus;
        console.log({ confirmChangeWeekId });
        console.log({ confirmChangeWeekNewStatus });

        switch (currStatus) {
            case "created":
                confirmChangeWeekTitle = $_("open_week");
                confirmChangeWeekText = "do_you_want_open_week";
                confirmChangeWeekValues = {
                    values: { year: year, week: week },
                };
                confirmChangeWeekNotifyUsers = true;
                confirmChangeWeekNotifyFlagVisible = true;
                confirmChangeWeekOpen = true;
                break;
            case "open":
                confirmChangeWeekTitle = $_("start_buying");
                confirmChangeWeekText = "do_you_want_start_buying";
                confirmChangeWeekValues = {
                    values: { year: year, week: week },
                };
                confirmChangeWeekNotifyUsers = true;
                confirmChangeWeekNotifyFlagVisible = true;
                confirmChangeWeekOpen = true;
                break;
            case "forecasted":
                try {
                    const res = await apiGetForecast(
                        null,
                        year,
                        week,
                        null /*dcSearch.map(x => {return {id: x.id}.id}).join(',').toUpperCase()*/,
                        null /*warehouseId*/,
                        null /*productId*/,
                        null /*productDetailId*/,
                        null /*productDesc*/,
                        null /*tpnb*/,
                        null /*description*/,
                        null /*ean*/,
                        null /*buyer*/,
                        null /*technicalManager*/,
                        null /*note*/,
                        null /*status*/,
                        null /*reasonCode*/,
                        null /*fileName*/,
                        "N" /*purchaseConfirmed*/,
                    );
                    if (res.length > 0) {
                        let buyersCount = 0;
                        try {
                            // Extract all buyer UUIDs
                            const allBuyerUuids = res.flatMap((item) => item?.buyerDetails?.map((buyer) => buyer?.uuid) || []);

                            const uniqueBuyers = {};

                            allBuyerUuids.forEach((buyer) => {
                                if (buyer) uniqueBuyers[buyer] = true;
                            });

                            buyersCount = Object.keys(uniqueBuyers).length;
                            console.log("Unique Buyers Count:", buyersCount);
                        } catch (error) {
                            console.error("Error while processing buyerDetails:", error);
                        }

                        // Use the computed buyers count instead of creating the array
                        $snackbarError.text = $_("cannot_close_buying_because_uncompleted_purchases", { values: { buyers: buyersCount } });
                        $snackbarError.element && $snackbarError.element.open();
                    } else {
                        confirmChangeWeekTitle = $_("stop_buing");
                        confirmChangeWeekText = "do_you_want_to_stop_buying_for_week";
                        confirmChangeWeekValues = {
                            values: { year: year, week: week },
                        };
                        confirmChangeWeekNotifyUsers = true;
                        confirmChangeWeekNotifyFlagVisible = true;
                        confirmChangeWeekOpen = true;
                    }
                } catch (error) {
                    console.log("changeWeekStatus().error");
                    console.log(error);
                    if (
                        !handleApiError(
                            $_("week_status_update_failed", {
                                values: { reason: error.message },
                            }),
                            error,
                            "non-fatal",
                        )
                    )
                        throw error;
                }
                break;
            case "purchased":
                confirmChangeWeekTitle = $_("close_week");
                confirmChangeWeekText = "do_you_want_to_close_week";
                confirmChangeWeekValues = {
                    values: { year: year, week: week },
                };
                confirmChangeWeekNotifyUsers = true;
                confirmChangeWeekNotifyFlagVisible = false;
                confirmChangeWeekOpen = true;
                break;
            default:
                break;
        }

        return newStatus;
    }

    async function updateWeekStatus(weekId, newWeekStatus, notifyUsersFlag) {
        console.log("================= updateWeekStatus ==============");
        console.log({ weekId });
        console.log({ newWeekStatus });
        apiUpdateWeekStatus({ id: weekId, status: newWeekStatus })
            .then((res) => {
                // console.log({ res });
                if (enableNotifications && notifyUsersFlag && $notificationMap.has(newWeekStatus)) {
                    apiCreateNotificationJob(Math.trunc(weekId / 100), weekId % 100, $notificationMap.get(newWeekStatus), null, null, null, null, null, null, null);
                }
                $snackbarSuccess.text = $_("week_status_updated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
                let nb = $dashboardList.findIndex((w) => w.id == weekId);
                $dashboardList[nb].status = newWeekStatus;
                let nw = $weeks.findIndex((w) => w.id == weekId);
                $weeks[nw].status = newWeekStatus;

                console.log({ $dashboardList });
                console.log({ $weeks });
                //canUpdate = false;
            })
            .catch((error) => {
                //console.log(error);
                if (
                    !handleApiError(
                        $_("week_status_update_failed", {
                            values: { reason: error.message },
                        }),
                        error,
                        "non-fatal",
                    )
                )
                    throw error;
            });
    }

    async function changeDeadline(w) {
        //console.log("================= changeDeadline ==============");
        //console.log({ w });
        confirmChangeDeadlineWeekId = w.id;
        confirmChangeDeadlineQsDeadlineDate = w.qsDeadlineDate;
        confirmChangeDeadlineQsDeadlineNotifDate = w.qsDeadlineNotifDate;
        confirmChangeDeadlineOpenWeekNotifSent = (w?.openWeekNotifSent != null)? (new Date(w?.openWeekNotifSent)).toAppDateTimeLocalFormat(): null;
        confirmChangeDeadlineQsDeadlineNotifSent = (w?.qsDeadlineNotifSent != null)? (new Date(w?.qsDeadlineNotifSent)).toAppDateTimeLocalFormat(): null;
        confirmChangeDeadlineForecastedNotifSent = (w?.forecastedNotifSent != null)? (new Date(w?.forecastedNotifSent)).toAppDateTimeLocalFormat(): null;
        confirmChangeDeadlinePurchasedNotifSent = (w?.purchasedNotifSent != null)? (new Date(w?.purchasedNotifSent)).toAppDateTimeLocalFormat(): null;
        confirmChangeDeadlineAmendmentNotifTime = (w?.amendmentNotifTime != null)? w.amendmentNotifTime: null;
        confirmChangeDeadlineMinDate = (new Date(w.weekStart)).addDays(-Number($appParameters.max_qs_deadline_offset_bwd.value)).toWSDateTime();
        confirmChangeDeadlineMaxDate = (new Date(w.weekStart)).addDays(-Number($appParameters.max_qs_deadline_offset_fwd.value)).toWSDateTime();
        confirmChangeDeadlineDataValid = false;
        oldConfirmChangeDeadlineQsDeadlineDate = confirmChangeDeadlineQsDeadlineDate;
        oldConfirmChangeDeadlineQsDeadlineNotifDate = confirmChangeDeadlineQsDeadlineNotifDate;
        //console.log({ confirmChangeDeadlineQsDeadlineDate }, { confirmChangeDeadlineQsDeadlineNotifDate }, { confirmChangeDeadlineOpenWeekNotifSent }, { confirmChangeDeadlineQsDeadlineNotifSent }, { confirmChangeDeadlineForecastedNotifSent }, { confirmChangeDeadlinePurchasedNotifSent }, {confirmChangeDeadlineMinDate}, {confirmChangeDeadlineMaxDate});
        confirmChangeDeadlineOpen = true;
    }

    async function updateDeadline(weekId, qsDeadlineDate, qsDeadlineNotifDate, oldQsDeadlineDate, oldQsDeadlineNotifDate, amendmentNotifTime, oldAmendmentNotifTime) {
        //console.log("================= updateDeadline ==============");
        //console.log({ weekId });
        //console.log({ qsDeadlineDate });
        //console.log({ oldQsDeadlineDate });
        //console.log({ qsDeadlineNotifDate });
        //console.log({ oldQsDeadlineNotifDate });
        if ( qsDeadlineDate != oldQsDeadlineDate || qsDeadlineNotifDate != oldQsDeadlineNotifDate || amendmentNotifTime != oldAmendmentNotifTime) {
            qsDeadlineDate = (new Date(qsDeadlineDate)).toWSDateTime().replace('T',' ');
            qsDeadlineNotifDate = (new Date(qsDeadlineNotifDate)).toWSDateTime().replace('T',' ');
            //console.log({ qsDeadlineDate });
            //console.log({ qsDeadlineNotifDate });
            apiUpdateWeekDeadlineNotification({ id: weekId, qsDeadlineDate: qsDeadlineDate, qsDeadlineNotifDate: qsDeadlineNotifDate, amendmentNotifTime: amendmentNotifTime })
                .then((res) => {
                    // console.log({ res });
                    $snackbarSuccess.text = $_("week_qs_deadline_updated");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                    let nb = $dashboardList.findIndex((w) => w.id == weekId);
                    $dashboardList[nb].qsDeadlineDate = qsDeadlineDate;
                    $dashboardList[nb].qsDeadlineNotifDate = qsDeadlineNotifDate;
                    $dashboardList[nb].amendmentNotifTime = amendmentNotifTime;

                    console.log({ $dashboardList });
                    console.log({ $weeks });
                    //canUpdate = false;
                })
                .catch((error) => {
                    //console.log(error);
                    if (
                        !handleApiError(
                            $_("week_deadline_update_failed", {
                                values: { reason: error.message },
                            }),
                            error,
                            "non-fatal",
                        )
                    )
                        throw error;
                });
        }
    }

    function validateQsDeadlineDate() {
        //console.log("================= validateQsDeadlineDate ==============");
        //console.log({ confirmChangeDeadlineQsDeadlineDate });

        if (confirmChangeDeadlineQsDeadlineDate != null) {
            if (new Date(confirmChangeDeadlineQsDeadlineDate).setHours(0, 0, 0, 0) >= new Date().addDays(1).setHours(0, 0, 0, 0)) {
                confirmChangeDeadlineQsDeadlineNotifDate = new Date(confirmChangeDeadlineQsDeadlineDate).addHours(-3).toWSDateTime();
                confirmChangeDeadlineDataValid = true;
            } else {
                $snackbarWarning.text = $_("you_must_set_qs_deadline_one_day_in_future");
                $snackbarWarning.element && $snackbarWarning.element.open();
                confirmChangeDeadlineDataValid = false;
                confirmChangeDeadlineQsDeadlineDate = oldConfirmChangeDeadlineQsDeadlineDate;
            }
        } else {
            confirmChangeDeadlineDataValid = false;
            confirmChangeDeadlineQsDeadlineDate = oldConfirmChangeDeadlineQsDeadlineDate;
        }
    }

    function validateQsDeadlineNotifDate() {
        //console.log("================= validateQsDeadlineNotifDate ==============");
        //console.log({ confirmChangeDeadlineQsDeadlineNotifDate });
        if (confirmChangeDeadlineQsDeadlineDate != null) {
            if (new Date(confirmChangeDeadlineQsDeadlineDate) > new Date(confirmChangeDeadlineQsDeadlineNotifDate)) {
                confirmChangeDeadlineDataValid = true;
            } else {
                $snackbarWarning.text = $_("qs_deadline_notification_must_be_before_deadline");
                $snackbarWarning.element && $snackbarWarning.element.open();
                confirmChangeDeadlineDataValid = false;
                confirmChangeDeadlineQsDeadlineNotifDate = oldConfirmChangeDeadlineQsDeadlineNotifDate;
            }
        } else {
            confirmChangeDeadlineDataValid = false;
            confirmChangeDeadlineQsDeadlineNotifDate = oldConfirmChangeDeadlineQsDeadlineNotifDate;
        }
    }

    
    function validateAmendmentNotifTime () {
        console.log("================= validateQsDeadlineNotifDate ==============");
        console.log({ confirmChangeDeadlineAmendmentNotifTime });
        if (confirmChangeDeadlineAmendmentNotifTime != null) {
            confirmChangeDeadlineDataValid = true;
        } else {
            $snackbarWarning.text = $_("amendment_notification_time_must_be_entered");
            $snackbarWarning.element && $snackbarWarning.element.open();
            confirmChangeDeadlineDataValid = false;
        }
    }

</script>

<section id="dashboard">
    <div class="list {$authenticatedUser.isSupplier ? ' slim ' : ''} {!pageEmpty ? 'mt-4' : ''}">
        {#if pageEmpty}
            <TescoAppLogo position="center" size="big" />
        {:else if $authenticatedUser.role && $dashboardList}
            {@const modules = $roles.filter((r) => r.id == $authenticatedUser.role).at(0)?.modules}
            {#each $dashboardList as week}
                <Paper variant="outlined" class="pa-0 ma-0 " data-qa={"dashboard-board-" + week.id}>
                    <Title class="pa-0 ma-0 tescoblue white-text sticky-week-header">
                        <Flex direction="row" align="center" justify="between" class="gap-05 ml-3 mr-3">
                            <Flex direction="row" align="center" justify="start" class="gap-05">
                                <div title={$_("week")} class="pl-2 pt-1 pb-1 mt-1 mb-1 pr-4">
                                    {week.description}
                                    <span class="fs-1rem">{format(new Date(week.weekStart), "dd.MM.")} - {format(new Date(week.weekEnd), "dd.MM.")} </span>
                                </div>
                                <Set
                                    data-qa={"dashboard-board-status-" + week.id + "-" + week.status}
                                    chips={[$_(week.status)]}
                                    let:chip
                                    nonInteractive
                                    class="square-chip"
                                    title={$_("status")}
                                >
                                    <Chip {chip}>
                                        <Text>{chip}</Text>
                                    </Chip>
                                </Set>
                                {#if $authenticatedUser.isAdmin}
                                    <Button
                                        title={$_("deadline_and_notifications")}
                                        data-qa={"dashboard-board-deadline-notifications-btn-" + week.id}
                                        class="tescoblue-text white-tescoblue-outlined pl-4 pr-4"
                                        on:click={() => {
                                            changeDeadline(week);
                                        }}>{$_("deadline_and_notifications")}</Button
                                    >
                                {/if}
                            </Flex>
                            {#if week.status != "closed" && $authenticatedUser.isAdmin}
                                <Button
                                    title={$_("change_week_status")}
                                    data-qa={"dashboard-board-week-status-btn-" + week.id + "-" + week.status}
                                    class="tescoblue-text white-tescoblue-outlined pl-4 pr-4"
                                    on:click={() => {
                                        changeWeekStatus(week.id, week.tescoYear, week.tescoWeek, week.status);
                                    }}>{$_([...$weekStatuses].filter((w) => w.id == week.status)?.at(0)?.actionLabel)}</Button
                                >
                            {/if}
                        </Flex>
                    </Title>
                    <Content class="ma-0 pa-0">
                        {#if !$authenticatedUser.isSupplier}
                            <Flex direction="row" align="stretch" justify="around" class="gap-1" style="height=100%">
                                {#if modules.includes("qts")}
                                    <QSSummaryCard {week} {apiCallProcessing} on:editQSWeek on:uploadQSData />
                                    {#if modules.includes("fc") || modules.includes("bs")}
                                        <div class="divider" />
                                    {/if}
                                {/if}
                                {#if modules.includes("fc") || modules.includes("bs")}
                                    <FCSummaryCard bind:week {apiCallProcessing} on:editFCWeek on:uploadFCData on:buyingFCWeek on:editForecastsDC on:buyingDC />
                                {/if}
                            </Flex>
                        {:else}
                            <Flex direction="row" align="stretch" justify="around" class="gap-1" style="height=100%">
                                {#if modules.includes("qts")}
                                    <QSSummarySupplierCard {week} {apiCallProcessing} on:editQSWeek on:uploadQSData />
                                    {#if modules.includes("ams")}
                                        <div class="divider" />
                                    {/if}
                                {/if}
                                {#if modules.includes("ams") || modules.includes("bs")}
                                    <AmSummaryCard {week} {apiCallProcessing} on:editASWeek on:uploadASData />
                                {/if}
                            </Flex>
                        {/if}
                    </Content>
                </Paper>
                <br />
            {/each}
        {/if}
    </div>
</section>

<FullscreenLoader open={apiCallDeadlineProcessing} />

<ConfirmationDialog
    open={confirmChangeWeekOpen}
    title={confirmChangeWeekTitle}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={false}
    itemData={null}
    headerColors="tescoblue white-text"
    on:reject={() => {
        confirmChangeWeekOpen = false;
    }}
    on:confirm={() => {
        confirmChangeWeekOpen = false;
        updateWeekStatus(confirmChangeWeekId, confirmChangeWeekNewStatus, confirmChangeWeekNotifyUsers);
    }}
>
    <Flex direction="column">
        <div class="tescoblue-text fs-1p2rem">
            {$_(confirmChangeWeekText, confirmChangeWeekValues)}
        </div>
        {#if enableNotifications && confirmChangeWeekNotifyFlagVisible}
            <FormField style="align-self: start;">
                <Checkbox bind:checked={confirmChangeWeekNotifyUsers} />
                <span slot="label">{$_("notify_users")}</span>
            </FormField>
        {/if}
    </Flex>
</ConfirmationDialog>

<ConfirmationDialog
    open={confirmChangeDeadlineOpen}
    title={$_("deadline_and_notifications")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("save")}
    big={true}
    headerColors="tescoblue white-text"
    dataValid={confirmChangeDeadlineDataValid}
    on:reject={() => {
        confirmChangeDeadlineOpen = false;
    }}
    on:confirm={() => {
        confirmChangeDeadlineOpen = false;
        updateDeadline(confirmChangeDeadlineWeekId, confirmChangeDeadlineQsDeadlineDate, confirmChangeDeadlineQsDeadlineNotifDate, oldConfirmChangeDeadlineQsDeadlineDate, oldConfirmChangeDeadlineQsDeadlineNotifDate, confirmChangeDeadlineAmendmentNotifTime, oldConfirmChangeDeadlineAmendmentNotifTime);
    }}
>
    <Flex direction="column">
        <DataTable class="w-120 no-border input-form pa-2">
            <Body>
                <Row>
                    <Cell class="form-label">{$_("qs_deadline")}</Cell>
                    {#if confirmChangeDeadlineQsDeadlineDate != null && new Date(confirmChangeDeadlineQsDeadlineDate).setHours(0, 0, 0, 0) >= new Date()
                                .addDays(1)
                                .setHours(0, 0, 0, 0)}
                        <Cell class="" style="border-bottom-style: none">
                            <Textfield
                                variant="standard"
                                type="datetime-local"
                                title={$_("qs_deadline")}
                                bind:value={confirmChangeDeadlineQsDeadlineDate}
                                input$min={confirmChangeDeadlineMinDate}
                                input$max={confirmChangeDeadlineMaxDate}
                                max={confirmChangeDeadlineMaxDate}
                                validateOnValueChange
                                useNativeValidation
                                updateInvalid
                                input$emptyValueUndefined
                                class="white small border"
                                style="border-style: solid;"
                                on:change={validateQsDeadlineDate}
                            ></Textfield>
                        </Cell>
                    {:else}
                        <Cell class="" style="border-bottom-style: none">{new Date(confirmChangeDeadlineQsDeadlineDate).toAppDateTimeLocalFormat()}</Cell>
                    {/if}
                </Row>

                <Row>
                    <Cell class="form-label">{$_("qs_deadline_notification")}</Cell>
                    {#if confirmChangeDeadlineQsDeadlineNotifSent == null}
                        <Cell class="" style="border-bottom-style: none">
                            <Textfield
                                variant="standard"
                                type="datetime-local"
                                title={$_("qs_deadline_notification")}
                                bind:value={confirmChangeDeadlineQsDeadlineNotifDate}
                                input$min={confirmChangeDeadlineMinDate}
                                input$max={confirmChangeDeadlineMaxDate}
                                max={confirmChangeDeadlineMaxDate}
                                validateOnValueChange
                                useNativeValidation
                                updateInvalid
                                input$emptyValueUndefined
                                class="white small"
                                on:change={validateQsDeadlineNotifDate}
                            ></Textfield>        
                        </Cell>
                    {:else}
                        <Cell class="" style="border-bottom-style: none">{(new Date(confirmChangeDeadlineQsDeadlineNotifDate)).toAppDateTimeLocalFormat()}</Cell>
                    {/if}
                </Row>

                <Row>
                    <Cell class="form-label">{$_("amendment_notif_time")}</Cell>
                    <Cell class="" style="border-bottom-style: none">
                        <Textfield
                            variant="standard"
                            type="time"
                            title={$_("amendment_notif_time")}
                            bind:value={confirmChangeDeadlineAmendmentNotifTime}
                            validateOnValueChange
                            useNativeValidation
                            updateInvalid
                            input$emptyValueUndefined
                            class="white small"
                            on:change={validateAmendmentNotifTime}
                        ></Textfield>        
                    </Cell>
                </Row>
                <!--
            </Body>
        </DataTable>
        <DataTable class="w-120 no-border input-form pa-2">
            <Body>
-->
                <Row>
                    <Cell class="form-label">{$_("week_open_notif_sent")}</Cell>
                    <Cell class="" style="border-bottom-style: none"
                        >{confirmChangeDeadlineOpenWeekNotifSent == null ? $_(notSentString) : confirmChangeDeadlineOpenWeekNotifSent}</Cell
                    >
                </Row>
                <Row>
                    <Cell class="form-label">{$_("qs_deadline_notif_sent")}</Cell>
                    <Cell class="" style="border-bottom-style: none"
                        >{confirmChangeDeadlineQsDeadlineNotifSent == null ? $_(notSentString) : confirmChangeDeadlineQsDeadlineNotifSent}</Cell
                    >
                </Row>
                <Row>
                    <Cell class="form-label">{$_("forecasted_notif_sent")}</Cell>
                    <Cell class="" style="border-bottom-style: none"
                        >{confirmChangeDeadlineForecastedNotifSent == null ? $_(notSentString) : confirmChangeDeadlineForecastedNotifSent}</Cell
                    >
                </Row>
                <Row>
                    <Cell class="form-label">{$_("purchased_notif_sent")}</Cell>
                    <Cell class="" style="border-bottom-style: none"
                        >{confirmChangeDeadlinePurchasedNotifSent == null ? $_(notSentString) : confirmChangeDeadlinePurchasedNotifSent}</Cell
                    >
                </Row>
            </Body>
        </DataTable>
    </Flex>
</ConfirmationDialog>

<style>
    section#dashboard {
        height: 100%;
        overflow-x: clip;
        overflow-y: auto;
    }
</style>
