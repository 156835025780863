<script>
    import Paper, { Title, Subtitle, Content } from "@smui/paper";
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiRefresh } from "@mdi/js";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import { apiError, apiErrorDetails, appInitFailedDetails, authenticatedUser, unauthorized } from "../stores/AppStatus";
</script>

<section>
    <Paper class="pt-0 pl-0 pr-0 mnw-400" elevation="5">
        <Content class="w-100">
            <Flex direction="column" class="w-100 gap-2">
                {#if $apiError}
                    <Title class="w-100 pt-2 pb-2 tescored white-text text-center top-rounded">{$_("api_error")}</Title>
                    <Content>
                        <Subtitle class="tescored-text">{$_("connection_lost")}</Subtitle>
                        <p>
                            {$apiErrorDetails.err}
                        </p>
                    </Content>
                {:else}
                    <Title class="w-100 pt-2 pb-2 tescored white-text text-center top-rounded">{$_("application_error")}</Title>
                    <Content>
                        <Subtitle class="tescored-text">{$_("app_init_failed")}</Subtitle>
                        <ul>
                            {#each $appInitFailedDetails as detail}
                                <li>{detail}</li>
                            {/each}
                        </ul>
                    </Content>
                {/if}
                <Button
                    variant="raised"
                    class="tescoblue white-text"
                    on:click={() => {
                        $authenticatedUser = null;
                        $unauthorized = true;

                        window.location.href = window.location.origin;
                    }}
                    title={$_("refresh")}
                >
                    <Icon tag="svg" viewBox="0 0 24 24">
                        <path fill="currentColor" d={mdiRefresh} />
                    </Icon>
                    {$_("refresh")}
                </Button>
            </Flex>
        </Content>
    </Paper>
</section>

<style>
    section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>
