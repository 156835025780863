<script>
    import { getDeliveryScheduleHist as apiGetDeliveryScheduleHist, getDeliveryScheduleSuppliers as apiGetDeliveryScheduleSuppliers } from "../../../api/DeliverySchedule";
    import { deliveryScheduleExcel as apiDeliveryScheduleExcel } from "../../../api/Report";
    import { createNotificationJob as apiCreateNotificationJob } from "../../../api/Notification";

    import { snackbarSuccess, authenticatedUser, selectedWeek } from "../../../stores/AppStatus";
    import { buyersList } from "../../../stores/Buyers";

    import { handleApiError } from "../lib/errorHandler";

    import FullscreenLoader from "../../elementary/FullscreenLoader.svelte";
    import WeekInfo from "../common/WeekInfo.svelte";
    import DSShowReportButton from "./DSShowReportButton.svelte";
    import DSDownloadExcelButton from "./DSDownloadExcelButton.svelte";
    import DSSendButton from "./DSSendButton.svelte";
    import DSSendPopup from "./DSSendPopup.svelte";

    import DSDCSelector from "./DSDCSelector.svelte";
    import DSSupplierSelector from "./DSSupplierSelector.svelte";
    import BuyerSearchAutocomplete from "../common/BuyerSearchAutocomplete.svelte";

    import DSDataTable from "./DSDataTable.svelte";

    import Paper from "@smui/paper";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    // Options
    let DCSelection;
    let SuppSelection;
    let BuyerSelection;

    // Filters
    let countrySearchArray = [];
    let dcSearch = "";
    let tpnSearch = "";
    let suppSearch = "";
    let productDescEnSearch = "";
    let deliveryDateSearch = "";

    // Data
    let items = [];

    let loadingData = false;
    let dataLoaded = false;

    // Send DS popup
    let DSPoppupOpen = false;
    let DSSuppliers = [];
    let DSSuppliersFetching = false;

    // Loading...
    let apiCallProcessing = false;
    let apiCallProcessingMessage = "";

    const getReportData = async () => {
        console.log("getReportData()");
        console.log({ DCSelection });
        console.log({ SuppSelection });
        console.log({ BuyerSelection });
        loadingData = true;
        dataLoaded = false;

        try {
            let ret = await apiGetDeliveryScheduleHist(
                $selectedWeek.tesco_year,
                $selectedWeek.tesco_week,
                SuppSelection,
                DCSelection != "" ? DCSelection : null,
                BuyerSelection != "" ? BuyerSelection : null,
            );
            console.log({ ret });

            if (ret && ret.length > 0) {
                let rowId = 1;
                ret.forEach((r) => {
                    r.id = rowId++;
                    r.totalVolSngl =
                        (r.d1VolSngl || 0) +
                        (r.d2VolSngl || 0) +
                        (r.d3VolSngl || 0) +
                        (r.d4VolSngl || 0) +
                        (r.d5VolSngl || 0) +
                        (r.d6VolSngl || 0) +
                        (r.d7VolSngl || 0) +
                        (r.flexVolSngl || 0);
                    r.totalVolCart =
                        (r.d1VolCart || 0) +
                        (r.d2VolCart || 0) +
                        (r.d3VolCart || 0) +
                        (r.d4VolCart || 0) +
                        (r.d5VolCart || 0) +
                        (r.d6VolCart || 0) +
                        (r.d7VolCart || 0) +
                        (r.flexVolCart || 0);
                    r.totalVolPal =
                        (r.d1VolPal || 0) +
                        (r.d2VolPal || 0) +
                        (r.d3VolPal || 0) +
                        (r.d4VolPal || 0) +
                        (r.d5VolPal || 0) +
                        (r.d6VolPal || 0) +
                        (r.d7VolPal || 0) +
                        (r.flexVolPal || 0);
                    // Update buyer field: convert UUID to "lastName firstName"
                    if (r.buyer) {
                        const buyerObj = $buyersList.find((b) => b.uuid === r.buyer);
                        if (buyerObj) {
                            r.buyer = `${buyerObj.lastName} ${buyerObj.firstName}`;
                        }
                    }
                });
                items = [...ret];
            } else {
                console.log("getReportData() - no data");
                items = [];
            }
            loadingData = false;
            dataLoaded = true;
        } catch (error) {
            console.log("getReportData().error");
            console.log({ error });
            items = [];
            loadingData = false;
            if (!handleApiError($_("get_data_failed"), error, "non-fatal")) throw error;
        }
    };

    const weekDataInvalidated = () => {
        items = [];
        dataLoaded = false;
    };

    const weekStatusChanged = () => {};

    $: {
        console.log({ DCSelection });
    }

    async function exportDeliverySchedule() {
        console.log("================= exportDeliverySchedule ==============");

        let fileName = "Delivery_Schedule_w" + $selectedWeek.tesco_year + $selectedWeek.tesco_week;
        let reportName = $_("delivery_schedule");
        let exportData = {
            fileName: fileName,
            reportName: reportName,
            year: $selectedWeek.tesco_year,
            week: $selectedWeek.tesco_week,
            supplierId: SuppSelection,
            showChanges: 1,
            warehouseCode: DCSelection != "" ? DCSelection : null,
            buyer: BuyerSelection != "" ? BuyerSelection : null,
        };
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("processing_wait");
        apiDeliveryScheduleExcel(exportData)
            .then((res) => {
                apiCallProcessing = false;
                $snackbarSuccess.text = $_("delivery_schedule_succeeded");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                apiCallProcessing = false;
                console.log(error);
                if (!handleApiError($_("export_delivery_schedule_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            });
    }
    $: console.log("check: ", items.length, $authenticatedUser.isAdmin, $authenticatedUser.isBuyer);

    async function getDSSupplierList() {
        console.log("================= getDSSupplierList ==============");
        DSSuppliersFetching = true;
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("checking_purchases_wait");
        DSSuppliers = [];
        await apiGetDeliveryScheduleSuppliers($selectedWeek.tesco_year, $selectedWeek.tesco_week, null, null)
            .then((res) => {
                res.forEach((r) => {
                    if (r.notConfirmed > 0) {
                        r.status = "not_confirmed_purchases_found";
                        r.disabled = false;
                        r.send = false;
                        r.order = 4;
                    } else if (r.lastPurchaseHistIdNotSent > 0) {
                        r.status = "notification_already_scheduled";
                        r.disabled = false;
                        r.send = false;
                        r.order = 2;
                    } else if (r.lastPurchaseHistId > r.lastPurchaseHistIdSent) {
                        r.status = "new_purchases_found";
                        r.disabled = false;
                        r.send = true;
                        r.order = 1;
                    } else if (r.lastPurchaseHistId == r.lastPurchaseHistIdSent) {
                        r.status = "no_new_purchases_found";
                        r.disabled = false;
                        r.send = false;
                        r.order = 3;
                    }
                });
                res.sort((a, b) => (a.order + a.supplierName).localeCompare(b.order + b.supplierName));
                DSSuppliers = [...res];
                console.log({ DSSuppliers });
                apiCallProcessing = false;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                DSSuppliers = [];
                DSSuppliersFetching = false;
                apiCallProcessing = false;
                if (!handleApiError($_("load_list_suppliers_for_ds_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            });
    }

    async function sendDSClicked() {
        console.log("================= sendDSClicked ==============");
        await getDSSupplierList();
        DSSuppliersFetching = false;
        DSPoppupOpen = true;
    }

    async function sendDeliverySchedule(event) {
        console.log("================= sendDeliverySchedule ==============");
        console.log({ event });
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("sending_ds_wait");
        let promises = [];
        event.detail.forEach((s) => {
            promises.push(apiCreateNotificationJob($selectedWeek.tesco_year, $selectedWeek.tesco_week, "DS", s.supplierId, null, null, null, null, s.lastPurchaseHistId));
        });
        Promise.all(promises)
            .then((responses) => {
                [...responses].forEach((r) => {
                    console.log({ r });
                });
                apiCallProcessing = false;
                $snackbarSuccess.text = $_("delivery_schedule_was_sent");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                console.error("Error Creating Send DS Jobs:", error);
                DSSuppliers = [];
                DSSuppliersFetching = false;
                apiCallProcessing = false;
                if (!handleApiError($_("creating_jobs_for_sending_ds_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            });
    }
</script>

<section class="qs main-data-view">
    <section class="header tescoblue-border-bottom-thin">
        <Flex direction="row" class="grey lighten-3 tescoblue-text bs-bb pr-4 gap-1" align="start">
            <Flex direction="column" class="mb-2" align="start" style="max-width: fit-content;">
                <WeekInfo on:weekChanged={weekDataInvalidated} on:weekStatusChanged={weekStatusChanged} />
                <Flex direction="row" class="gap-05 w-100 pl-2 pr-2" style="">
                    <DSDCSelector bind:selection={DCSelection} />
                    <DSSupplierSelector bind:selection={SuppSelection} />
                    <BuyerSearchAutocomplete bind:uuidOutput={BuyerSelection} label="buyer" class="white tescoblue-text w-100 small" />
                </Flex>
            </Flex>
            <Flex class="pt-2 pl-4" align="top">
                <DSShowReportButton on:get={getReportData} />
            </Flex>
            <Flex direction="row" justify="end" class="w-100 gap-05 pt-2">
                <DSDownloadExcelButton disabled={items.length == 0 || (!$authenticatedUser.isAdmin && !$authenticatedUser.isBuyer)} on:download={exportDeliverySchedule} />
                {#if $authenticatedUser.isAdmin || $authenticatedUser.isBuyer}
                    <DSSendButton disabled={items.length == 0} on:sendDSClicked={sendDSClicked} />
                {/if}
            </Flex>
        </Flex>
    </section>

    <section class="data">
        <Paper class="grey lighten-3 w-100 bs-bb pt-3">
            <div class="smui-paper__content bs-bb">
                <section class="mt-2 pt-2 w-100">
                    <!--            <DSFilterBar
                    bind:countrySearchArray
                    bind:dcSearch
                    bind:tpnSearch
                    bind:suppSearch
                    bind:productDescEnSearch
                    bind:deliveryDateSearch
                    bind:statusSearchArray
                    bind:uplStatusSearchArray
                />-->
                </section>

                <DSDataTable
                    {items}
                    {loadingData}
                    {dataLoaded}
                    countrySearchArray={countrySearchArray.map((x) => x.id.toUpperCase())}
                    {dcSearch}
                    {tpnSearch}
                    {suppSearch}
                    {productDescEnSearch}
                    {deliveryDateSearch}
                />
                <!--        <QSDataTable/>-->
            </div>
        </Paper>
    </section>
</section>

{#key DSSuppliers}
    <DSSendPopup
        open={DSPoppupOpen}
        title={$_("send_delivery_schdule")}
        big={true}
        headerColors="tescoblue white-text"
        items={DSSuppliers}
        suppliersFetching={DSSuppliersFetching}
        on:cancel={() => {
            DSPoppupOpen = false;
        }}
        on:sendDS={sendDeliverySchedule}
    ></DSSendPopup>
{/key}

<FullscreenLoader open={apiCallProcessing} text={apiCallProcessingMessage} />

<style>
    section.header {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        width: 100%;
        z-index: 1;
        align-items: stretch;
    }
</style>
