<script>
    import { _ } from "svelte-i18n";
    import Flex from "svelte-flex";
    import { Row, Cell } from "@smui/data-table";
    import IconButton, { Icon } from "@smui/icon-button";
    import { mdiDelete, mdiArrowULeftTop } from "@mdi/js";

    import { formatVolume, isNumber } from "../lib/Functions";
    import DTInputCell from "../common/DTInputCell.svelte";
    import PsPurchasePopupPurchaseRowCell from "./PsPurchasePopupPurchaseRowCell.svelte";

    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();

    export let row;
    export let conversionRatio = 1;

    let rowState;

    const computeTotal = () => {
        let oldTotal = row.totalVol;
        row.totalVol =
            Number(row.d1Vol) + Number(row.d2Vol) + Number(row.d3Vol) + Number(row.d4Vol) + Number(row.d5Vol) + Number(row.d6Vol) + Number(row.d7Vol) + Number(row.flexVol);

        if (!isNumber(row.totalVol)) {
            if (row.rowState != "invalid") {
                rowState = row.rowState;
            }
            row.rowState = "invalid";
        } else if (!["add", "invalid"].includes(row.rowState) && row.totalVol != oldTotal) {
            row.rowState = "updated";
            rowState = "updated";
        } else {
            row.rowState = rowState ? rowState : row.rowState;
        }
    };

    const promoFlagChange = () => {
        rowState = row.rowState;
        if (row.rowState != "invalid") {
            row.rowState = row.rowState != "add" ? "updated" : row.rowState;
        }
    };
</script>

{#if row}
    <Row class="small noborder background-unset">
        <Cell class="datatable-header font-weight-bold">
            <Flex direction="row" justify="between">
                <div class={row.deleted ? "row-deleted" : ""}>
                    {row.supplierName} <span class="cyan-text text-accent-2">({row.countryOfOrigin})</span>
                    {#if row.variety}
                        <br /><span class="font-weight-regular cyan-text text-accent-2"><small>{$_("variety")}: {row.variety}</small></span>
                    {/if}
                </div>
            </Flex>
        </Cell>
        <Cell class="pa-0 tescoblue-border-right-thin noborder">
            <Flex direction="row" class="">
                {#if !row.deleted}
                    <div title={$_("delete")}>
                        <IconButton
                            size="button"
                            class="opacity-1"
                            on:click={() => {
                                row.deleted = true;
                                dispatch("purchaseTableVolumeChange", { supplierId: row.supplierId });
                            }}
                        >
                            <Icon tag="svg" viewBox="0 0 24 24" class="tescoblue-text">
                                <path fill="currentColor" d={mdiDelete} />
                            </Icon>
                        </IconButton>
                    </div>
                {:else}
                    <div title={$_("undo")}>
                        <IconButton
                            size="button"
                            class="opacity-1"
                            on:click={() => {
                                row.deleted = false;
                                if (row.status == "rejected") row.status = "prepared";
                                if (row.rowState == "origin") row.rowState = "updated";
                                dispatch("purchaseTableVolumeChange", { supplierId: row.supplierId });
                            }}
                        >
                            <Icon tag="svg" viewBox="0 0 24 24" class="tescoblue-text">
                                <path fill="currentColor" d={mdiArrowULeftTop} />
                            </Icon>
                        </IconButton>
                    </div>
                {/if}

                <DTInputCell
                    bind:value={row.participation}
                    editable={!row.deleted}
                    class="small pa-0 text-overflow-unset {row.deleted ? 'row-deleted' : ''}"
                    on:change={(e) => {
                        dispatch("participationChanged", {
                            supplierId: row.supplierId,
                            warehouseId: row.warehouseId,
                            participation: row.participation ? Number(row.participation) : 0,
                        });
                    }}
                    withTrailingIcon={true}
                >
                    <svelte:fragment slot="trailingIcon">
                        <Icon class="material-icons {row.deleted ? 'grey-text ' : 'tescoblue-text'}" style="font-size: 12px;">percent</Icon>
                    </svelte:fragment>
                </DTInputCell>
            </Flex>
        </Cell>

        <!--
        <Cell class="text-center right-splitter-sub">
            <span>{row.cartonLogistics}</span>
            /
            <span>{row.palletLogistics}</span>
        </Cell>
        -->

        <!-- For each day MON to SUN -->
        {#each [1, 2, 3, 4, 5, 6, 7] as day}
            <Cell class="pa-0 tescoblue-border-right-thin noborder">
                <PsPurchasePopupPurchaseRowCell
                    bind:value={row[`d${day}Vol`]}
                    bind:promoFlag={row[`d${day}Promo`]}
                    disabled={row.deleted}
                    {conversionRatio}
                    price={row.price}
                    currency={row.currency}
                    priceEur={row.priceEur}
                    pricePromo={row.pricePromo}
                    pricePromoEur={row.pricePromoEur}
                    on:change={() => {
                        computeTotal();
                        dispatch("purchaseTableVolumeChange", { supplierId: row.supplierId });
                    }}
                    on:promoFlagChange={() => {
                        dispatch("promoFlagChange");
                        promoFlagChange();
                    }}
                />
            </Cell>
        {/each}

        <Cell class="pa-0 tescoblue-border-right-thin noborder">
            <Row class="small noborder background-unset">
                <Cell class="pa-0 noborder" style="border-bottom: 0px">
                    <DTInputCell
                        bind:value={row.flexVol}
                        disabled={row.deleted}
                        editable={!row.deleted}
                        class="small {row.deleted ? 'row-deleted' : ''}"
                        on:change={() => {
                            computeTotal();
                            dispatch("purchaseTableVolumeChange");
                        }}
                    />
                </Cell>
            </Row>
            {#if conversionRatio != 1}
                <Row style="height: 1rem; background-color: inherit">
                    <Cell numeric colspan="2" class="noborder {row.deleted ? 'row-deleted' : ''}">{formatVolume(row.flexVol * conversionRatio)}</Cell>
                </Row>
            {/if}
        </Cell>

        <Cell numeric class="pa-0 noborder">
            <div class="mdc-data-table__row small">
                <Cell numeric class="text-bold  {row.deleted ? 'row-deleted grey-text' : ''}" style="border-bottom: 0px; float: right; padding-top: .3rem;">
                    {row.totalVol}
                </Cell>
            </div>
            {#if conversionRatio != 1}
                <div class="mdc-data-table__row small" style="height: 1rem; background-color: inherit; float: right;">
                    <Cell numeric colspan="2" class={row.deleted ? "row-deleted" : ""}>{formatVolume(row.totalVol * conversionRatio)}</Cell>
                </div>
            {/if}
        </Cell>
    </Row>
{/if}
