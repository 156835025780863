<script>
    import { _ } from "svelte-i18n";
    import Flex from "svelte-flex";
    import Textfield from "@smui/textfield";
    import Button, { Label } from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiMagnify, mdiBackspaceOutline } from "@mdi/js";

    import { amendmentDetailStatuses, amendmentDetailApplyStatuses, dcList } from "../../../stores/AppConfig";

    import MultiselectFilterBar from "../common/MultiselectFilterBar.svelte";
    import MultiSelectErrorsFilterBarAccord from "../common/MultiSelectErrorsFilterBarAccord.svelte";
    import CountryFilter from "../common/CountryFilterBar.svelte";

    export let countrySearchArray = [];
    export let selectedDC = [];
    export let ceTpnSearch = "";
    export let suppSearch = "";
    export let ceDescSearch = "";
    export let deliveryDateSearch = "";
    export let statusSearchArray = [];
    export let applyStatusSearchArray = [];
    export let selectedWeek;
    export let screenMode = "";
    export let statusErrors;
    export let statusSearchErrorsArray = [];

    let dcArray = $dcList.map((x) => {
        return { id: x.code, label: x.code + " - " + x.name };
    });

    let showErrorFilter = false;

    $: if (screenMode === "upload" && $statusErrors.length > 0) {
        showErrorFilter = true;
    } else {
        statusSearchErrorsArray = [];
        showErrorFilter = false;
    }

    // Date range acording selected week
    function readableDate(date) {
        return date.toISOString().split("T")[0];
    }
    $: minDate = selectedWeek.week_start ? readableDate(selectedWeek.week_start) : undefined;
    $: maxDate = selectedWeek.week_end ? readableDate(selectedWeek.week_end) : undefined;

    //let countriesArray = $countries.map((x) => x.id.toUpperCase());
    let suppStatusesArray = $amendmentDetailStatuses.map((x) => {
        return { id: x.id, label: $_(x.name) };
    });
    let applyStatusesArray = $amendmentDetailApplyStatuses.map((x) => {
        return { id: x.id, label: $_(x.name) };
    });
</script>

<Flex direction="row" justify="start" align="center" class="w-100 gray lighten-4 h-100 gap-05">
    <Flex direction="row" justify="start" align="center" class="flex-wrap gap-05">
        <CountryFilter bind:selection={countrySearchArray} title={$_("country")} size="10" multiselect />

        <MultiselectFilterBar title={$_("dc")} valuesArray={dcArray} bind:selection={selectedDC} size="15" sortValues={false} />

        <Textfield
            variant="outlined"
            bind:value={ceTpnSearch}
            maxlength="50"
            placeholder=""
            title={$_("tpn_en")}
            class="white w-100 small flex-1"
            input$class="mnw-100"
            size="40"
            label={$_("tpn_en")}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                <path fill="currentColor" d={mdiMagnify} />
            </Icon>
        </Textfield>

        <Textfield
            variant="outlined"
            bind:value={suppSearch}
            maxlength="50"
            placeholder=""
            title={$_("supplier")}
            class="white w-100 small flex-1"
            input$class="mnw-100"
            size="40"
            label={$_("supplier")}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                <path fill="currentColor" d={mdiMagnify} />
            </Icon>
        </Textfield>

        <Textfield
            variant="outlined"
            bind:value={ceDescSearch}
            maxlength="50"
            placeholder=""
            title={$_("product_en")}
            class="white w-100 small flex-1"
            input$class="mnw-100"
            size="40"
            label={$_("product_en")}
        >
            <Icon tag="svg" viewBox="0 0 24 24" class="gray-text text-field-leading-icon" slot="leadingIcon">
                <path fill="currentColor" d={mdiMagnify} />
            </Icon>
        </Textfield>

        <Textfield
            variant="outlined"
            type="date"
            title={$_("delivery_date")}
            bind:value={deliveryDateSearch}
            input$min={minDate}
            input$max={maxDate}
            max={maxDate}
            validateOnValueChange
            useNativeValidation
            updateInvalid
            input$emptyValueUndefined
            input$autocomplete="delivery_date"
            prefix="{$_('delivery_date')}: "
            class="white small"
        ></Textfield>

        {#if screenMode === "upload"}
            {#if showErrorFilter}
                <MultiSelectErrorsFilterBarAccord title={$_("errors")} valuesArray={$statusErrors} bind:selection={statusSearchErrorsArray} size={30} sortValues={true} />
            {/if}
        {:else}
            <MultiselectFilterBar title={$_("status")} valuesArray={suppStatusesArray} bind:selection={statusSearchArray} size="15" sortValues={false} />

            <MultiselectFilterBar title={$_("applyStatus")} valuesArray={applyStatusesArray} bind:selection={applyStatusSearchArray} size="15" sortValues={false} />
        {/if}
    </Flex>

    <Button
        title={$_("clear")}
        class="tescoblue white-text"
        on:click={() => {
            countrySearchArray.length = 0;
            selectedDC.length = 0;
            ceTpnSearch = "";
            suppSearch = "";
            ceDescSearch = "";
            deliveryDateSearch = "";
            statusSearchArray.length = 0;
            applyStatusSearchArray.length = 0;
            statusSearchErrorsArray.length = 0;
        }}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="pa-0 ma-0">
            <path fill="currentColor" d={mdiBackspaceOutline} />
        </Icon>
    </Button>
</Flex>
