<script>
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import Flex from "svelte-flex";
    import Dialog, { Title, Header, Content } from "@smui/dialog";
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import Checkbox from "@smui/checkbox";
    import FormField from "@smui/form-field";
    import Textfield from "@smui/textfield";
    import { Label } from "@smui/common";
    import CircularProgress from "@smui/circular-progress";

    import { countryList } from "../../../stores/AppConfig";
    import ChipInput from "../common/ChipInput.svelte";
    import DTInputCell from "../common/DTInputCell.svelte";
    import { isNumber } from "../lib/Functions";

    import { validatePrice, validatePriceIsNotEmpty } from "../Upload/ValidateQuoteSheet";
    import { getFieldErrMsg, existsError, clearError, updateErrors } from "../Upload/Upload.js";

    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();

    export let open = false;
    export let insideDialog = false;
    export let item = {};
    export let product = {};

    let working = false;
    let dataInvalid = false;

    let countryLower = "";
    let countryUpper = "";
    let countryMix = "";

    let cartonOrigValue;
    let palletOrigValue;

    let cartonFieldError;
    let palletFieldError;
    let hasValidated = false;
    let hasPriceError = false;
    let hasPromoPriceError = false;

    let applyChangeToProduct = false;
    let applyChangeToOthers = false;
    let applyChangeToOthersDisabledFlag = false;

    //CoO Chips
    let countryChips = [];
    onMount(() => {
        if (item.countryOfOrigin) {
            const updatedChips = item.countryOfOrigin.split(",").map((code) => {
                const match = countryOptions.find((option) => option.code === code.trim());
                return match ? { code: match.code, name: match.name, invalid: false } : { code: code.trim(), name: "Invalid", invalid: true };
            });

            // Sort invalid chips to the top
            updatedChips.sort((a, b) => b.invalid - a.invalid);

            // Force reactivity
            countryChips = [...updatedChips];
        }
    });

    // Search CoO name and code
    function searchCountries(input) {
        if (!input) return [];
        const lowerInput = input.toLowerCase();
        return countryOptions.filter(
            (option) =>
                (option.name.toLowerCase().includes(lowerInput) || option.code.toLowerCase().includes(lowerInput)) && !countryChips.some((chip) => chip.code === option.code),
        );
    }

    let countryOptions = [];
    $: if ($countryList.length > 0) {
        // Country variables options and text
        countryOptions = [...$countryList]
            .filter((e) => e.active)
            .map((x) => ({
                code: x.code,
                name: x.description,
                text: `${x.code} - ${x.description}`,
            }));

        // Recompute countryChips if `item.countryOfOrigin` exists
        if (item.countryOfOrigin) {
            countryChips = item.countryOfOrigin.split(",").map((code) => {
                const match = countryOptions.find((option) => option.code === code.trim());
                return match ? { code: match.code, name: match.name, invalid: false } : { code: code.trim(), name: "Invalid", invalid: true };
            });

            countryChips.sort((a, b) => b.invalid - a.invalid);
        }
    }

    // Update countryOfOrigin when chips changes
    function handleChipChange(event) {
        console.log("handleChipChange", event);

        const { type, detail } = event; // Extract type and detail

        if (type === "add") {
            // Ensure chip is added to countryChips only once
            if (!countryChips.some((chip) => chip.code === detail.code)) {
                countryChips = [...countryChips, detail];
            }
        } else if (type === "remove") {
            // Remove the chip from countryChips
            countryChips = countryChips.filter((chip) => chip.code !== detail.code);
        }

        // Update countryOfOrigin using countryChips
        item.countryOfOrigin = countryChips
            .map((chip) => chip.code)
            .sort()
            .join(", ");
        console.log("Updated countryOfOrigin", item.countryOfOrigin);
    }

    const dialogConfirmed = () => {
        console.log("confirmed", { item });
        dispatch("confirmed", { ...item, applyChangeToProduct: applyChangeToProduct, applyChangeToOthers: applyChangeToOthers });
        working = true;
    };

    function validateField(val, conditions) {
        if (!conditions || !Array.isArray(conditions)) return "";

        //console.log("validateField()", val, conditions.join(","));

        let ret = "";
        conditions.forEach((c) => {
            if (!ret) {
                switch (c) {
                    case "nonEmpty":
                        //console.log("nonEmpty");
                        if (typeof val === "undefined" || !val) ret = "field_is_required";
                        break;
                    case "number":
                        //console.log("number");
                        if (val && !Number(val)) ret = "value_must_be_number";
                        break;
                    case "integer":
                        //console.log("integer");
                        if (val && !Number.isInteger(Number(val))) ret = "value_must_be_integer";
                        break;
                    case "gtZero":
                        //console.log("gtZero");
                        if (val && val <= 0) ret = "value_cant_be_negative";
                        if (val && val == 0) ret = "value_cant_be_zero";
                        break;
                }
            }
        });

        //console.log("END", ret);
        return ret;
    }

    function validateInputPrice() {
        if (!hasValidated) {
            hasValidated = true; // delay as on:change and on:input do not trigger in the same time

            // Replace commas with periods in price fields
            if (item["price" + countryMix] && typeof item["price" + countryMix] === "string") {
                item["price" + countryMix] = item["price" + countryMix].replace(/,/g, ".");
            }

            if (item["pricePromo" + countryMix] && typeof item["pricePromo" + countryMix] === "string") {
                item["pricePromo" + countryMix] = item["pricePromo" + countryMix].replace(/,/g, ".");
            }

            console.log("=== validateInputPrice() ===", { item });
            clearError(item, "price");
            let errors = [];
            let fields = [];
            // Fill product with all present currencies from item
            let product = {};
            Object.keys(item).forEach((key) => {
                if (key.match("currency" + countryMix)) {
                    product[key] = item[key];
                }
            });
            errors.push(validatePriceIsNotEmpty(item, product));
            fields.push("price");

            clearError(item, "price" + countryMix);
            clearError(item, "pricePromo" + countryMix);

            errors.push(validatePrice(item, product, countryMix.toUpperCase(), "REGULAR"));
            fields.push("price" + countryMix);

            errors.push(validatePrice(item, product, countryMix.toUpperCase(), "PROMO"));
            fields.push("pricePromo" + countryMix);

            // Update errors in the item
            if (errors.length > 0) {
                item = updateErrors(item, item.errors || [], item.warnings || [], fields, errors);
            }

            hasPriceError = existsError(item, "price", "E");
            if (!hasPriceError) {
                hasPriceError = existsError(item, "price" + countryMix, "E");
            }
            hasPromoPriceError = existsError(item, "pricePromo" + countryMix, "E");

            setTimeout(() => {
                hasValidated = false;
            }, 100);
        }
    }

    $: if (item && item.country && countryLower != item.country.toLowerCase()) {
        countryLower = item.country?.toLowerCase();
        countryUpper = item.country?.toUpperCase();
        countryMix = countryUpper[0] + countryLower[1];
    }

    $: if (item && !cartonOrigValue) {
        cartonOrigValue = item.cartonLogistics;
        palletOrigValue = item.palletLogistics;
    }

    $: {
        cartonFieldError = item ? validateField(item.cartonLogistics, ["nonEmpty", "number", "integer", "gtZero"]) : "";
        if (cartonFieldError == "") {
            item.cartonLogistics = Number(item.cartonLogistics);
        }
    }
    $: {
        palletFieldError = item ? validateField(item.palletLogistics, ["nonEmpty", "number", "integer", "gtZero"]) : "";
        if (palletFieldError == "") {
            item.palletLogistics = Number(item.palletLogistics);
        }
    }

    $: dataInvalid = cartonFieldError || palletFieldError || hasPriceError || hasPromoPriceError;

    $: cartonFieldWarning = item.cartonLogistics != product.cartonLogistics || (item.lwCartonLogistics && item.cartonLogistics != item.lwCartonLogistics);
    $: palletFieldWarning = item.palletLogistics != product.palletLogistics || (item.lwPalletLogistics && item.palletLogistics != item.lwPalletLogistics);

    $: applyChangeToOthersDisabledFlag = cartonOrigValue != item.cartonLogistics || palletOrigValue != item.palletLogistics;
    $: applyChangeToOthers = applyChangeToOthersDisabledFlag && cartonOrigValue ? true : applyChangeToOthers;

    /*     $: {
        console.log("---- ----");
        console.log({ open });
        console.log({ item });
        console.log({ countryMix });
        console.log("price" + countryMix, item["price" + countryMix]);
        console.log("pricePromo" + countryMix, item["pricePromo" + countryMix]);
        console.log("cartonLogistics", item.cartonLogistics, item.lwCartonLogistics, product.cartonLogistics);
        console.log("palletLogistics", item.palletLogistics, item.lwPalletLogistics, product.palletLogistics);
        console.log({ cartonOrigValue }, { palletOrigValue });
        console.log({ dataInvalid });
    } */
</script>

<Dialog bind:open slot={insideDialog ? "over" : ""} class="pa-2" surface$style="min-height:300px; min-width: 850px;">
    <Header>
        <Title data-qa="purchase-qs-edit-confirmation-title" class="tescoblue white-text">
            {$_("qs_update")}
        </Title>
    </Header>
    <Content data-qa="purchase-qs-edit-confirmation-content" class="pb-0" style="display:flex;">
        <DataTable class="w-100 input-form no-border pa-2">
            <Body>
                <Row class="small">
                    <Cell class="no-border"></Cell>
                    <Cell class="no-border tescoblue-text font-weight-bold text-center">{$_("original")}</Cell>
                    <Cell class="no-border tescoblue-text font-weight-bold text-center" colspan="4">{$_("current")}</Cell>
                </Row>
                <Row>
                    <Cell class="form-label">{$_("variety")}</Cell>
                    <DTInputCell colspan="1" value={item.origVariety} editable={false} numeric={true} style="width: 7rem;" class="fs-1rem tescoblue-text grey lighten-4" />
                    <td colspan="3">
                        <Textfield
                            variant="outlined"
                            bind:value={item.variety}
                            updateInvalide={false}
                            input$maxlength={100}
                            input$emptyValueUndefined
                            input$class="tescoblue-text fs-1rem"
                            class="w-100 tescoblue-text edit-cell-border"
                        ></Textfield>
                    </td>
                </Row>
                <Row>
                    <Cell class="form-label">{$_("country_of_origin")}</Cell>
                    <DTInputCell colspan="1" value={item.origCountryOfOrigin} editable={false} numeric={true} style="width: 7rem;" class="fs-1rem tescoblue-text grey lighten-4" />
                    <td colspan="3">
                        <ChipInput
                            class="w-90 tescoblue-text edit-cell-border pr-1"
                            autocomplete$class="pa-2"
                            bind:chips={countryChips}
                            value
                            key={(country) => country.code}
                            getChipLabel={(country) => `${country.name} (${country.code})`}
                            getChipText={(country) => country.name}
                            autocomplete$search={searchCountries}
                            autocomplete$getOptionLabel={(country) => `${country.name} (${country.code})`}
                            input$disabled={countryChips.length >= 3}
                            chipTrailingAction$class="material-icons"
                            chipTrailingAction$aria-label="Remove country"
                            style="min-width: 450px;"
                            on:add={handleChipChange}
                            on:remove={handleChipChange}
                        >
                            <svelte:fragment slot="chipTrailingAction">cancel</svelte:fragment>
                            <svelte:fragment slot="label">{countryChips.length >= 3 ? $_("invalid_count_of_coo_codes") : $_("search")}</svelte:fragment>
                            <CircularProgress slot="loading" style="height: 24px; width: 24px;" indeterminate />
                        </ChipInput>
                    </td>
                </Row>
                <Row>
                    <Cell class="form-label">{$_("carton_logistics")}</Cell>
                    <DTInputCell colspan="1" value={item.cartonLogistics} editable={false} numeric={true} style="width: 7rem;" class="fs-1rem tescoblue-text grey lighten-4" />
                    <td colspan="1">
                        <Textfield
                            variant="outlined"
                            bind:value={item.purchaseCartonLogistics}
                            withTrailingIcon={cartonFieldError}
                            invalid={cartonFieldError}
                            updateInvalide={false}
                            input$maxlength={8}
                            input$autocomplete="cartonLogistics"
                            input$emptyValueUndefined
                            input$class="tescoblue-text fs-1rem"
                            required
                            class="w-100 tescoblue-text edit-cell-border pr-1"
                        >
                            <svelte:fragment slot="trailingIcon">
                                {#if cartonFieldError}
                                    <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                {/if}
                                {#if cartonFieldWarning}
                                    <Icon class="material-icons orange-text mdc-text-field__icon mdc-text-field__icon--trailing">warning</Icon>
                                {/if}
                            </svelte:fragment>
                        </Textfield>
                    </td>
                    <Cell class="no-border tescoblue-text pr-0 pl-0" style="width: 1rem;">
                        <DataTable class="report no-border tescoblue-text w-100">
                            <Body>
                                <Row>
                                    <Cell class="tescoblue-text white font-weight-bold text-right pr-1">{$_("product")}:</Cell>
                                </Row>
                                <Row>
                                    <Cell class="tescoblue-text white font-weight-bold text-right pr-1" title={$_("last_week")}>{$_("lw")}:</Cell>
                                </Row>
                            </Body>
                        </DataTable>
                    </Cell>
                    <Cell class="no-border tescoblue-text  pr-0 pl-0">
                        <DataTable class="report no-border tescoblue-text">
                            <Body>
                                <Row>
                                    <Cell class="tescoblue-text {item.cartonLogistics != product.cartonLogistics ? 'yellow' : 'white'} text-left pl-1 pr-1"
                                        >{product.cartonLogistics}</Cell
                                    >
                                </Row>
                                <Row>
                                    <Cell class="tescoblue-text {item.lwCartonLogistics && item.cartonLogistics != item.lwCartonLogistics ? 'yellow' : 'white'} text-left pl-1"
                                        >{item.lwCartonLogistics ? item.lwCartonLogistics : ""}</Cell
                                    >
                                </Row>
                            </Body>
                        </DataTable>
                    </Cell>
                </Row>
                {#if cartonFieldError || cartonFieldWarning}
                    <Row class="helpertext__row">
                        <Cell></Cell>
                        <Cell></Cell>
                        <Cell colspan="5" style="padding: 0">
                            {#if cartonFieldError}
                                <div class="red-text fs-08rem">
                                    {$_(cartonFieldError)}
                                </div>
                            {:else}
                                <div class="orange-text fs-08rem">
                                    {$_("carton_logistics_differs")}
                                </div>
                            {/if}
                        </Cell>
                    </Row>
                {/if}

                <Row>
                    <Cell class="form-label">{$_("pallet_logistics")}</Cell>
                    <DTInputCell colspan="1" value={item.palletLogistics} editable={false} numeric={true} style="width: 7rem;" class="fs-1rem tescoblue-text grey lighten-4" />
                    <td colspan="1">
                        <Textfield
                            variant="outlined"
                            bind:value={item.purchasePalletLogistics}
                            withTrailingIcon={cartonFieldError}
                            invalid={cartonFieldError}
                            updateInvalide={false}
                            input$maxlength={8}
                            input$autocomplete="palletLogistics"
                            input$emptyValueUndefined
                            input$class="tescoblue-text fs-1rem"
                            required
                            class="w-100 tescoblue-text edit-cell-border pr-1"
                        >
                            <svelte:fragment slot="trailingIcon">
                                {#if palletFieldError}
                                    <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                {/if}
                                {#if palletFieldWarning}
                                    <Icon class="material-icons orange-text mdc-text-field__icon mdc-text-field__icon--trailing">warning</Icon>
                                {/if}
                            </svelte:fragment>
                        </Textfield>
                    </td>
                    <Cell class="no-border tescoblue-text pr-0 pl-0" style="width: 1rem;">
                        <DataTable class="report no-border tescoblue-text w-100">
                            <Body>
                                <Row>
                                    <Cell class="tescoblue-text white font-weight-bold text-right pr-1">{$_("product")}:</Cell>
                                </Row>
                                <Row>
                                    <Cell class="tescoblue-text white font-weight-bold text-right pr-1" title={$_("last_week")}>{$_("lw")}:</Cell>
                                </Row>
                            </Body>
                        </DataTable>
                    </Cell>
                    <Cell class="no-border tescoblue-text  pr-0 pl-0">
                        <DataTable class="report no-border tescoblue-text">
                            <Body>
                                <Row>
                                    <Cell class="tescoblue-text {item.palletLogistics != product.palletLogistics ? 'yellow' : 'white'} text-left pl-1 pr-1"
                                        >{product.palletLogistics}</Cell
                                    >
                                </Row>
                                <Row>
                                    <Cell class="tescoblue-text {item.lwPalletLogistics && item.palletLogistics != item.lwPalletLogistics ? 'yellow' : 'white'} text-left pl-1"
                                        >{item.lwPalletLogistics ? item.lwPalletLogistics : ""}</Cell
                                    >
                                </Row>
                            </Body>
                        </DataTable>
                    </Cell>
                </Row>
                {#if palletFieldError || palletFieldWarning}
                    <Row class="helpertext__row">
                        <Cell></Cell>
                        <Cell></Cell>
                        <Cell colspan="5" style="padding: 0">
                            {#if palletFieldError}
                                <div class="red-text fs-08rem">
                                    {$_(palletFieldError)}
                                </div>
                            {:else}
                                <div class="orange-text fs-08rem">
                                    {$_("pallet_logistics_differs")}
                                </div>
                            {/if}
                        </Cell>
                    </Row>
                {/if}
                <Row>
                    <Cell class="form-label">{countryUpper + " " + $_("price")}</Cell>
                    <DTInputCell
                        colspan="1"
                        value={item["origPrice" + countryMix]}
                        editable={false}
                        numeric={true}
                        style="width: 7rem;"
                        class="fs-1rem tescoblue-text grey lighten-4"
                    />
                    <td>
                        <Textfield
                            variant="outlined"
                            bind:value={item["price" + countryMix]}
                            withTrailingIcon={hasPriceError}
                            invalid={hasPriceError}
                            updateInvalide={false}
                            input$maxlength={8}
                            input$autocomplete="price"
                            input$emptyValueUndefined
                            input$class="tescoblue-text fs-1rem"
                            required
                            class="w-100 tescoblue-text edit-cell-border pr-1"
                            on:input={() => {
                                validateInputPrice();
                            }}
                            on:change={() => {
                                validateInputPrice();
                            }}
                        >
                            <svelte:fragment slot="trailingIcon">
                                {#if hasPriceError}
                                    <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                {/if}
                            </svelte:fragment>
                        </Textfield>
                    </td>
                    <DTInputCell
                        colspan="1"
                        value={item["currency" + countryMix]}
                        editable={false}
                        numeric={false}
                        style="width: 7rem;"
                        class="fs-1rem tescoblue-text grey lighten-4 text-center"
                    />
                    <Cell class="no-border"></Cell>
                </Row>
                {#if hasPriceError}
                    <Row class="helpertext__row">
                        <Cell></Cell>
                        <Cell colspan={4} style="padding: 0">
                            <div class="red-text fs-08rem">
                                {$_(getFieldErrMsg(item, "price", "E")) || $_(getFieldErrMsg(item, "price" + countryMix, "E"))}
                            </div>
                        </Cell>
                    </Row>
                {/if}
                <Row>
                    <Cell class="form-label">{countryUpper + " " + $_("promo_price")}</Cell>
                    <DTInputCell
                        colspan="1"
                        value={item["origPricePromo" + countryMix]}
                        editable={false}
                        numeric={true}
                        style="width: 7rem;"
                        class="fs-1rem tescoblue-text grey lighten-4"
                    />
                    <td>
                        <Textfield
                            variant="outlined"
                            bind:value={item["pricePromo" + countryMix]}
                            withTrailingIcon={hasPromoPriceError}
                            invalid={hasPromoPriceError}
                            updateInvalide={false}
                            input$maxlength={8}
                            input$autocomplete="pricePromo"
                            input$emptyValueUndefined
                            input$class="tescoblue-text fs-1rem"
                            class="w-100 tescoblue-text edit-cell-border pr-1"
                            on:input={() => {
                                validateInputPrice();
                            }}
                            on:change={() => {
                                validateInputPrice();
                            }}
                        >
                            <svelte:fragment slot="trailingIcon">
                                {#if hasPromoPriceError}
                                    <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                {/if}
                            </svelte:fragment>
                        </Textfield>
                    </td>
                    <DTInputCell
                        colspan="1"
                        value={item["currency" + countryMix]}
                        editable={false}
                        numeric={false}
                        style="width: 7rem;"
                        class="fs-1rem tescoblue-text grey lighten-4 text-center"
                    />
                    <Cell class="no-border"></Cell>
                </Row>
                {#if hasPromoPriceError}
                    <Row class="helpertext__row">
                        <Cell></Cell>
                        <Cell colspan={4} style="padding: 0">
                            <div class="red-text fs-08rem">
                                {$_(getFieldErrMsg(item, "pricePromo" + countryMix, "E"))}
                            </div>
                        </Cell>
                    </Row>
                {/if}
            </Body>
        </DataTable>
    </Content>
    <div class="mdc-dialog__actions gap-1 pr-4 mr-4 pb-4">
        <Flex direction="row" align="between" class="w-100 gap-2" justify="end">
            <Flex direction="column" align="start" style="--mdc-checkbox-touch-target-size: 24px;">
                <Flex direction="row">
                    <FormField>
                        <Checkbox bind:checked={applyChangeToProduct} />
                        <span slot="label" class="tescoblue-text">{$_("apply_to_product")}</span>
                    </FormField>
                </Flex>
                <Flex direction="row">
                    <FormField>
                        <Checkbox bind:checked={applyChangeToOthers} disabled={applyChangeToOthersDisabledFlag} />
                        <span slot="label" class={applyChangeToOthersDisabledFlag ? "grey-text" : "tescoblue-text"}>{$_("apply_to_all_country_purch")}</span>
                    </FormField>
                </Flex>
            </Flex>
            <Flex direction="row" class="gap-1">
                <Button
                    action="close"
                    disabled={working}
                    class="{!working ? 'tescored tescored-text' : 'grey grey-text'} outlined"
                    on:click={() => {
                        open = false;
                        dispatch("cancelled");
                    }}
                >
                    <Label>{$_("cancel")}</Label>
                </Button>
                <Button
                    action="save"
                    default
                    defaultAction
                    disabled={working || dataInvalid}
                    class="{!(working || dataInvalid) ? 'tescoblue' : 'grey'} white-text"
                    on:click={dialogConfirmed}
                >
                    <Label>
                        <Flex dirction="row" class="gap-05 pr-1">
                            {#if working}
                                <CircularProgress class="white-text" style="height: 18px; width: 18px;" indeterminate />
                            {/if}
                            {$_("ok")}
                        </Flex>
                    </Label>
                </Button>
            </Flex>
        </Flex>
    </div>
</Dialog>
