<script>
    import Card from "@smui/card";
</script>

<section>
    <Card padded>
        <h1 class="red-text accent-4">Sorry, page not found.</h1>
    </Card>
</section>

<style>
    section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    h1 {
        padding: 10rem;
    }
</style>
