import { readable, writable } from 'svelte/store';

// API url
// Configuration moved to src/main/public/config/endPointConfig.json
// It is loaded dynamically during start now.
//
// export const endPointUrl = readable('https://de0dkr03:18051/');
// export const endPointUrl = writable('https://de0dkr03.tesco-europe.com:18051/');
// export const endPointUrl = writable('https://tpc59042bg0744.tgrc.tesco.org:8443/');
// export const endPointUrl = writable('http://tpc59042bg0744.tgrc.tesco.org:8080/');
// export const endPointUrl = writable('https://localhost:8443/');
// export const endPointUrl = readable('/');

//export const endPointUrl = readable('/'); // Localhost
export const endPointUrl = writable('');
export const toolkitEndPointUrl = writable('');
// App name
export const appName = readable('Produce Buying Tool');


// Application version
export const appVersion = readable('v1.10.0 (20250331)');

// Max tasks in batch
export const batchSize = 5;

// Maximal toolkit attempts before it fails
export const maxLoginAttempts = 3;


// Lists stores
export const productHierarchyList  =  writable([]);
export const temperatureList  =  writable([]);
export const unitList  =  writable([]);
export const currencyList  =  writable([]);
export const countryList  =  writable([]);
export const dcList  =  writable([]);
export const fullDcList  =  writable([]);
export const dashboardList  =  writable([]);
export const appParameters = writable({});

// App countries
export const countries = readable([
    {id: 'cz', name: 'czechia',  flag: "cz", orderNo: 1},
    {id: 'sk', name: 'slovakia', flag: "sk", orderNo: 2},
    {id: 'hu', name: 'hungary',  flag: "hu", orderNo: 4}
]);

export const countriesAll = readable([
    { id: 'CZ', name: 'czechia', flag: "cz" },
    { id: 'SK', name: 'slovakia', flag: "sk" },
    { id: 'HU', name: 'hungary', flag: "hu" },
    { id: 'ALL', name: 'all', flag: "eu" }
]);

export const languages = readable([
    {id: 'en', flag: "gb"},
    {id: 'cz', flag: "cz"},
    {id: 'sk', flag: "sk"},
    {id: 'hu', flag: "hu"}
]);

/* export const currency = readable([
    { id: 1, name: "czech_crown", code: "CZK", sign: "Kč", flag: "/images/flags/4x3/cz.svg" },
    { id: 3, name: "hungary_forint", code: "HUF", sign: "Ft", flag: "/images/flags/4x3/hu.svg" },
]); */

export const roless = readable([
/*     { id: 'supplier', name: 'Supplier', modules: [{ 'id': 'qts', permissions: ['view', 'edit'] }, { 'id': 'ds', permissions: ['view', 'edit'] }, { 'id': 'mg', permissions: ['view'] }] },
    { id: 'buyer', name: 'Buyer', modules: [{ 'id': 'qts', permissions: ['view', 'edit'] }, { 'id': 'bs', permissions: ['view', 'edit'] }, { 'id': 'ds', permissions: ['view', 'edit'] }, { 'id': 'rpt', permissions: ['view', 'edit'] }] },
    { id: 'sc', name: 'Supply chain', modules: [{ 'id': 'qts', permissions: ['view', 'edit'] }, { 'id': 'fc', permissions: ['view', 'edit'] }, { 'id': 'ds', permissions: ['view', 'edit'] }, { 'id': 'rpt', permissions: ['view', 'edit'] }] },
    { id: 'admin', name: 'Admin', all: true } */
]);

export const roles = writable([
/*     {id: 'supplier', name: 'Supplier', modules: ['qts', 'am'], readonly: false},
    {id: 'buyer', name: 'Buyer', modules: ['qts', 'bs', 'ds', 'rpt', 'am', 'mng'], readonly: false},
    {id: 'supplychain', name: 'Supply chain', modules: ['fc', 'ds', 'am' ], readonly: false},
    {id: 'admin', name: 'Admin', modules: ['qts', 'fc', 'bs', 'ds', 'am', 'rpt', 'mng'], readonly: false},
    {id: 'readonly', name: 'Guest', modules: ['qts', 'fc', 'ds'], readonly: true} */
]);

export const modules = writable([
/*     { id: 'qts', name: 'quote_sheets', tabs: ['dashboard', 'quote_sheets'], icon: '', labelType: 'text', insertStatuses: ['open', 'forecasted'], editStatuses: ['open', 'forecasted'], deleteStatuses: ['open', 'forecasted'] },
    { id: 'ds', name: 'delivery_schedule', tabs: ['delivery_schedule'], icon: '', labelType: 'text', insertStatuses: [], editStatuses: [], deleteStatuses: [] },
    { id: 'bs', name: 'buying', tabs: ['dashboard', 'buying'], icon: '', labelType: 'text', insertStatuses: [], editStatuses: ['forecasted', 'purchased'], deleteStatuses: [] },
    { id: 'ps', name: 'purchase', tabs: ['dashboard', 'purchase_screen'], icon: '', labelType: 'text', insertStatuses: ['forecasted', 'purchased'], editStatuses: ['forecasted'], deleteStatuses: ['forecasted'] },
    { id: 'rpt', name: 'reports', tabs: ['reports'], icon: '', labelType: 'text', insertStatuses: [], editStatuses: [], deleteStatuses: [] },
    { id: 'fc', name: 'forecasts', tabs: ['dashboard', 'forecasts'], icon: '', labelType: 'text', insertStatuses: ['open', 'forecasted'], editStatuses: ['open', 'forecasted'], deleteStatuses: ['open'] },
    { id: 'am', name: 'amendments', tabs: ['amendments'], icon: '', labelType: 'text', insertStatuses: ['purchased'], editStatuses: ['purchased'], deleteStatuses: ['purchased'] },
    { id: 'mng', name: 'manage', tabs: ['manage'], icon: 'settings', labelType: 'icon_only', insertStatuses: [], editStatuses: [], deleteStatuses: [] } */
]);

export const deliveryTypes = readable([
    {id: "normal", name: "Normal"},
    {id: "direct", name: "Direct"},
    {id: "xdock", name: "XDock"}
]);

export const forecastStatuses = readable([
    {id: 'error', name: 'error'},
    {id: 'new', name: 'new'},
    {id: 'updated', name: 'updated'},
    {id: 'rejected', name: 'rejected'},
    {id: 'purchased', name: 'purchased'},
    {id: 'purchased_less', name: 'purchased_less'},
    {id: 'purchased_more', name: 'purchased_more'},
    {id: 'finished', name: 'finished'}
]);

export const forecastNotes = readable([
    { id: 1, name: "regular" },
    { id: 2, name: "afterpromo" },
    { id: 3, name: "promo" },
]);

export const weekStatuses = readable([
    {id: 'created', name: 'created', actionLabel: 'open_week'},
    {id: 'open', name: 'open', actionLabel: 'start_buying'},
    {id: 'forecasted', name: 'forecasted', actionLabel: 'stop_buying'},
    {id: 'purchased', name: 'purchased', actionLabel: 'close_week'},
    {id: 'closed', name: 'closed', actionLabel: ''}
]);

export const amendmentStatuses = readable([
    {id: 'new', name: 'new'},
    {id: 'in_progress', name: 'in_progress'},
    {id: 'finished', name: 'finished'},
    {id: 'finished_less', name: 'finished_less'},
    {id: 'rejected', name: 'rejected'},
    {id: 'error', name: 'error'}
]);

export const amendmentDetailStatuses = readable([
    {id: 'new', name: 'new'},
    {id: 'sent', name: 'sent'},
    {id: 'not_responded', name: 'not_responded'},
    {id: 'confirmed_less', name: 'confirmed_less'},
    {id: 'confirmed_all', name: 'confirmed_all'},
    {id: 'rejected', name: 'rejected'}
]);

export const amendmentDetailApplyStatuses = readable([
    { id: 'not_applied', name: 'not_applied'},
    { id: 'applied', name: 'applied'}
]);

export const purchaseStatuses = readable([
    {id: 'prepared', name: 'prepared'},
    {id: 'confirmed', name: 'confirmed'},
    {id: 'updated', name: 'updated'},
    {id: 'rejected', name: 'rejected'}
]);

export const quoteSheetStatuses = readable([
    {id: 'valid', name: 'valid'},
    {id: 'warning', name: 'warning'},
    {id: 'error', name: 'error'}
]);

export const rejectPurchaseReasons = readable([
    {id: 'postponed_start_date', name: 'postponed_start_date', statuses: ['purchased_less', 'rejected']},
    {id: 'buy_later', name: 'buy_later', statuses: ['rejected']},
    {id: 'delisted', name: 'delisted', statuses: ['rejected']},
    {id: 'end_of_season', name: 'end_of_season', statuses: ['purchased_less', 'rejected']},
    {id: 'rounding_pallets', name: 'rounding_pallets', statuses: ['purchased_less', 'purchased_more']},
    {id: 'temporary_unavailable', name: 'temporary_unavailable', statuses: ['purchased_less']},
    {id: 'not_enough_volume', name: 'not_enough_volume', statuses: ['purchased_less']},
    {id: 'bad_quality', name: 'bad_quality', statuses: ['purchased_less']},
    {id: 'too_high_price', name: 'too_high_price', statuses: ['purchased_less']},
    {id: 'unavailable', name: 'unavailable', statuses: ['rejected']},
    {id: 'other', name: 'other', statuses: ['purchased_less', 'purchased_more', 'rejected']}
]);

// list of item category
export const itemCategory = writable([
    { id: 1, description: "Squashes"},
    { id: 2, description: "Fresh_juices"},
    { id: 3, description: "Peppers"},
    { id: 4, description: "Herbs"},
    { id: 5, description: "Tomatoes"},
    { id: 6, description: "Flowers"},
]);

// list of supp status
export const suppStatus = readable([
    {id: 1, name: "active"},
    {id: 0, name: "inactive"},
]);

// list of status
export const statusList = readable([
    {id: 1, name: "active"},
    {id: 0, name: "inactive"},
]);

// Country list
export const country = writable([]);


// Supplier's amendment deadline time interval
export const amendmentDeadlineTimeIntervals = readable([
    {id: 0, name: 'immediately'},
    {id: 5, name: '5_min'},
    {id: 10, name: '10_min'},
    {id: 15, name: '15_min'},
    {id: 20, name: '20_min'},
    {id: 25, name: '25_min'},
    {id: 30, name: '30_min'},
]);

export const enableNotifications = true;

let notifMap = new Map();
notifMap.set("open", "OPENED");
notifMap.set("forecasted", "FORECASTED");
notifMap.set("purchased", "PURCHASED");

export const notificationMap =  readable(new Map(notifMap));
