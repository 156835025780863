<script lang="ts">
    import { onMount, createEventDispatcher } from "svelte";
    import { writable } from "svelte/store";

    import {
        getQuoteSheets as apiGetQuoteSheets,
        createQuoteSheet as apiCreateQuoteSheet,
        updateQuoteSheet as apiUpdateQuoteSheet,
        deleteQuoteSheets as apiDeleteQuoteSheets,
    } from "../../api/QuoteSheet";
    import { generalExportToExcel as apiGeneralExportToExcel, downloadQuoteSheetTemplate as apiDownloadQuoteSheetTemplate } from "../../api/Report";
    import { loadSuppliers } from "./Load/Supplier";
    import { uploadFiles } from "./Upload/Upload";
    import { isActionEnabled } from "./lib/Functions";

    import { validateUploadedQSData, duplicityCheckQSData, duplicityCheck } from "./Upload/ValidateQuoteSheet";

    import { snackbarSuccess, snackbarWarning, snackbarError, selectedWeek, authenticatedUser, autoLoadData } from "../../stores/AppStatus";
    import { batchSize } from "../../stores/AppConfig";

    import { handleApiError } from "./lib/errorHandler";

    import WeekInfo from "./common/WeekInfo.svelte";
    import GetDataButton from "./common/GetDataButton.svelte";
    import AddItemButton from "./common/AddItemButton.svelte";
    import UploadButton from "./common/UploadButton.svelte";
    import ClearQSButton from "./common/ClearButton.svelte";
    import ExportToExcelButton from "./common/ExportToExcelButton.svelte";
    import QSDownloadTemplateButton from "./QuoteSheets/QSDownloadTemplateButton.svelte";
    import SaveQSButton from "./QuoteSheets/SaveQSButton.svelte";
    import QSFilterBar from "./QuoteSheets/QSFilterBar.svelte";
    import QSDataTable from "./QuoteSheets/QSDataTable.svelte";
    import Loader from "../elementary/Loader.svelte";

    import Dialog, { Title as DTitle } from "@smui/dialog";
    import Button, { Label } from "@smui/button";
    import Paper, { Content } from "@smui/paper";
    import LinearProgress from "@smui/linear-progress";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import ConfirmationDialog from "../dialogs/ConfirmationDialog.svelte";
    import FullscreenLoader from "../elementary/FullscreenLoader.svelte";

    import type { QuoteSheetsItems } from "../../types/QuoteSheets";

    // Add item dialog
    import QSItemDetail from "./QuoteSheets/QSItemDetail.svelte";

    export let showUploadDialog = false;
    export let screenMode = "edit"; // Possible values = ['edit', 'upload']

    let dataLoaded = false;
    let loadingData = false;
    let unsavedRowsExists = false;
    let rowsValid = false;

    // Search
    let tpnEnSearch = "";
    let countrySearch = [];
    let supplierSearch = "";
    let hierarchySearch = "";
    let productSearch = "";
    let statusErrors = writable([]);
    let statusSearchErrorsArray = [];
    let buyerSearchArray = [];

    let dialogOpen = false;
    let quoteSheetDialogType = "edit";

    // Item detail dialog
    let item = {};

    // Filtered items for export
    let filteredItems: QuoteSheetsItems[] = [];

    // Delete rows dialog
    let checkboxItems = [];
    let itemsToDelete;
    let itemToDelete;
    let singlDeleteConfirmationOpen = false;
    let multiDeleteConfirmationDialog = false;
    let deleteCount = 0;

    let items = [];
    let qsProducts = [];

    let filesUploadDialogOpen = false;
    let filesUploadState;
    let filesStatus = [];
    let filesUploadCount = 0;

    let apiCallProcessing = false;
    let apiCallProcessingMessage = "";

    let canInsert = false;
    let canUpdate = false;
    let canDelete = false;

    const dispatch = createEventDispatcher();

    $: rowsValid = items && Array.isArray(items) && [...items].filter((x) => x.errors && x.errors.length > 0).length == 0;
    $: uploadFinishedCount = filesStatus ? filesStatus.filter((x) => ["done", "error", "rejected"].includes(x.uploadStatus)).length : 0;
    $: validationFinishedCount = filesStatus ? filesStatus.filter((x) => ["done", "error", "rejected"].includes(x.validationStatus)).length : 0;

    $: if ($autoLoadData) {
        getQSData();
        $autoLoadData = false;
        checkboxItems.length = 0;
    }

    // create a list of errors while upload mode
    $: if (screenMode === "upload") {
        // All errors and warnings
        const allErrorsAndWarnings = items.flatMap((item) => [
            ...(item.errors ? item.errors.filter((error) => error !== null && error !== undefined) : []),
            ...(item.warnings ? item.warnings.filter((warning) => warning !== null && warning !== undefined) : []),
        ]);

        const uniqueErrors = Array.from(new Map(allErrorsAndWarnings.map((errorOrWarning) => [errorOrWarning.message, errorOrWarning])).values());

        statusErrors.set(uniqueErrors);
    } else {
        statusErrors.set([]); // Clear statusErrors if not in 'upload' mode
    }

    const handleEvents = async (event) => {
        console.log("================= handleEvents ==============");
        console.log({ event });

        switch (event.type) {
            case "quoteSheetDialogCancel":
                console.log("Event Cancel Quote Sheet");
                dialogOpen = false;
                break;
            case "quoteSheetDialogSave":
                console.log("Event Save Quote Sheet Row");
                dialogOpen = false;
                // Update Product Detail table on screen
                items.filter((x) => x.id == event.detail.item.id)[0] = event.detail.item;
                saveQuoteSheetRow(event.detail.type, screenMode, event.detail.item);
                break;
            case "editQuoteSheetRow":
                console.log("Event Edit Quote Sheet Row");
                editQuoteSheet(screenMode, event.detail);
                break;
            case "deleteQuoteSheetRow":
                console.log(`Event ${event.type} with row:`, event.detail);
                const id = event.detail;
                itemToDelete = Array.isArray(id) ? id : [id];
                deleteCount = 1;
                singlDeleteConfirmationOpen = true;
                break;
            case "deleteSelectedRows":
                console.log(`Event ${event.type} with rows:`, event.detail);
                const ids = event.detail;
                itemsToDelete = Array.isArray(ids) ? ids : [ids];
                deleteCount = itemsToDelete.length;
                multiDeleteConfirmationDialog = true;
                break;
            case "openFileDialogConfirmed":
                console.log("Event Import Quote Sheets");
                uploadData(event.detail);
                break;
            case "saveAllData":
                console.log("Event Save All Imported Quote Sheets");
                saveAllData(items, true);
                break;
            case "clearAllData":
                console.log("Event Clear All Imported Quote Sheets");
                clearAllData();
                break;
            case "downloadQuoteSheetTemplate":
                console.log("Download Quote Sheet Template", event.detail);
                downloadQuoteSheetTemplate(event.detail?.supplierId, event.detail?.importProductList);
                break;
            default:
                console.log("Unknown event: " + event.type);
        }
    };

    const getQSData = async () => {
        try {
            loadingData = true;
            unsavedRowsExists = false;
            screenMode = "edit";
            checkboxItems.length = 0;
            const res = await apiGetQuoteSheets(null, $selectedWeek.tesco_year, $selectedWeek.tesco_week);

            console.log({ res });

            items = [
                ...res.sort((a, b) =>
                    [a.productDesc, a.supplierName, a.countryOfOrigin, a.variety].join("#").toLowerCase() >
                    [b.productDesc, b.supplierName, b.countryOfOrigin, b.variety].join("#").toLowerCase()
                        ? 1
                        : -1,
                ),
            ];
            items.forEach((x) => {
                x.rowStatus = "loaded";
                x.productId = String(x.productId);
                x.productCurrencyCz = x.currencyCz;
                x.productCurrencySk = x.currencySk;
                x.productCurrencyHu = x.currencyHu;
                x.supplierStatus = true;
                x.productStatus = true;
                x.selected = false;
                x.errors = [];
                x.warnings = [];
            });

            if (items.length == 0) {
                console.log("getQSData().empty");
            } else {
                // $divisionsList = [...divsArray];
                items = items;
                console.log({ items });
            }
            dispatch("getDataRequest");
            dataLoaded = true;
            loadingData = false;
        } catch (error) {
            console.log("getQSData().error");
            console.log({ error });
            loadingData = false;
            if (!handleApiError($_("get_data_failed"), error, "non-fatal")) throw error;
        }
    };

    const saveQuoteSheetRow = (type, mode, data) => {
        console.log("================= saveQuoteSheetRow ==============");
        console.log({ type });
        console.log({ mode });
        console.log({ data });

        if (type == "add") {
            // Add New QuoteSheet
            if (mode == "edit" || mode == "saveAll") {
                apiCreateQuoteSheet([data])
                    .then((res) => {
                        console.log({ res });

                        if (mode == "edit") {
                            // While add but row exist => update, new row => add to items
                            if (data.id !== undefined) {
                                // Update existing QuoteSheet
                                console.log("Update existing QuoteSheet");
                                items.forEach((x) => {
                                    if (x.id == res[0].id) {
                                        x.variety = data.variety == null ? "" : data.variety;
                                        x.countryOfOrigin = data.countryOfOrigin == null ? "" : data.countryOfOrigin;
                                        x.cartonLogistics = data.cartonLogistics == null ? null : data.cartonLogistics;
                                        x.palletLogistics = data.palletLogistics == null ? null : data.palletLogistics;
                                        x.volume = data.volume == null ? null : data.volume;
                                        x.priceCz = data.priceCz == null ? null : data.priceCz;
                                        x.priceSk = data.priceSk == null ? null : data.priceSk;
                                        x.priceHu = data.priceHu == null ? null : data.priceHu;
                                        x.pricePromoCz = data.pricePromoCz == null ? null : data.pricePromoCz;
                                        x.pricePromoSk = data.pricePromoSk == null ? null : data.pricePromoSk;
                                        x.pricePromoHu = data.pricePromoHu == null ? null : data.pricePromoHu;
                                        x.currencyCz = data.currencyCz == null ? "" : data.currencyCz;
                                        x.currencySk = data.currencySk == null ? "" : data.currencySk;
                                        x.currencyHu = data.currencyHu == null ? "" : data.currencyHu;
                                        x.errors = [...data.errors];
                                        x.warnings = [...data.warnings];
                                    }
                                });
                            } else {
                                // Add new QuoteSheet
                                console.log("Add new QuoteSheet to the list");
                                data.id = res[0].id;
                                data.buyerDetails = data.product.buyerDetails;
                                items.push(data);
                            }

                            items = items;
                            $snackbarSuccess.text = $_("quote_sheet_created");
                            $snackbarSuccess.element && $snackbarSuccess.element.open();
                        } else {
                            // When row from XLS upload was created update XLS row id with new row id
                            items.filter((x) => x.id == data.idXLS)[0].id = res[0].id;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        if (!handleApiError($_("create_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                    });
            } else {
                items.push(data);
                items = items;
            }
        } else {
            // Update existing QuoteSheet
            items.forEach((x) => {
                if (x.id == data.id) {
                    x.id = data.id;
                    x.week = data.week;
                    x.year = data.year;
                    x.productId = data.productId;
                    x.supplierId = data.supplierId;
                    x.supplierName = data.supplierName;
                    x.hierarchyId = data.hierarchyId;
                    x.hierarchyClassDesc = data.hierarchyClassDesc;
                    x.productDesc = data.productDesc;
                    x.countryOfOrigin = data.countryOfOrigin;
                    x.variety = data.variety;
                    x.units = data.units;
                    x.cartonLogistics = data.cartonLogistics;
                    x.palletLogistics = data.palletLogistics;
                    x.volume = data.volume;
                    x.priceCz = data.priceCz;
                    x.priceSk = data.priceSk;
                    x.priceHu = data.priceHu;
                    x.pricePromoCz = data.pricePromoCz;
                    x.pricePromoSk = data.pricePromoSk;
                    x.pricePromoHu = data.pricePromoHu;
                    x.currencyCz = data.currencyCz;
                    x.currencySk = data.currencySk;
                    x.currencyHu = data.currencyHu;
                    x.fileName = data.fileName;
                    x.errors = [...data.errors];
                    x.warnings = [...data.warnings];
                }
            });
            items = items;

            if (mode == "edit" || mode == "saveAll") {
                apiUpdateQuoteSheet(data)
                    .then((res) => {
                        console.log({ res });
                        if (mode == "edit") {
                            $snackbarSuccess.text = $_("product_detail_updated");
                            $snackbarSuccess.element && $snackbarSuccess.element.open();
                            dialogOpen = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                    });
            } else {
                dialogOpen = false;
            }
        }
    };

    const editQuoteSheet = (mode, data) => {
        console.log("================= editQuoteSheet ==============");
        dialogOpen = true;
        quoteSheetDialogType = "edit";
        screenMode = mode;
        item = JSON.parse(JSON.stringify(data));
        item.errors.length = 0;
        item.warnings.length = 0;
    };

    // Delete rows
    const deleteRows = async (ids) => {
        console.log("================= deleteRows ==============");
        console.log("screenMode: ", screenMode);
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("processing_wait");

        let delFailedCount = 0;

        if (Array.isArray(ids) && ids.length > 0) {
            if (screenMode === "upload") {
                items = items.filter((item) => !ids.includes(item.id));
                checkboxItems.length = 0;
                if (items.length === 0) {
                    clearAllData();
                } else {
                    await duplicityCheck(items);
                }
                apiCallProcessing = false;
            } else {
                // Edit mode
                try {
                    const deletedResponses = await apiDeleteQuoteSheets(ids);
                    console.log("Deleted Responses:", deletedResponses);

                    // Track if any deletions failed
                    let allSuccess = true;

                    // Clear old warnings from all items
                    items.forEach((item) => {
                        item.warnings = [];
                    });

                    // Update items with success or error messages
                    items = items.map((item) => {
                        const response = deletedResponses.find((deleted) => deleted.id === item.id);
                        if (response) {
                            item.warnings.push({
                                priority: 1,
                                message: response.success ? "OK" : response.errorMessage,
                                field: "id",
                                type: "D",
                            });
                            if (!response.success) {
                                delFailedCount++;
                                allSuccess = false;
                            }
                        }
                        return item;
                    });

                    // Filter out successfully deleted items
                    items = items.filter((item) => {
                        const response = deletedResponses.find((deleted) => deleted.id === item.id);
                        return !(response && response.success);
                    });

                    checkboxItems.length = 0;

                    if (allSuccess) {
                        $snackbarSuccess.text = $_("quote_sheet_deleted");
                        $snackbarSuccess.element && $snackbarSuccess.element.open();
                    } else {
                        $snackbarWarning.text = $_("some_item_failed_to_delete", { values: { rows: delFailedCount } });
                        $snackbarWarning.element && $snackbarWarning.element.open();
                    }

                    apiCallProcessing = false;
                } catch (error) {
                    checkboxItems.length = 0;
                    apiCallProcessing = false;
                    console.error("Deletion error", error);
                    if (!handleApiError($_("delete_qs_rows"), error)) throw error;
                }
            }
        }
        multiDeleteConfirmationDialog = false;
        singlDeleteConfirmationOpen = false;
        itemsToDelete = null;
        itemToDelete = null;
    };

    // Upload files
    const uploadProgress = (fileName, status, message) => {
        if (fileName) {
            filesStatus.forEach((x) => {
                if (x.fileName == fileName) {
                    x.uploadStatus = status;
                    if (message) {
                        x.errorMessage = message;
                    }
                }
            });
        }
        console.log({ filesStatus });
        filesStatus = filesStatus;
    };

    const validationProgress = (fileName, status, message) => {
        if (fileName) {
            filesStatus.forEach((x) => {
                if (x.fileName == fileName) {
                    x.validationStatus = status;
                    if (message) {
                        x.errorMessage = message;
                    }
                }
            });
        }
        console.log({ filesStatus });
        filesStatus = filesStatus;
    };

    const allProgress = async (proms, progressCallBack) => {
        // console.log("callback");
        // console.log({proms});
        let d = 0;
        progressCallBack(0);
        for (const p of proms) {
            p.then((x) => {
                console.log({ x });
                d++;
                if (Array.isArray(x) && x.length > 0) {
                    if (x.at(0)?.productId) {
                        progressCallBack(x.at(0).fileName, "done");
                    } else {
                        progressCallBack(x.at(0).fileName, "rejected");
                    }
                } else {
                    console.log("error");
                    progressCallBack("n/a", "error");
                }
            }).catch((x) => {
                d++;
                console.log("CATCH");
                console.log({ x });
                if (Array.isArray(x) && x.length > 0) {
                    console.log("array");
                    progressCallBack(x.at(0).fileName, "error");
                } else {
                    console.log("error");
                    progressCallBack("n/a", "error");
                }
            });
        }
        console.log({ proms });
        return Promise.all(proms);
    };

    const validateFiles = async (files, validationProgressCallBack) => {
        console.log("==================================================");
        console.log("==============     validateFiles     =============");
        console.log("==================================================");
        console.log({ files });

        let responses = [];

        if (files && files != null && files.length > 0) {
            let filesArray = [...files];
            while (filesArray.length) {
                const filePromises = filesArray.splice(0, batchSize).map((f) => {
                    console.log("Map file: ", { f });
                    validationProgressCallBack(f.fileName, "processing");

                    return new Promise(async (resolve, reject) => {
                        try {
                            console.log({ f });
                            if (f.errors && f.errors.size > 0) {
                                validationProgressCallBack(f.fileName, "rejected");
                                let ret = [{ fileName: [f.fileName], errors: f.errors }];
                                console.log({ ret });
                                resolve(ret);
                            } else {
                                if (f?.items) {
                                    let qsData = [...f.items.flatMap((x) => x)];
                                    console.log([...qsData]);
                                    let ret = await validateUploadedQSData(qsData, $selectedWeek);

                                    if (ret) {
                                        console.log("Validation finished");
                                        console.log({ ret });
                                        resolve(ret);
                                    } else {
                                        console.log("Throw: file_validation_failed");
                                        validationProgressCallBack(f.fileName, "error");
                                        resolve(ret);
                                        // throw new Error('file_validation_failed');
                                    }
                                } else {
                                    console.log("Throw: file_validation_failed - no items");
                                    validationProgressCallBack(f.fileName, "error");
                                    resolve({
                                        fileId: 0,
                                        fileName: f.name,
                                        supplierId: null,
                                        currency: { Cz: null, Sk: null, Hu: null },
                                        week: null,
                                        errors: new Map(),
                                        items: [],
                                    });
                                }
                            }
                        } catch (error) {
                            console.log({ error });
                            validationProgressCallBack(f.fileName, "error");
                            reject(error);
                        }
                    });
                });

                const batchResponses = await allProgress(filePromises, validationProgressCallBack);
                responses = responses.concat(batchResponses);
            }
            console.log({ responses });
            return responses;
        }
    };

    const uploadData = async (files) => {
        console.log("================= uploadData ==============");
        checkboxItems.length = 0;
        try {
            filesUploadDialogOpen = true;
            filesUploadState = "started";
            filesStatus = [];
            items = [];
            screenMode = "upload";
            filesUploadCount = files.length;

            for (let f = 0; f < filesUploadCount; f++) {
                filesStatus.push({ fileName: files.item(f).name, uploadStatus: "waiting", validationStatus: "waiting" });
            }
            filesStatus = filesStatus;
            console.log({ filesStatus });

            const results = await uploadFiles(files, "QS", uploadProgress);
            console.log("Upload results: ", { results });
            if (results && filesUploadState == "started") {
                try {
                    const validationResults = await validateFiles(results, validationProgress);
                    console.log({ validationResults });
                    if (validationResults) {
                        for (let fileRows of validationResults) {
                            console.log({ fileRows });
                            if (fileRows && fileRows.at(0)?.productId) {
                                for (let itemRow of fileRows) {
                                    items.push(itemRow);
                                }
                            }
                        }

                        if (filesUploadState == "started") {
                            await duplicityCheckQSData(items);
                            //console.log({items});
                            items = items;
                        } else {
                            items = [];
                        }
                    }
                } catch (error) {
                    console.log("Validation Fails!", { error });
                    if (error?.cause?.response?.status == 401) {
                        throw error;
                    }
                }
            }

            if (filesUploadState == "started") {
                filesUploadState = "finished";
                console.log("Uploading XLS data finished");
                unsavedRowsExists = true;
                dataLoaded = true;
            } else {
                // cancell requested
                filesUploadDialogOpen = false;
                filesUploadState = "cancelled";
            }
        } catch (error) {
            filesUploadDialogOpen = false;
            console.log({ error });

            if (!handleApiError($_(error.message + " - " + error.cause, { values: { reason: error.message } }), error, "non-fatal")) throw error;
        }
    };

    const saveAllData = (data, batchMode) => {
        console.log("================= saveAllData ==============");
        console.log(batchMode, { data });
        let promises = [];
        checkboxItems.length = 0;

        apiCallProcessing = true;
        apiCallProcessingMessage = $_("processing_wait");

        // Update id value with qsId - as the row already exists -> row for update
        items.forEach((item) => {
            const warningWithQsId = item.warnings?.find((warning) => warning.qsId);
            if (warningWithQsId) {
                item.id = warningWithQsId.qsId;
            }
        });

        if (batchMode) {
            let preparedData = [...data].map((d) => {
                return {
                    idXLS: d.id,
                    id: String(d.id).includes("-") ? null : d.id,
                    year: d.year,
                    week: d.week,
                    supplierId: d.supplierId,
                    productId: d.productId,
                    countryOfOrigin: d.countryOfOrigin,
                    variety: d.variety,
                    units: d.units,
                    cartonLogistics: d.cartonLogistics,
                    palletLogistics: d.palletLogistics,
                    volume: d.volume,
                    priceCz: d.priceCz,
                    priceSk: d.priceSk,
                    priceHu: d.priceHu,
                    pricePromoCz: d.pricePromoCz,
                    pricePromoSk: d.pricePromoSk,
                    pricePromoHu: d.pricePromoHu,
                    currencyCz: d.productCurrencyCz,
                    currencySk: d.productCurrencySk,
                    currencyHu: d.productCurrencyHu,
                    fileName: d.fileName,
                };
            });
            console.log({ preparedData });
            promises.push(apiCreateQuoteSheet(preparedData));
        } else {
            for (let d of [...data]) {
                let r = {
                    idXLS: d.id,
                    id: String(d.id).includes("-") ? null : d.id,
                    year: d.year,
                    week: d.week,
                    supplierId: d.supplierId,
                    productId: d.productId,
                    countryOfOrigin: d.countryOfOrigin,
                    variety: d.variety,
                    units: d.units,
                    cartonLogistics: d.cartonLogistics,
                    palletLogistics: d.palletLogistics,
                    volume: d.volume,
                    priceCz: d.priceCz,
                    priceSk: d.priceSk,
                    priceHu: d.priceHu,
                    pricePromoCz: d.pricePromoCz,
                    pricePromoSk: d.pricePromoSk,
                    pricePromoHu: d.pricePromoHu,
                    currencyCz: d.productCurrencyCz,
                    currencySk: d.productCurrencySk,
                    currencyHu: d.productCurrencyHu,
                    fileName: d.fileName,
                };
                if (r.id == null) {
                    promises.push(apiCreateQuoteSheet(r));
                } else {
                    promises.push(apiUpdateQuoteSheet(r));
                }
            }
        }

        let saveErrorsCount = 0;
        Promise.all(promises)
            .then((responses) => {
                responses.forEach((response) => {
                    response.forEach((row) => {
                        if (batchMode) {
                            if (row.id) {
                                // Update item ID if created successfully
                                if (row.idXLS && row.idXLS != null) {
                                    items.filter((x) => x.id == row.idXLS)[0].id = row.id;
                                }
                            } else {
                                let err;
                                if (row.oracleError == 1) {
                                    err = {
                                        priority: 3,
                                        message: "quote_sheet_exists_already",
                                        field: "productId",
                                        type: "E",
                                    };
                                } else if (row.oracleErrorMessage) {
                                    err = {
                                        priority: 3,
                                        message: "row.oracleErrorMessage",
                                        field: "productId",
                                        type: "E",
                                    };
                                } else {
                                    err = {
                                        priority: 3,
                                        message: "failed_to_create_unknown_error",
                                        field: "productId",
                                        type: "E",
                                    };
                                }

                                if (err) {
                                    const item = items.find((x) => x.id == row.idXLS);
                                    if (item) {
                                        item.errors = item.errors || [];
                                        item.errors.push(err);
                                        saveErrorsCount++;
                                    }
                                }
                            }
                        }
                    });
                });

                if (saveErrorsCount > 0) {
                    items = items.filter((x) => x.errors.length > 0);
                    !handleApiError(
                        $_("some_quote_sheets_failed_to_save"),
                        items.map((item) => item.errors),
                        "non-fatal",
                    );
                    throw new Error($_("some_quote_sheets_failed_to_save"));
                } else {
                    unsavedRowsExists = false;
                    $snackbarSuccess.text = $_("quote_sheets_created");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                    screenMode = "edit";
                }
            })
            .catch((error) => {
                apiCallProcessing = false;
                !handleApiError($_("save_failed", { values: { reason: error.message || "Unknown error" } }), error, "fatal");
            })
            .finally(() => {
                apiCallProcessing = false;
            });
    };

    const clearAllData = () => {
        console.log("================= clearAllData ==============");
        items = [];
        checkboxItems.length = 0;
        screenMode = "edit";
        filesUploadDialogOpen = false;
        dataLoaded = false;
        unsavedRowsExists = false;
    };

    const weekDataInvalidated = () => {
        dataLoaded = false;
        items = [];
        unsavedRowsExists = false;
        updatePrivileges();
        checkboxItems.length = 0;
    };

    const updatePrivileges = () => {
        if ($selectedWeek && $selectedWeek.status) {
            canInsert = isActionEnabled("quote_sheets", "insert", $selectedWeek.status);
            canUpdate = isActionEnabled("quote_sheets", "edit", $selectedWeek.status);
            canDelete = isActionEnabled("quote_sheets", "delete", $selectedWeek.status);
        } else {
            canInsert = canUpdate = canDelete = false;
        }
    };

    const checkProductDuplicity = (productId, supplierId, countryOfOrigin, variety) => {
        console.log("==== checkProductDuplicity ====");
        console.log(productId, supplierId, countryOfOrigin, variety);
        let productsList = items.filter((x) => x.productId == productId && x.supplierId == supplierId && x.countryOfOrigin == countryOfOrigin && x.variety == variety);
        console.log({ productsList });

        if (productsList.length == 1) {
            if (
                productsList
                    .at(0)
                    .errors.map((x) => x.message)
                    .includes("duplicate_product_in_file")
            ) {
                productsList.at(0).errors.splice(
                    productsList
                        .at(0)
                        .errors.map((x) => x.field)
                        .indexOf("productId"),
                    1,
                );
                console.log({ productsList });
            }
        }
        console.log({ items });
    };

    async function exportQuoteSheet() {
        console.log("================= exportQuoteSheet ==============");
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("processing_wait");
        let data = [];
        let fileName = "Quote_Sheet_w" + $selectedWeek.tesco_year + $selectedWeek.tesco_week;
        let reportName = $_("quote_sheet");
        let headersTranslations = [
            $_("year"),
            $_("week"),
            $_("tpn_en"),
            $_("supplier"),
            $_("buyer"),
            $_("hierarchy"),
            $_("product_en"),
            $_("country_of_origin"),
            $_("variety"),
            $_("units"),
            $_("carton_logistics"),
            $_("pallet_logistics"),
            $_("volume"),
            "CZ " + $_("currency"),
            "CZ " + $_("price"),
            "CZ " + $_("promo") + " " + $_("price"),
            "SK " + $_("currency"),
            "SK " + $_("price"),
            "SK " + $_("promo") + " " + $_("price"),
            "HU " + $_("currency"),
            "HU " + $_("price"),
            "HU " + $_("promo") + " " + $_("price"),
        ];
        let columnTypes = [
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "text",
            "number",
            "number",
            "number",
            "text",
            "decimal2",
            "decimal2",
            "text",
            "decimal2",
            "decimal2",
            "text",
            "decimal2",
            "decimal2",
        ];

        filteredItems.forEach(function (row) {
            let rowData = [];
            let buyers = [...new Set([...row.buyerDetails].map((b) => b.lastName + " " + b.firstName))].join(", ");
            //row.buyeDetails.forEach(b => {buyers});
            rowData.push(Number(row.year));
            rowData.push(Number(row.week));
            rowData.push(row.productId);
            rowData.push(row.supplierName);
            rowData.push(buyers);
            rowData.push(row.hierarchyClassDesc);
            rowData.push(row.productDesc);
            rowData.push(row.countryOfOrigin);
            rowData.push(row.variety);
            rowData.push($_(row.units));
            rowData.push(Number(row.cartonLogistics));
            rowData.push(Number(row.palletLogistics));
            rowData.push(Number(row.volume));
            rowData.push(row.currencyCz);
            rowData.push(row.priceCz);
            rowData.push(row.pricePromoCz);
            rowData.push(row.currencySk);
            rowData.push(row.priceSk);
            rowData.push(row.pricePromoSk);
            rowData.push(row.currencyHu);
            rowData.push(row.priceHu);
            rowData.push(row.pricePromoHu);

            data.push(rowData);
        });

        let exportData = {
            fileName: fileName,
            reportName: reportName,
            headersTranslations: headersTranslations,
            columnTypes: columnTypes,
            data: data,
        };

        apiGeneralExportToExcel(exportData)
            .then((res) => {
                apiCallProcessing = false;
                $snackbarSuccess.text = $_("export_quote_sheet_succeeded");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                apiCallProcessing = false;
                console.log(error);
                if (!handleApiError($_("export_quote_sheet_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            });
    }

    async function downloadQuoteSheetTemplate(supplierId, importProductList) {
        console.log("================= downloadQuoteSheetTemplate ==============", supplierId, importProductList);
        let supplierName;
        if (supplierId && supplierId != null) {
            try {
                let res = await loadSuppliers(supplierId, null, 1);
                if (res && Array.isArray(res) && res.length > 0) supplierName = res[0].name;
                else {
                    $snackbarError.text = $_("no_supplier_name_check_user_mapping");
                    $snackbarError.element && $snackbarError.element.open();
                    return;
                }
            } catch (error) {
                console.log("downloadQuoteSheetTemplate().error");
                console.log({ error });
                loadingData = false;
                if (!handleApiError($_("get_data_failed"), error, "non-fatal")) throw error;
            }
        } else {
            supplierName = null;
        }
        console.log({ supplierName });
        let qsData = [...filteredItems]
            .filter((i) => i.supplierId == supplierId)
            .map((x) => {
                return {
                    productId: x.productId,
                    productName: x.productDesc,
                    countryOfOrigin: x.countryOfOrigin,
                    variety: x.variety,
                    units: x.units,
                    cartonLogistics: x.cartonLogistics,
                    palletLogistics: x.palletLogistics,
                    volume: x.volume,
                    priceCz: x.priceCz,
                    priceSk: x.priceSk,
                    priceHu: x.priceHu,
                    currencyCz: x.currencyCz,
                    currencySk: x.currencySk,
                    currencyHu: x.currencyHu,
                    pricePromoCz: x.pricePromoCz,
                    pricePromoSk: x.pricePromoSk,
                    pricePromoHu: x.pricePromoHu,
                };
            });
        let filename =
            "Quote_Sheet_w" + $selectedWeek.tesco_year + $selectedWeek.tesco_week + (supplierId == null ? "" : "_" + supplierName.replaceAll(".", "").replaceAll(" ", "_"));
        let reportName = $_("quote_sheet");
        let reportType = importProductList ? "TEMPLATE" : "EXPORT";
        let exportData = {
            filename: filename,
            reportName: reportName,
            type: reportType,
            qsData: qsData,
            year: $selectedWeek.tesco_year,
            week: $selectedWeek.tesco_week,
            supplierId: supplierId,
            supplierName: supplierName,
        };
        console.log({ exportData });
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("processing_wait");
        apiDownloadQuoteSheetTemplate(exportData)
            .then((res) => {
                apiCallProcessing = false;
                $snackbarSuccess.text = $_("quote_sheet_template_generated");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
            })
            .catch((error) => {
                apiCallProcessing = false;
                console.log(error);
                if (!handleApiError($_("quote_sheet_template_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            });
    }

    onMount(() => {
        updatePrivileges();
    });

    /*
    $: {
        console.log('-----------------')
        console.log({screenMode});
        console.log({$selectedWeek});
        console.log({canInsert});
        console.log({canUpdate});
        console.log({canDelete});

    }
*/
</script>

<!--<div class="pt-2">
    <ModuleHeader/>
</div>-->

<section class="main-data-view">
    <section class="w-100 header top-splitter">
        <Flex direction="row" class="grey lighten-3 tescoblue-text w-100 bs-bb pr-4 gap-1" justify="between">
            <WeekInfo disabled={screenMode == "upload"} on:weekChanged={weekDataInvalidated} on:weekStatusChanged={updatePrivileges} />
            <Flex direction="row" justify="between" class="gap-1 w-100">
                {#if screenMode != "upload"}
                    <GetDataButton approvalNeeded={unsavedRowsExists} approvalMessage={unsavedRowsExists ? $_("discard_unsaved_changes") : ""} on:get={getQSData} />
                {/if}
                <Flex direction="row" justify="end" class="w-100 gap-1">
                    {#if screenMode != "upload" && !$authenticatedUser.isReadOnly}
                        <AddItemButton
                            disabled={!canInsert}
                            on:addItemBtnClicked={() => {
                                console.log("on:addItemBtnClicked");
                                screenMode = "edit";
                                quoteSheetDialogType = "add";
                                dialogOpen = true;
                                item = {};
                            }}
                        />
                        <ExportToExcelButton disabled={filteredItems.length == 0} on:export={exportQuoteSheet} />
                        <QSDownloadTemplateButton
                            authenticatedUser={$authenticatedUser}
                            supplierList={[
                                ...new Set(
                                    [...filteredItems]
                                        .map((x) => {
                                            return { id: x.supplierId, name: x.supplierId + " - " + x.supplierName };
                                        })
                                        .filter((obj1, i, arr) => arr.findIndex((obj2) => JSON.stringify(obj2) === JSON.stringify(obj1)) === i),
                                ),
                            ]}
                            rowCount={filteredItems.length}
                            on:downloadQuoteSheetTemplate={handleEvents}
                        />
                        <UploadButton bind:dialogOpen={showUploadDialog} disabled={!canInsert} on:openFileDialogConfirmed={handleEvents} />
                    {/if}
                    {#if screenMode == "upload"}
                        <ClearQSButton on:clearAllData={handleEvents} />
                        <SaveQSButton dataValidFlag={screenMode != "upload" || (screenMode == "upload" && rowsValid)} on:saveAllData={handleEvents} />
                    {/if}
                </Flex>
            </Flex>
        </Flex>
    </section>

    <section class="filters tescoblue-border-top-thin">
        <Paper class="grey lighten-3 w-100 bs-bb pt-0 pb-1 mt-0 mb-0">
            <div class="smui-paper__content bs-bb">
                <section class="mt-2 pt-0 w-100">
                    <QSFilterBar
                        bind:tpnEnSearch
                        bind:supplierSearch
                        bind:countrySearch
                        bind:hierarchySearch
                        bind:productSearch
                        bind:statusSearchErrorsArray
                        bind:buyerSearchArray
                        {screenMode}
                        {statusErrors}
                    />
                </section>
            </div>
        </Paper>
    </section>

    <section class="tescoblue-border-top-thin">
        <Paper class="grey lighten-3 w-100 bs-bb pt-3">
            <div class="smui-paper__content bs-bb table-container">
                <QSDataTable
                    {items}
                    {loadingData}
                    {canUpdate}
                    {canDelete}
                    {statusSearchErrorsArray}
                    {tpnEnSearch}
                    {countrySearch}
                    {supplierSearch}
                    {hierarchySearch}
                    {productSearch}
                    {buyerSearchArray}
                    {screenMode}
                    bind:checkboxItems
                    bind:filteredItems
                    on:editQuoteSheetRow={handleEvents}
                    on:deleteQuoteSheetRow={handleEvents}
                    on:deleteSelectedRows={handleEvents}
                />
            </div>
        </Paper>
    </section>
</section>

{#key item}
    <QSItemDetail
        open={dialogOpen}
        title={quoteSheetDialogType == "add" ? $_("add_item") : $_("edit_item")}
        big={true}
        headerColors="tescoblue white-text"
        item={{ ...item }}
        {screenMode}
        type={quoteSheetDialogType}
        on:reject={() => {
            dialogOpen = false;
        }}
        on:quoteSheetDialogSave={handleEvents}
    ></QSItemDetail>
{/key}

<!-- Singl delete -->
<ConfirmationDialog
    open={singlDeleteConfirmationOpen}
    title={$_("delete_quotesheet")}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    itemData={null}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        singlDeleteConfirmationOpen = false;
    }}
    on:confirm={() => {
        {
            deleteRows(itemToDelete);
        }
        singlDeleteConfirmationOpen = false;
    }}
>
    {$_("delete_selected?", { values: { rows: deleteCount } })}
</ConfirmationDialog>

<!-- Multi delete -->
<ConfirmationDialog
    open={multiDeleteConfirmationDialog}
    title={$_("delete_rows")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    itemData={itemsToDelete}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        multiDeleteConfirmationDialog = false;
    }}
    on:confirm={deleteRows(itemsToDelete)}
>
    {$_("delete_selected?", { values: { rows: deleteCount } })}
</ConfirmationDialog>

<Dialog bind:open={filesUploadDialogOpen} sheet scrimClickAction="" escapeKeyAction="" aria-describedby="processing" surface$class="mnh-50pct mnw-600 mxh-90dvh">
    <DTitle id="file-process-title" class="tescoblue white-text">{$_("upload_excel_files")}</DTitle>
    <Content id="file-process-sheet-content" class="w-100 bs-bb pl-4 pr-4 ma-auto {filesUploadState == 'cancel-request' ? '' : 'flex-1'}">
        {#if filesUploadState == "cancel-request"}
            <Loader size="big" text={$_("discarting")} />
        {:else}
            <section style="display: flex; flex-direction: column; align-items: start">
                <div class="pt-4 w-100 bs-bb">
                    <div>{$_("upload")} {uploadFinishedCount}/{filesUploadCount}</div>
                    {#if uploadFinishedCount < filesUploadCount}
                        <LinearProgress indeterminate />
                    {/if}
                    <div style="max-height: 33dvh; overflow: auto; mergin-top: .5rem; margin-inline: .5rem;">
                        {#each filesStatus as row}
                            <div class="fs-08rem tescoblue-text">
                                <span class="text-bold green-text">{$_(row.uploadStatus)}</span> - {row.fileName}
                            </div>
                        {/each}
                    </div>
                </div>
                <div class="pt-4 w-100 bs-bb">
                    <div>{$_("validation")} {validationFinishedCount}/{filesUploadCount}</div>
                    {#if validationFinishedCount < filesUploadCount}
                        <LinearProgress indeterminate />
                    {/if}
                    <div style="max-height: 33dvh; overflow: auto; margin-top: .5rem; margin-inline: .5rem;">
                        {#each filesStatus as row}
                            <div class="fs-08rem tescoblue-text">
                                <span class="text-bold {['error', 'rejected'].includes(row.validationStatus) ? 'red-text' : 'green-text'}">{$_(row.validationStatus)}</span>
                                - {row.fileName}
                                {#if row.errorMessage}
                                    - <span class="red-text yellow lighten-4">{$_(row.errorMessage)}</span>
                                {/if}
                            </div>
                        {/each}
                    </div>
                </div>
            </section>
        {/if}
    </Content>
    <Flex direction="row" aling="right" class="pr-4 ma-4 gap-1">
        {#if filesUploadState != "cancel-request"}
            <Button
                class="tescored-text white-tescored-outlined"
                on:click={() => {
                    items = [];
                    screenMode = "edit";
                    unsavedRowsExists = false;
                    dataLoaded = false;
                    if (filesUploadState == "started") {
                        filesUploadState = "cancel-request";
                    } else {
                        filesUploadDialogOpen = false;
                    }
                }}
            >
                <Label>{filesUploadState == "finished" ? $_("discard_all") : $_("cancel")}</Label>
            </Button>
        {/if}
        {#if items.length > 0}
            <Button
                class="{validationFinishedCount < filesUploadCount ? 'grey' : 'tescoblue'} white-text"
                on:click={() => {
                    filesUploadDialogOpen = false;
                }}
                disabled={validationFinishedCount < filesUploadCount}
            >
                <Label>{$_("continue")}</Label>
            </Button>
        {/if}
    </Flex>
</Dialog>

<FullscreenLoader open={apiCallProcessing} text={apiCallProcessingMessage} />
