<script lang="ts">
    import { onMount } from "svelte";
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";
    import Dialog, { Title as DTitle, Content as Content, Actions, InitialFocus } from "@smui/dialog";
    import CircularProgress from "@smui/circular-progress";
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import Button, { Label } from "@smui/button";
    import Autocomplete from "../../extra/Autocomplete.svelte";
    import Textfield from "@smui/textfield";
    import { Icon } from "@smui/icon-button";

    import { getProduct as apiGetProduct } from "../../../api/Product";
    import { getAmendmentCountryProduct as apiGetAmendmentCountryProduct } from "../../../api/AmendmentCountry";

    import { nvl, getFieldErrMsg, existsError } from "../Upload/Upload.js";

    import { validateUnits, validateVolume, validateACExists } from "../Upload/ValidateAmendmentCountry";

    import DTInputCell from "../common/DTInputCell.svelte";
    import DTSelectCell from "../common/DTSelectCell.svelte";
    import { snackbarError, selectedWeek } from "../../../stores/AppStatus";

    import { unitList } from "../../../stores/AppConfig";
    import type { AmendmentCountryItems } from "../../../types/AmendmentCountry";

    export let dcList;
    export let item: AmendmentCountryItems;
    export let title;
    export let type = "add";
    export let open;
    export let screenMode = "edit";

    const dispatch = createEventDispatcher();

    export let warehouseNameForDisplay = "";

    let buttonSaveEnabled = true;
    let initialized = false;
    let deliveryDateListLoaded = false;

    // Autocomplete
    let prodEl;
    let menuBottom;
    let menuWidth;
    let productsFetching = false;
    let matchingItem;

    let unitListLoc = [];
    let localItems = [];
    let selectedLocalItem = [];
    let selectedProduct = null;
    let deliveryDateList = [];

    // Upload mode - store fields with error
    let keepUnitsEnabled = screenMode == "upload" && existsError(item, "units", "E") && item.productId;

    $: if (open && !initialized) {
        console.log("Initializing Popup....");
        deliveryDateListLoaded = false;
        initialized = true;
    } else if (!open && initialized) {
        console.log("Already initialized: ", { open }, { initialized });
        initialized = false;
    }

    $: keepDeliveryDateEnabled = screenMode == "upload" || (existsError(item, "deliveryDate", "E") && item.deliveryDate) || (type == "add" && item.tpnb && item.tpnb != null);
    $: {
        buttonSaveEnabled =
            !productsFetching &&
            item &&
            item.errors &&
            Array.isArray(item.errors) &&
            item.errors.length == 0 &&
            item.productId != null &&
            !productNotSelected &&
            item?.amendVol != undefined &&
            item?.amendVol != null &&
            item?.amendVol != 0 &&
            item?.deliveryDate != undefined &&
            item?.deliveryDate != null;
        console.log({ buttonSaveEnabled });
    }
    $: productNotSelected = !item || (item && (!item?.tpnb || item?.tpnb == undefined));

    $: {
        /*
        console.log('======================================================');
        console.log('======================================================');
        console.log('Recalculate DeliveryDateList...');
        console.log('======================================================');
        console.log('======================================================');
        console.log({item});
        console.log({matchingItem});
        console.log('item.deliveryDate',item?.deliveryDate);
        console.log('screenMode: ',screenMode);
        console.log('type: ', type);
        console.log('existsError(item, "deliveryDate", "E"): ',existsError(item, "deliveryDate", "E"));
        console.log('item.deliveryDate: ', item?.deliveryDate);
        console.log('item.productDetail: ', item?.productDetail);
        console.log('deliveryDateListLoaded: ',deliveryDateListLoaded);
        */
        if (!deliveryDateListLoaded && item && item?.productDetail) {
            let d = new Date($selectedWeek.week_start);
            deliveryDateList = [];
            if (screenMode == "upload" && type == "edit") {
                //  && existsError(item, "deliveryDate", "E")  && item?.deliveryDate && item?.deliveryDate != null) {
                if (existsError(item, "deliveryDate", "E") && item?.deliveryDate && item?.deliveryDate != null) {
                    deliveryDateList.push({ day: 0, id: item.deliveryDate, name: $_("invalid_date") + ": " + new Date(item.deliveryDate).toAppDate(), volume: null });
                }
                for (let i = 1; i <= 7; i++) {
                    deliveryDateList.push({ day: i, id: d.addDays(i - 1).toWSDate(), name: d.addDays(i - 1).toAppDate(), volume: item.productDetail["d" + i + "PurchasedVol"] });
                }
            }
            if (matchingItem && matchingItem != null /* && Array.isArray(matchingItem) && matchingItem.length > 0 */) {
                for (let i = 1; i <= 7; i++) {
                    deliveryDateList.push({ day: i, id: d.addDays(i - 1).toWSDate(), name: d.addDays(i - 1).toAppDate(), volume: matchingItem["d" + i + "PurchasedVol"] });
                }
            }
            deliveryDateListLoaded = true;
        }
        //console.log('FINISHED Recalc of DeliveryDateList: ', deliveryDateList);
    }

    onMount(async () => {
        unitListLoc = $unitList
            .filter((e) => e.active)
            .map((x) => {
                return { id: x.code, name: x.description };
            });
    });

    function saveItemDetail() {
        console.log("====================== saveItemDetail =========================");
        if (item && item.errors && item.errors != null && item.errors.length > 0) {
            $snackbarError.text = $_("cannot_save_ac_errors_found", { values: { reason: "cannot_save_ac_errors_found" } });
            $snackbarError.element && $snackbarError.element.open();
        } else {
            dispatch("AmendmentCountryDialogSave", { item: item, type: type });
        }
    }

    function cancelItemDetail() {
        console.log("====================== cancelItemDetail =========================");
        dispatch("reject");
    }

    function handleNoteSelectionChange(event) {
        // Validation is not needed as select is taking values from store
        // Remove all errors where field is "note"
        item.errors = item.errors.filter((error) => error.field !== "note");
    }

    // Function to handle warehouse selection changes
    function handleWarehouseSelectionChange() {
        console.log("====================== handleWarehouseSelectionChange =========================");
        const selectedWarehouseId = item.warehouseId;
        if (selectedWarehouseId) {
            productClear();
            item.warehouseId = selectedWarehouseId;

            // Find the corresponding country information based on the selected warehouse ID
            const selectedWarehouse = dcList.find((dc) => dc.id === selectedWarehouseId);
            if (selectedWarehouse) {
                item.country = selectedWarehouse.country;
            } else {
                console.error("Selected warehouse not found in dcList");
            }
            loadLocalProducts(selectedWarehouseId);
        }
    }

    // Function to handle unit changes
    async function handleUnitChange(event) {
        console.log("====================== handleUnitChange =========================");
        console.log(event.detail.value);
        const ceTpn = await apiGetProduct(item.productId, item.tpnb, null, null, null, null, item.country, null, 1);

        if (ceTpn) {
            const validationError = validateUnits(event.detail.value, ceTpn[0].units);

            // Remove all errors where field is "units"
            item.errors = item.errors.filter((error) => error.field !== "units");

            if (validationError) {
                item.errors.push(validationError);
                item = item;
            }
        } else {
            console.log("ceTpn Not found for unit change validation");
        }
    }

    // Function to handle volume changes
    function handleVolumeChange(value, field, originalVol) {
        console.log("====================== handleVolumeChange =========================");
        //console.log({field});
        //console.log({value});
        //console.log({originalVol});
        const validationError = validateVolume(Number(value), field, originalVol);
        // Remove all errors for the given field
        item.errors = item.errors.filter((error) => error.field !== field);
        if (validationError) {
            item.errors.push(validationError);
        }
    }

    async function loadLocalProducts(warehouseId) {
        console.log("====================== loadLocalProducts =========================");
        console.log("warehouseId = " + warehouseId);
        productsFetching = true;
        localItems = await apiGetAmendmentCountryProduct(null, null, $selectedWeek.tesco_year, $selectedWeek.tesco_week, null, warehouseId, null, "Y");
        productsFetching = false;
        console.log({ localItems });
    }

    async function searchLocalProduct(input: string) {
        console.log("====================== searchLocalProduct =========================");
        console.log("input: ", input);
        console.log({ localItems });
        console.log("localItems.length: ", localItems.length);

        if (input.length < 3) {
            return [];
        }
        try {
            if (localItems.length == 0) {
                console.log("Loading products...");
                await loadLocalProducts(item?.warehouseId);
            }

            console.log("Search product in list...");

            let results = [...localItems].filter(
                (x) => x.tpnb.toString().toUpperCase().includes(input.toUpperCase()) || (x.tpnbDescription && x.tpnbDescription.toUpperCase().includes(input.toUpperCase())),
            );
            selectedLocalItem = results;

            return results.map((item) => `${item.tpnb} - ${item.tpnbDescription}`);
        } catch (error) {
            console.error("Error fetching SupplierProduct autocomplete data:", error);
            return [];
        }
    }

    function productBlur(object) {
        console.log("====================== productBlur =========================");
        console.log({ selectedProduct });
        if (typeof selectedProduct === "undefined" || !selectedProduct || selectedProduct == null || selectedProduct == "") {
            productClear();
        }
    }

    function productClear() {
        console.log("====================== productClear =========================");
        item.id = null;
        //        item.year = null;
        //        item.week = null;
        item.forecastId = null;
        item.country = null;
        item.warehouseId = null;
        item.productId = null;
        item.productDetailId = null;
        item.productDescription = null;
        item.tpnb = null;
        item.tpnbDescription = null;
        item.deliveryDate = null;
        item.units = null;
        item.originalVol = null;
        item.amendVol = null;
        item.status = null;
        item.countryComment = null;
        item.userComment = null;
        item.fileName = null;
        item.errors = [];
        item.warnings = [];
        item.selected = false;
        selectedProduct = null;
        // Cleanup previous error
        if (item && item.errors && item.errors.map((x) => x.field).indexOf("productId") > -1) {
            item.errors.splice(item.errors.map((x) => x.field).indexOf("productId"), 1);
        }
    }

    async function validateACExistence(item) {
        console.log("====================== validateACExistence =========================");
        // Prepare promises for validateACExists and getProduct
        console.log("Before promise", item);
        const validatePromise = validateACExists(item);
        const [amendmentCountryExists] = await Promise.all([validatePromise]);

        // Handle amendmentCountryExists
        console.log("amendmentCountryExists", amendmentCountryExists);
        const errorMessageIndex = item.errors.findIndex((error) => error.message === "country_amendment_in_progress_exists_already");
        const warningMessageIndex = item.warnings.findIndex((warning) => warning.message === "country_amendment_exists_already");
        // Remove the error message if it exists
        if (errorMessageIndex !== -1) {
            console.log("Removing error message...");
            item.errors.splice(errorMessageIndex, 1);
        }
        // Remove the warning message if it exists
        if (warningMessageIndex !== -1) {
            console.log("Removing warning message...");
            item.warnings.splice(warningMessageIndex, 1);
        }
        if (amendmentCountryExists) {
            // Add the error message
            if (amendmentCountryExists.type == "E") {
                console.log("Adding error message...");
                item.errors.push(amendmentCountryExists);
            }
            // Add the warning message
            if (amendmentCountryExists.type == "W") {
                console.log("Adding warning message...");
                item.warnings.push(amendmentCountryExists);
            }
        }
        console.log("FINISH validateACExistence: ", item);
        return item;
    }

    async function productSelected(option) {
        console.log("====================== productSelected =========================");
        console.log({ selectedLocalItem });
        console.log(option.detail);

        // Extract the tpnb from option.detail
        const tpnb = option.detail.split(" - ")[0];
        matchingItem = selectedLocalItem.find((item) => item.tpnb === tpnb);
        if (matchingItem) {
            // Assign matching item properties to the item
            console.log("matchingItem", item);
            //item.year = null;
            //item.week = null;
            item.forecastId = matchingItem.forecastId;
            item.productId = matchingItem.id;
            item.tpnb = matchingItem.tpnb;
            item.productDetailId = matchingItem.productDetailId;
            item.tpnbDescription = matchingItem.tpnbDescription;
            item.productDescription = matchingItem.productDescription;
            item.forecastId = matchingItem.forecastId;
            item.country = matchingItem.country;
            item.warehouseId = matchingItem.warehouseId;
            item.deliveryDate = new Date($selectedWeek.week_start).toWSDate();
            item.units = matchingItem.units;
            item.originalVol = matchingItem.d1PurchasedVol;
            item.amendVol = null;
            item.status = "new";
            item.countryComment = null;
            item.userComment = null;
            item.fileName = null;
            item.errors = [];
            item.warnings = [];
            item.selected = false;
            deliveryDateList = [];
            item = await validateACExistence(item);
            let d = new Date($selectedWeek.week_start);
            for (var i = 1; i <= 7; i++) {
                deliveryDateList.push({ day: i, id: d.addDays(i - 1).toWSDate(), name: d.addDays(i - 1).toAppDate(), volume: matchingItem["d" + i + "PurchasedVol"] });
            }
            item.deliveryDate = deliveryDateList[0].id;
            deliveryDateListLoaded = true;
        } else {
            console.log("No matching item found.");
        }
    }

    async function handleDeliveryDateChange() {
        console.log("====================== handleDeliveryDateChange =========================");
        let selectedDay = [...deliveryDateList].filter((i) => i.id == item.deliveryDate)[0].day;
        const errorMessageIndex = item.errors.findIndex((error) => error.message === "deliveryDate_out_of_week");
        if (selectedDay != 0) {
            item = await validateACExistence(item);
            item.originalVol = [...deliveryDateList].filter((i) => i.id == item.deliveryDate)[0].volume;
            // Duplicity not found and error message exists => remove the error message
            if (errorMessageIndex !== -1) {
                item.errors.splice(errorMessageIndex, 1);
            }
        } else {
            if (errorMessageIndex == -1) {
                item.errors.push({ priority: 3, message: "deliveryDate_out_of_week", field: "deliveryDate", type: "E" });
            }
        }
    }
</script>

<Dialog
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="data-changed-title"
    aria-describedby="data-changed-content"
    class="pa-2"
    surface$style="width: 850px; max-width: calc(100vw - 32px);"
>
    <DTitle id="data-changed-title" class="tescoblue white-text">{title}</DTitle>
    <Content>
        {#if item}
            <DataTable class="w-100 input-form no-border pa-2">
                <Body>
                    <!--  DC Selector -->
                    <Row>
                        <Cell class="form-label">{$_("dc")}</Cell>
                        {#if type == "edit"}
                            <DTInputCell colspan="2" value={warehouseNameForDisplay} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                        {:else if Array.isArray(dcList) && dcList.length > 0}
                            <DTSelectCell
                                bind:value={item.warehouseId}
                                list={dcList}
                                format={{ showId: false, translateName: true }}
                                required
                                on:change={handleWarehouseSelectionChange}
                            />
                        {/if}
                    </Row>

                    <!--  TPNB Selector  -->
                    <Row>
                        <Cell class="form-label">{$_("tpn")}</Cell>
                        {#if type == "edit"}
                            <DTInputCell
                                colspan="2"
                                value={`${item.tpnb} - ${item.tpnbDescription}`}
                                editable={false}
                                numeric={false}
                                class="fs-1rem tescoblue-text grey lighten-2"
                            />
                        {:else if item.country}
                            <td colspan="4">
                                <Autocomplete
                                    class="w-100 tescoblue-text edit-cell-border"
                                    search={(input) => searchLocalProduct(input)}
                                    title={$_("enter_min_chars", { values: { chars: 3 } })}
                                    showMenuWithNoInput={false}
                                    minInputSizeToShowMenu="3"
                                    selectOnExactMatch={true}
                                    bind:value={selectedProduct}
                                    bind:this={prodEl}
                                    menu$class="mdc-menu-surface--fixed with-parameter__menu"
                                    style="--mdc-menu-min-width: {menuWidth}px; --mdc-menu-top: {menuBottom}px"
                                    on:focusin={() => {
                                        menuWidth = prodEl?.getElement()?.clientWidth;
                                        menuBottom = prodEl?.getElement()?.getBoundingClientRect()?.bottom;
                                    }}
                                    on:click={() => {
                                        menuWidth = prodEl?.getElement()?.clientWidth;
                                        menuBottom = prodEl?.getElement()?.getBoundingClientRect()?.bottom;
                                    }}
                                    on:focus={(event) => {
                                        event.detail.target.select();
                                    }}
                                    on:blur={(event) => {
                                        productBlur(event);
                                    }}
                                    on:SMUIAutocomplete:selected={(option) => {
                                        productSelected(option);
                                    }}
                                >
                                    <Textfield
                                        type="text"
                                        bind:value={selectedProduct}
                                        withTrailingIcon={existsError(item, "productId", "E") || productsFetching}
                                        disabled={productsFetching}
                                        invalid={existsError(item, "productId", "E")}
                                        input$maxlength={30}
                                        input$autocomplete="productId"
                                        input$emptyValueUndefined
                                        input$placeholder={productsFetching ? $_("loading_products_wait") : $_("enter_min_chars", { values: { chars: 3 } })}
                                        required
                                        style="min-width: 500px;"
                                        class="w-100"
                                    >
                                        <svelte:fragment slot="trailingIcon">
                                            {#if existsError(item, "productId", "E")}
                                                <Icon class="material-icons red-text">error</Icon>
                                            {:else if productsFetching}
                                                <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
                                            {/if}
                                        </svelte:fragment>
                                    </Textfield>
                                </Autocomplete>
                            </td>
                        {:else if !item.country}
                            <Cell colspan="4">{$_("dc_not_selected")}</Cell>
                        {:else}
                            <Cell colspan="4">{$_("rms_product_not_found")}</Cell>
                        {/if}
                    </Row>
                    <!-- Errors on TPNB -->
                    {#if existsError(item, "tpnb", "E")}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {$_(getFieldErrMsg(item, "tpnb", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <!-- Warnings on TPNB -->
                    {#if existsError(item, "tpnb", "W")}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {$_(getFieldErrMsg(item, "tpnb", "W"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}
                    <Row>
                        <Cell class="form-label">{$_("ce_tpn")}</Cell>
                        <DTInputCell
                            colspan="2"
                            value={item.productId && item.productDescription ? `${item.productId} - ${item.productDescription}` : ""}
                            editable={false}
                            numeric={false}
                            class="fs-1rem tescoblue-text grey lighten-2"
                        />
                    </Row>
                    <!-- Units error -->
                    {#if existsError(item, "productId", "E")}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {$_(getFieldErrMsg(item, "productId", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}

                    <!--  Units  -->
                    <Row>
                        <Cell class="form-label">{$_("units")}</Cell>
                        {#if existsError(item, "units", "E") || keepUnitsEnabled}
                            <td colspan="4">
                                <DTSelectCell
                                    bind:value={item.units}
                                    list={unitListLoc}
                                    format={{ showId: false, translateName: true }}
                                    required
                                    errorMsg={existsError(item, "units", "E")}
                                    on:change={handleUnitChange}
                                ></DTSelectCell>
                            </td>
                        {:else}
                            <DTInputCell colspan="2" value={$_(nvl(item.units, ""))} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                        {/if}
                    </Row>
                    <!-- Units error -->
                    {#if existsError(item, "units", "E")}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {$_(getFieldErrMsg(item, "units", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}

                    <!--  Delivery Date  -->
                    <Row>
                        <Cell class="form-label">{$_("delivery_date")}</Cell>
                        {#if existsError(item, "deliveryDate", "E") || keepDeliveryDateEnabled}
                            <td colspan="4">
                                {#key item.tpnb}
                                    {#if deliveryDateList?.length > 0}
                                        <DTSelectCell
                                            bind:value={item.deliveryDate}
                                            list={deliveryDateList}
                                            format={{ showId: false, translateName: true }}
                                            required
                                            errorMsg={existsError(item, "deliveryDate", "E")}
                                            on:change={handleDeliveryDateChange}
                                        ></DTSelectCell>
                                    {/if}
                                {/key}
                            </td>
                        {:else}
                            <DTInputCell colspan="2" value={$_(nvl(item.deliveryDate, ""))} editable={false} numeric={false} class="fs-1rem tescoblue-text grey lighten-2" />
                        {/if}
                    </Row>
                    <!-- Delivery Date error -->
                    {#if existsError(item, "deliveryDate", "E")}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {$_(getFieldErrMsg(item, "deliveryDate", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}

                    <!--  Original Volume - !!! READ ONLY !!! -->
                    <Row>
                        <Cell class="form-label">{$_("original_vol")}</Cell>
                        <td colspan="4">
                            <Textfield
                                variant="outlined"
                                disabled="true"
                                value={item.originalVol}
                                input$emptyValueUndefined
                                input$class="tescoblue-text fs-1rem"
                                class="w-100 tescoblue-text edit-cell-border grey lighten-2"
                            />
                        </td>
                    </Row>

                    <!--  Amendment Volume  -->
                    <Row>
                        <Cell class="form-label">{$_("amend_vol")}</Cell>
                        <td colspan="4">
                            <Textfield
                                variant="outlined"
                                disabled={productNotSelected}
                                bind:value={item.amendVol}
                                withTrailingIcon={existsError(item, "amendVol", "E")}
                                invalid={existsError(item, "amendVol", "E") ||
                                    (!productNotSelected && (item?.amendVol == null || item?.amendVol == 0 || item?.amendVol == undefined))}
                                updateInvalide={false}
                                input$maxlength={8}
                                input$autocomplete="amendVol"
                                input$emptyValueUndefined
                                input$class="tescoblue-text fs-1rem"
                                required
                                class="w-100 tescoblue-text edit-cell-border"
                                on:input={() => {
                                    handleVolumeChange(item.amendVol, "amendVol", item.originalVol ?? null);
                                }}
                            >
                                <svelte:fragment slot="trailingIcon">
                                    {#if existsError(item, "amendVol", "E")}
                                        <Icon class="material-icons red-text mdc-text-field__icon mdc-text-field__icon--trailing">error</Icon>
                                    {/if}
                                </svelte:fragment>
                            </Textfield>
                        </td>
                    </Row>
                    {#if existsError(item, "amendVol", "E") || (!productNotSelected && (item?.amendVol == null || item?.amendVol == undefined))}
                        <Row class="helpertext__row">
                            <Cell></Cell>
                            <Cell colspan="4" style="padding: 0">
                                <div class="red-text fs-08rem">
                                    {!productNotSelected && (item?.amendVol == null || item?.amendVol == undefined)
                                        ? $_("field_is_required")
                                        : $_(getFieldErrMsg(item, "amendVol", "E"))}
                                </div>
                            </Cell>
                        </Row>
                    {/if}

                    <!--  Country user Comment  -->
                    <Row>
                        <Cell class="form-label">{$_("country_comment")}</Cell>
                        <td colspan="4">
                            <Textfield
                                variant="outlined"
                                disabled={productNotSelected}
                                bind:value={item.countryComment}
                                withTrailingIcon={existsError(item, "countryComment", "E")}
                                invalid={existsError(item, "countryComment", "E") || (!productNotSelected && (item?.countryComment == null || item?.countryComment == undefined))}
                                updateInvalide={false}
                                input$maxlength={500}
                                input$autocomplete="countryComment"
                                input$emptyValueUndefined
                                input$class="tescoblue-text fs-1rem"
                                required
                                class="w-100 tescoblue-text edit-cell-border"
                            ></Textfield>
                        </td>
                    </Row>

                    <!--  User Comment  !!! READ ONLY !!! -->
                    <Row>
                        <Cell class="form-label">{$_("user_comment")}</Cell>
                        <DTInputCell
                            colspan="2"
                            value={$_(nvl(item.userComment, ""))}
                            editable={false}
                            maxlength={500}
                            numeric={false}
                            class="w-100 fs-1rem tescoblue-text grey lighten-2"
                        />
                    </Row>

                    <!--  Status  -->
                    <Row>
                        <Cell class="form-label">{$_("status")}</Cell>
                        <DTInputCell colspan="2" value={$_(nvl(item.status, ""))} editable={false} numeric={false} class="w-100 fs-1rem tescoblue-text grey lighten-2" />
                    </Row>
                </Body>
            </DataTable>
        {/if}
    </Content>
    <Actions class="pr-4 gap-1 space-around">
        <Button
            action="close"
            class="tescored tescored-text outlined"
            on:click={() => {
                cancelItemDetail();
            }}
        >
            <Label>{$_("cancel")}</Label>
        </Button>
        <Button
            action="save"
            use={[InitialFocus]}
            class={buttonSaveEnabled ? "tescoblue white-text" : "grey white-text"}
            disabled={!buttonSaveEnabled}
            on:click={() => {
                saveItemDetail();
            }}
        >
            <Label>{$_("save")}</Label>
        </Button>
    </Actions>
</Dialog>
