<script lang="ts">
  import DataTable, { Head, Body, Row, Cell, Pagination, Label } from "@smui/data-table";
  import Select, { Option } from "@smui/select";
  import IconButton from "@smui/icon-button";
  import CircularProgress from "@smui/circular-progress";
  import { _ } from "svelte-i18n";

  import type { RpLogChange } from "../../../types/Report";

  export let items = [];
  export let loadingData = false;
  export let dataLoaded = false;

  // Filters
  export let countrySearchArray = [];
  export let dcSearchArray = [];
  export let supplierSearchArray = [];
  export let buyerSearchArray = [];
  export let filteredItems: RpLogChange[] = [];

  // Pagging
  let rowsPerPage = 25;
  let currentPage = 0;

  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, filteredItems.length);
  $: slice = filteredItems.slice(start, end);
  $: lastPage = Math.max(Math.ceil(filteredItems.length / rowsPerPage) - 1, 0);

  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }

  // Apply filters
  $: filteredItems = items?.filter((item) => {
    const countryMatch = countrySearchArray.length > 0 ? countrySearchArray.some((unitId) => item.countryCode?.includes(unitId)) : true;
    // Convert item.warehouseId to a DC code for comparison
    const dcMatch = dcSearchArray.length > 0 ? dcSearchArray.map((d) => d.id).includes(item.dcCode) : true;
    const supplierMatch =
      supplierSearchArray.length > 0
        ? supplierSearchArray
            .map((x) => {
              return x.id;
            })
            .includes(item.supplierId)
        : true;
    const buyerMatch =
      buyerSearchArray.length > 0
        ? buyerSearchArray
            .map((x) => {
              return x.label;
            })
            .includes(item.buyer)
        : true;

    return countryMatch && dcMatch && supplierMatch && buyerMatch;
  });

  $: {
    console.log("---------------------");
    console.log({ items });
  }
</script>

<DataTable table$aria-label="report" class="w-100 mt-2 report">
  <Head>
    <Row style="height: 2rem !important;">
      <Cell colspan="24" class="datatable-header bottom-border-tescoblue"></Cell>
      <Cell colspan="8" class="datatable-header bottom-border-tescoblue left-splitter text-center">{$_("singles")}</Cell>
      <Cell colspan="8" class="datatable-header bottom-border-tescoblue left-splitter text-center">{$_("cases_kartons")}</Cell>
      <Cell colspan="8" class="datatable-header bottom-border-tescoblue left-splitter text-center right-splitter">{$_("pallets")}</Cell>
      <Cell colspan="2" class="datatable-header bottom-border-tescoblue"></Cell>
    </Row>
    <Row style="height: 2rem !important;">
      <Cell columnId="TescoWeek" class="datatable-header no-border-radius text-center wrap-spaces" numeric>
        <Label>{$_("tesco_week")}</Label>
      </Cell>
      <Cell columnId="CountryId" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("country_id")}</Label>
      </Cell>
      <Cell columnId="DCNo" class="datatable-header" numeric>
        <Label>{$_("dc_no")}</Label>
      </Cell>
      <Cell columnId="GFSTPN" class="datatable-header" numeric>
        <Label>{$_("gfs_tpn")}</Label>
      </Cell>
      <Cell columnId="SupplierIDGFS" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("supplier_id_gfs")}</Label>
      </Cell>
      <Cell columnId="SupplierIdCountry" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("supplier_id_country")}</Label>
      </Cell>
      <Cell columnId="TPNCountry" class="datatable-header" numeric>
        <Label>{$_("country_tpn")}</Label>
      </Cell>
      <Cell columnId="ProductNameCountry" class="datatable-header">
        <Label>{$_("product_name_country")}</Label>
      </Cell>
      <Cell columnId="Country" class="datatable-header">
        <Label>{$_("country")}</Label>
      </Cell>
      <Cell columnId="DC" class="datatable-header">
        <Label>{$_("dc")}</Label>
      </Cell>
      <Cell columnId="Buyer" class="datatable-header">
        <Label>{$_("buyer")}</Label>
      </Cell>
      <Cell columnId="ArticleGroup" class="datatable-header text-center wrap-spaces">
        <Label>{$_("article_group")}</Label>
      </Cell>
      <Cell columnId="ProductEN" class="datatable-header">
        <Label>{$_("product_en")}</Label>
      </Cell>
      <Cell columnId="CountryOfOrigin" class="datatable-header text-center wrap-spaces">
        <Label>{$_("country_of_origin")}</Label>
      </Cell>
      <Cell columnId="Variety" class="datatable-header">
        <Label>{$_("variety")}</Label>
      </Cell>
      <Cell columnId="Supplier" class="datatable-header">
        <Label>{$_("supplier")}</Label>
      </Cell>
      <Cell columnId="Unit" class="datatable-header">
        <Label>{$_("unit")}</Label>
      </Cell>
      <Cell columnId="CartonLogistic" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("carton_logistic")}</Label>
      </Cell>
      <Cell columnId="CartonLogisticLW" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("carton_logistic_lw")}</Label>
      </Cell>
      <Cell columnId="PalletLogistic" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("pallet_logistic")}</Label>
      </Cell>
      <Cell columnId="PalletLogisticLW" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("pallet_logistic_lw")}</Label>
      </Cell>
      <Cell columnId="Reason" class="datatable-header" numeric>
        <Label>{$_("report_reason")}</Label>
      </Cell>
      <Cell columnId="Temperature" class="datatable-header" numeric>
        <Label>{$_("temperature")}</Label>
      </Cell>
      <Cell columnId="ForecastDemand" class="datatable-header" numeric>
        <Label>{$_("forecast_demand")}</Label>
      </Cell>
      <Cell columnId="SinglesMon" class="datatable-header left-splitter" numeric>
        <Label>{$_("mon")}</Label>
      </Cell>
      <Cell columnId="SinglesTue" class="datatable-header" numeric>
        <Label>{$_("tue")}</Label>
      </Cell>
      <Cell columnId="SinglesWed" class="datatable-header" numeric>
        <Label>{$_("wed")}</Label>
      </Cell>
      <Cell columnId="SinglesThu" class="datatable-header" numeric>
        <Label>{$_("thu")}</Label>
      </Cell>
      <Cell columnId="SinglesFri" class="datatable-header" numeric>
        <Label>{$_("fri")}</Label>
      </Cell>
      <Cell columnId="SinglesSat" class="datatable-header" numeric>
        <Label>{$_("sat")}</Label>
      </Cell>
      <Cell columnId="SinglesSun" class="datatable-header" numeric>
        <Label>{$_("sun")}</Label>
      </Cell>
      <Cell columnId="SinglesTotal" class="datatable-header" numeric>
        <Label>{$_("total")}</Label>
      </Cell>
      <Cell columnId="CasesMon" class="datatable-header left-splitter" numeric>
        <Label>{$_("mon")}</Label>
      </Cell>
      <Cell columnId="CasesTue" class="datatable-header" numeric>
        <Label>{$_("tue")}</Label>
      </Cell>
      <Cell columnId="CasesWed" class="datatable-header" numeric>
        <Label>{$_("wed")}</Label>
      </Cell>
      <Cell columnId="CasesThu" class="datatable-header" numeric>
        <Label>{$_("thu")}</Label>
      </Cell>
      <Cell columnId="CasesFri" class="datatable-header" numeric>
        <Label>{$_("fri")}</Label>
      </Cell>
      <Cell columnId="CasesSat" class="datatable-header" numeric>
        <Label>{$_("sat")}</Label>
      </Cell>
      <Cell columnId="CasesSun" class="datatable-header" numeric>
        <Label>{$_("sun")}</Label>
      </Cell>
      <Cell columnId="CasesTotal" class="datatable-header" numeric>
        <Label>{$_("total")}</Label>
      </Cell>

      <Cell columnId="PalletMon" class="datatable-header left-splitter" numeric>
        <Label>{$_("mon")}</Label>
      </Cell>
      <Cell columnId="PalletTue" class="datatable-header" numeric>
        <Label>{$_("tue")}</Label>
      </Cell>
      <Cell columnId="PalletWed" class="datatable-header" numeric>
        <Label>{$_("wed")}</Label>
      </Cell>
      <Cell columnId="PalletThu" class="datatable-header" numeric>
        <Label>{$_("thu")}</Label>
      </Cell>
      <Cell columnId="PalletFri" class="datatable-header" numeric>
        <Label>{$_("fri")}</Label>
      </Cell>
      <Cell columnId="PalletSat" class="datatable-header" numeric>
        <Label>{$_("sat")}</Label>
      </Cell>
      <Cell columnId="PalletSun" class="datatable-header" numeric>
        <Label>{$_("sun")}</Label>
      </Cell>
      <Cell columnId="PalletTotal" class="datatable-header right-splitter" numeric>
        <Label>{$_("total")}</Label>
      </Cell>
      <Cell columnId="AgreedPrice" class="datatable-header text-center wrap-spaces" numeric>
        <Label>{$_("agreed_price")}</Label>
      </Cell>
      <Cell columnId="AgreedCurrency" class="datatable-header no-border-radius text-center wrap-spaces">
        <Label>{$_("agreed_currency")}</Label>
      </Cell>
    </Row>
  </Head>
  <Body>
    {#if loadingData}
      <Row>
        <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center;">
          <CircularProgress style="height: 32px; width: 32px; margin: auto" indeterminate />
        </td>
      </Row>
    {:else if slice && slice.length == 0}
      <Row>
        <td colspan="15" class="mdc-data-table__cell" style="width: 100%; text-align: center; height: 3rem !important;">
          {dataLoaded ? $_("no_data_for_week") : $_("load_week_data")}
        </td>
      </Row>
    {:else}
      {#each slice as data (data.id)}
        <Row>
          <Cell numeric>{data.tescoWeek}</Cell>
          <Cell numeric>{data.countryId ? data.countryId : ""}</Cell>
          <Cell numeric>{data.dcNo > 0 ? data.dcNo : ""}</Cell>
          <Cell numeric>{data.productId}</Cell>
          <Cell numeric>{data.supplierId}</Cell>
          <Cell numeric>{data.rmsSupplierNo}</Cell>
          <Cell numeric>{data.tpnb}</Cell>
          <Cell>{data.countryProductName}</Cell>
          <Cell>{data.countryCode}</Cell>
          <Cell>{data.dcCode}</Cell>
          <Cell>{data.buyer}</Cell>
          <Cell>{data.articleGroup}</Cell>
          <Cell>{data.ceTpnName}</Cell>
          <Cell>{data.countryOfOrigin}</Cell>
          <Cell>{data.variety ? data.variety : ""}</Cell>
          <Cell>{data.supplierName}</Cell>
          <Cell>{data.units}</Cell>
          <Cell numeric>{data.cartonLogistics}</Cell>
          <Cell numeric>{data.cartonLogisticsLW}</Cell>
          <Cell numeric>{data.palletLogistics}</Cell>
          <Cell numeric>{data.palletLogisticsLW}</Cell>
          <Cell>{$_(data.reason)}</Cell>
          <Cell numeric>{data.temperature}</Cell>
          <Cell numeric>{data.totalForecasted}</Cell>
          <Cell class="left-splitter-black">{data.d1VolSngl}</Cell>
          <Cell numeric>{data.d2VolSngl}</Cell>
          <Cell numeric>{data.d3VolSngl}</Cell>
          <Cell numeric>{data.d4VolSngl}</Cell>
          <Cell numeric>{data.d5VolSngl}</Cell>
          <Cell numeric>{data.d6VolSngl}</Cell>
          <Cell numeric>{data.d7VolSngl}</Cell>
          <Cell numeric>{data.totalVolSngl?.toFixed(2)?.replace(".00", "")}</Cell>
          <Cell numeric class="left-splitter-black">{data.d1VolCart}</Cell>
          <Cell numeric>{data.d2VolCart}</Cell>
          <Cell numeric>{data.d3VolCart}</Cell>
          <Cell numeric>{data.d4VolCart}</Cell>
          <Cell numeric>{data.d5VolCart}</Cell>
          <Cell numeric>{data.d6VolCart}</Cell>
          <Cell numeric>{data.d7VolCart}</Cell>
          <Cell numeric>{data.totalVolCart?.toFixed(2)?.replace(".00", "")}</Cell>
          <Cell numeric class="left-splitter-black">{data.d1VolPal}</Cell>
          <Cell numeric>{data.d2VolPal}</Cell>
          <Cell numeric>{data.d3VolPal}</Cell>
          <Cell numeric>{data.d4VolPal}</Cell>
          <Cell numeric>{data.d5VolPal}</Cell>
          <Cell numeric>{data.d6VolPal}</Cell>
          <Cell numeric>{data.d7VolPal}</Cell>
          <Cell numeric class="right-splitter-black">{data.totalVolPal?.toFixed(2)?.replace(".00", "")}</Cell>
          <Cell numeric>{data.price}</Cell>
          <Cell class="text-center">{data.currency}</Cell>
        </Row>
      {/each}
    {/if}
  </Body>

  <Pagination slot="paginate">
    <svelte:fragment slot="rowsPerPage">
      <Label>{$_("rows_per_page")}</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        <Option value={10}>10</Option>
        <Option value={25}>25</Option>
        <Option value={100}>100</Option>
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      {start + 1}-{end} of {filteredItems.length}
    </svelte:fragment>

    <IconButton class="material-icons" action="first-page" title="First page" on:click={() => (currentPage = 0)} disabled={currentPage === 0}>first_page</IconButton>
    <IconButton class="material-icons" action="prev-page" title="Prev page" on:click={() => currentPage--} disabled={currentPage === 0}>chevron_left</IconButton>
    <IconButton class="material-icons" action="next-page" title="Next page" on:click={() => currentPage++} disabled={currentPage === lastPage}>chevron_right</IconButton>
    <IconButton class="material-icons" action="last-page" title="Last page" on:click={() => (currentPage = lastPage)} disabled={currentPage === lastPage}>last_page</IconButton>
  </Pagination>
</DataTable>
