<script lang="ts">
    import DataTable, { Body, Row, Cell } from "@smui/data-table";
    import Switch from "@smui/switch";
    import DTInputCell from "../../common/DTInputCell.svelte";
    import { _ } from "svelte-i18n";

    export let rowData;
    export let IdType = "number";
    export let idNotUnique = false;

    // $: {
    //     console.log({rowData});
    //     console.log({mode});
    //     console.log({idNotUnique});
    // }
</script>

<section class="scroll-on">
    <DataTable class="w-100 input-form no-border pa-2">
        <Body>
            <Row>
                <Cell class="form-label" numeric={IdType == "number"}>{$_("id")}</Cell>
                <DTInputCell
                    colspan
                    bind:value={rowData.id}
                    editable={IdType == "string"}
                    numeric={false}
                    disableSelectOnFocus={false}
                    class="w-100 {IdType == 'number' ? 'grey lighten-2' : ''}"
                />
            </Row>
            <Row>
                <Cell class="form-label">{$_("description")}</Cell>
                <DTInputCell colspan bind:value={rowData.description} editable={true} numeric={false} disableSelectOnFocus={false} />
            </Row>
            <Row>
                <Cell class="form-label">{$_("active")}</Cell>
                <Cell><Switch bind:checked={rowData.active} /></Cell>
            </Row>
        </Body>
    </DataTable>
    {#if rowData.id && idNotUnique}
        <div class="tescored-text mt-2 yellow text-center font-weight-bold fs-14">{$_("id_not_unique")}</div>
    {/if}
</section>

<style>
    section {
        padding: 0.5rem;
    }
</style>
