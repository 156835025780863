<script lang="ts">
    import Select, { Option } from "@smui/select";
    import { _ } from "svelte-i18n";

    export let options = [];
    export let selectedList = {};
</script>

<Select
    bind:value={selectedList}
    key={(option) => `${option ? option.id : ""}`}
    variant="outlined"
    class="small white tescoblue-text"
    placeholder={$_("select_list")}
    required
    invalid={!selectedList}
>
    {#each options as option}
        <Option value={option.id}>{option.name}</Option>
    {/each}
</Select>
