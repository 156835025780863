<script>
    import Button from "@smui/button";
    import { Icon } from "@smui/icon-button";
    import { mdiClipboardArrowRightOutline } from "@mdi/js";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";
    import { createEventDispatcher } from "svelte";

    export let disabled = true;

    const dispatch = createEventDispatcher();
</script>

<Flex direction="row" justify="start" class="gap-1">
    <Button
        class="{disabled ? 'grey-text white-grey-outlined' : 'tescoblue-text white-tescoblue-outlined'} pr-4 nowrap"
        title={$_("assign_qty_supp")}
        {disabled}
        on:click={() => {
            dispatch("assignQtyButtonClick");
        }}
    >
        <Icon tag="svg" viewBox="0 0 24 24" class="pr-0 pl-1">
            <path fill="currentColor" d={mdiClipboardArrowRightOutline} />
        </Icon>
        {$_("assign_qty")}
    </Button>
</Flex>
