<script>
    import { unauthorized, authenticatedUser } from "../stores/AppStatus";
    import { push } from "svelte-spa-router";
</script>

{#if $unauthorized || !$authenticatedUser}
    {push("/login")}
{:else if !$authenticatedUser?.roles || $authenticatedUser?.roles?.length == 0}
    {push("/unauthorized")}
{:else}
    {push("/appmenu")}
{/if}
