<script lang="ts">
  import DataTable, { Head, Body, Row, Cell, Label, SortValue } from "@smui/data-table";
  import Button from "@smui/button";
  import IconButton, { Icon } from "@smui/icon-button";
  import { mdiPencilBoxOutline, mdiDelete, mdiCancel, mdiArrowULeftTop, mdiCheckCircleOutline, mdiAlertCircle, mdiCartPlus } from "@mdi/js";
  import Flex from "svelte-flex";
  import { _ } from "svelte-i18n";

  import { selectedWeek, authenticatedUser } from "../../../stores/AppStatus";

  import { createEventDispatcher } from "svelte";

  // Items
  export let items: Items[] = [];
  export let filter = [];

  type Items = {
    purchaseId: number;
    id: number;
    rowType: string;
    dcId: number;
    dcName: string;
    supplierId: number;
    supplierName: number;
    cartonLogistics: number;
    palletLogistics: number;
    variety: string;
    country: string;
    d1Vol: number;
    d1AmendVol: number;
    d2Vol: number;
    d2AmendVol: number;
    d3Vol: number;
    d3AmendVol: number;
    d4Vol: number;
    d4AmendVol: number;
    d5Vol: number;
    d5AmendVol: number;
    d6Vol: number;
    d6AmendVol: number;
    d7Vol: number;
    d7AmendVol: number;
    totalVol: number;
    totalAmendVol: number;
    flexVol: number;
    fixVol: number;
    currency: string;
    price: number;
    priceEur: number;
    deliveryType: number;
    status: string;
    statusRed: boolean;
    reason: number;
    comment: string;
  };

  let rowStyle;

  $: availableCountries =
    items && Array.isArray(items)
      ? [...new Set(items.map((x) => x.country))]
          .map((x) => {
            return { id: x[0] == "C" ? 1 : x[0] == "S" ? 2 : 4, country: x };
          })
          .sort((x, y) => (x.id > y.id ? 1 : -1))
      : [];
  $: enableCountryBuying = checkMultiDCPurchases(items);

  // $: {
  //   console.log("--------------------- PSPurchaseTable ---------------------");
  //   console.log({items});
  //   console.log({filter});
  //   console.log({availableCountries});
  //   console.log({enableCountryBuying});
  // }

  // Return true if exists a country with multiple DC's and without purchases
  const checkMultiDCPurchases = (itms) => {
    let itemCountries = itms.map((x) => x.country);
    let multiDCCountries = [...new Set(itemCountries.filter((item, index) => itemCountries.indexOf(item) < index))];
    // [...new Set(itemCountries.filter((item, index) => itemCountries.some((elem, idx) => elem === item && idx !== index)))];
    let ret = false;
    multiDCCountries.forEach((cntr) => {
      if (itms.filter((r) => r.country == cntr && r.rowType == "PURCHASE").length == 0) {
        ret = true;
      }
    });
    return ret;
  };

  const dispatch = createEventDispatcher();
</script>

<DataTable table$aria-label="Purchases table" class="w-100 mt-2">
  <Head>
    <Row>
      {#if enableCountryBuying}
        <Cell rowspan="2" columnId="countryActions" class="datatable-header pl-0 pr-0">
          <Label></Label>
        </Cell>
      {/if}
      <Cell rowspan="2" columnId="actions" class="datatable-header pl-0 pr-0">
        <Label></Label>
      </Cell>
      <Cell rowspan="2" columnId="dcName" class="datatable-header right-splitter-main text-center">
        <Label>{$_("dc")}</Label>
      </Cell>
      <Cell rowspan="2" columnId="supplier" class="datatable-header right-splitter-sub sticky-col text-center">
        <Label>{$_("supplier")}</Label>
      </Cell>
      <Cell rowspan="2" columnId="logistics" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("logistics")}</Label>
      </Cell>
      <Cell rowspan="2" columnId="variety" class="datatable-header right-splitter-sub text-center">
        <Label title="{$_('country_of_origin')} / {$_('variety')}">{$_("coo")}<br />{$_("variety")}</Label>
      </Cell>
      <Cell numeric colspan="10" columnId="volume" class="datatable-header right-splitter-sub  bottom-splitter-sub text-center">
        <Label>{$_("volume")}</Label>
      </Cell>
      <Cell rowspan="2" columnId="currency" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("currency")}</Label>
      </Cell>
      <Cell numeric rowspan="2" columnId="price" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("price")}</Label>
      </Cell>
      <Cell rowspan="2" columnId="status" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("status")}</Label>
      </Cell>
      <Cell rowspan="2" columnId="reason" class="datatable-header right-splitter-sub text-center wrap-spaces ">
        <Label>{$_("reason")}</Label>
      </Cell>
      <Cell rowspan="2" columnId="comment" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("comment")}</Label>
      </Cell>
    </Row>
    <Row>
      <Cell numeric columnId="d1Vol" class="datatable-header right-splitter-sub text-center no-border-radius">
        <Label>{$_("d1_short")}</Label>
      </Cell>
      <Cell numeric columnId="d2Vol" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("d2_short")}</Label>
      </Cell>
      <Cell numeric columnId="d3Vol" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("d3_short")}</Label>
      </Cell>
      <Cell numeric columnId="d4Vol" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("d4_short")}</Label>
      </Cell>
      <Cell numeric columnId="d5Vol" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("d5_short")}</Label>
      </Cell>
      <Cell numeric columnId="d6Vol" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("d6_short")}</Label>
      </Cell>
      <Cell numeric columnId="d7Vol" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("d7_short")}</Label>
      </Cell>
      <Cell numeric columnId="flexVol" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("flex")}</Label>
      </Cell>
      <Cell numeric columnId="fixVol" class="datatable-header right-splitter-sub text-center">
        <Label>{$_("fix")}</Label>
      </Cell>
      <Cell numeric columnId="totalVol" class="datatable-header right-splitter-sub text-center no-border-radius">
        <Label>{$_("total")}</Label>
      </Cell>
    </Row>
  </Head>
  <Body>
    {#if items.length == 0}
      <Row>
        <td colspan="20" class="mdc-data-table__cell" style="width: 100%">{$_("no_data")}</td>
      </Row>
    {:else}
      {#each availableCountries as country}
        {@const disableCountryBuying =
          items.filter((i) => i.country == country.country && i.rowType == "PURCHASE").filter((i) => filter.length == 0 || filter.includes(i.warehouseCode)).length > 0}
        {@const dcCount = items.filter((i) => i.country == country.country && i.rowType == "FORECAST").filter((i) => filter.length == 0 || filter.includes(i.warehouseCode)).length}
        {@const purchaseCount = items
          .filter((i) => i.country == country.country && i.rowType == "PURCHASE")
          .filter((i) => filter.length == 0 || filter.includes(i.warehouseCode)).length}
        {@const countryRowsCount = items.filter((i) => i.country == country.country).filter((i) => filter.length == 0 || filter.includes(i.warehouseCode)).length}
        {#each items
          .filter((i) => i.country == country.country)
          .filter((i) => filter.length == 0 || filter.includes(i.warehouseCode))
          .sort((a, b) => ([a.country[0].replace("C", "1").replace("S", "2").replace("H", "3"), a.warehouseCode, a?.supplierName?.toLowerCase() || ""].join("-") > [b.country[0]
                .replace("C", "1")
                .replace("S", "2")
                .replace("H", "3"), b.warehouseCode, b?.supplierName?.toLowerCase() || ""].join("-") ? 1 : -1)) as item (item.id)}
          {@const rowStyle = item.rowType == "PURCHASE" ? "purchase-table-ps-row" : "purchase-table-fc-row"}
          {@const originalVolTitle = item.rowType == "PURCHASE" ? "original_purchase_volume" : "original_forecast_volume"}
          {@const firstFCRow = items
            .filter((i) => i.country == country.country && i.rowType == "FORECAST")
            .filter((i) => filter.length == 0 || filter.includes(i.warehouseCode))
            .sort((a, b) =>
              [a.country[0].replace("C", "1").replace("S", "2").replace("H", "3"), a.warehouseCode, a?.supplierName?.toLowerCase() || ""].join("-") >
              [b.country[0].replace("C", "1").replace("S", "2").replace("H", "3"), b.warehouseCode, b?.supplierName?.toLowerCase() || ""].join("-")
                ? 1
                : -1,
            )
            .at(0)}
          {@const unconfirmedPurchases =
            items.filter((x) => x.rowType == "PURCHASE" && x.forecastId == item.forecastId).length == 0 ||
            (items.filter((x) => x.rowType == "PURCHASE" && x.forecastId == item.forecastId).length > 0 &&
              items.filter((x) => x.rowType == "PURCHASE" && x.forecastId == item.forecastId && ["prepared", "rejected"].includes(x.status)).length > 0)}
          {@const allPurchasesRejected =
            items.filter((x) => x.rowType == "PURCHASE" && x.forecastId == item.forecastId).length == 0 ||
            (items.filter((x) => x.rowType == "PURCHASE" && x.forecastId == item.forecastId).length > 0 &&
              items.filter((x) => x.rowType == "PURCHASE" && x.forecastId == item.forecastId && !["rejected"].includes(x.status)).length == 0)}
          {@const disableBuying =
            !["forecasted", "purchased"].includes($selectedWeek.status) ||
            !["new", "updated", "purchased", "purchased_less", "purchased_more", "rejected"].includes(item.status) ||
            !unconfirmedPurchases ||
            ($authenticatedUser.isBuyer && item?.canBuy == "N")}
          {@const disableReject =
            !["forecasted", "purchased"].includes($selectedWeek.status) ||
            !["new", "updated"].includes(item.status) ||
            items.filter((r) => r.rowType == "PURCHASE" && r.forecastId == item?.forecastId).length > 0 ||
            ($authenticatedUser.isBuyer && item?.canBuy == "N")}
          {@const forecastConfirmed =
            (item.rowType == "FORECAST" &&
              items.filter((r) => r.rowType == "PURCHASE" && r.forecastId == item?.forecastId).length > 0 &&
              items.filter((r) => r.rowType == "PURCHASE" && r.forecastId == item?.forecastId && !["confirmed", "rejected"].includes(r.status)).length == 0) ||
            ($authenticatedUser.isBuyer && item?.canBuy == "N")}

          {@const disablePurchaseActions =
            !["forecasted", "purchased"].includes($selectedWeek.status) || !["prepared"].includes(item.status) || ($authenticatedUser.isBuyer && item?.canBuy == "N")}
          {@const disablePurchaseReject =
            !["purchased"].includes($selectedWeek.status) || !["updated", "confirmed"].includes(item.status) || ($authenticatedUser.isBuyer && item?.canBuy == "N")}
          {@const disableUndoRejectPurchase =
            !["purchased"].includes($selectedWeek.status) || !["rejected"].includes(item.status) || ($authenticatedUser.isBuyer && item?.canBuy == "N")}
          <Row>
            {#if enableCountryBuying}
              {#if firstFCRow && firstFCRow.forecastId == item.forecastId}
                {#if item.rowType == "FORECAST" && countryRowsCount && dcCount > 1}
                  <Cell class="pl-0 pr-0 right-splitter-sub {rowStyle}" rowspan={dcCount > 1 ? countryRowsCount : 1}>
                    <div title={$_("buy_country")}>
                      <Button
                        class="small tescoblue-text ma-1 square-btn"
                        disabled={disableCountryBuying}
                        on:click={() => dispatch("newCountryPurchase", items.filter((i) => i.country == country.country).at(0))}
                        style="min-width: 2rem !important; height: 4rem;"
                      >
                        <Flex direction="column" class="">
                          <Icon tag="svg" viewBox="0 0 24 24" class="{disableCountryBuying ? 'grey-text' : 'tescoblue-text'} pa-0 ma-0">
                            <path fill="currentColor" d={mdiCartPlus} />
                          </Icon>
                          <div>{country.country}</div>
                        </Flex>
                      </Button>
                    </div>
                  </Cell>
                {:else if dcCount == 1}
                  <Cell class="pl-0 pr-0 {rowStyle}"></Cell>
                {/if}
              {:else}
                <!--          <Cell class="pl-0 pr-0 {rowStyle}">
            </Cell>-->
              {/if}
            {/if}
            <!-- enableCountryBuying -->
            <Cell class="pl-0 pr-0 {rowStyle}">
              {#if item.rowType == "FORECAST"}
                <Flex direction="row" justify="start" align="start">
                  <div title={$_("buy_dc")}>
                    <IconButton size="button" class="opacity-1" disabled={disableBuying} on:click={() => dispatch("newPurchase", item)}>
                      <Icon tag="svg" viewBox="0 0 24 24" class={disableBuying ? "grey-text" : "tescoblue-text"}>
                        <path fill="currentColor" d={mdiCartPlus} />
                      </Icon>
                    </IconButton>
                  </div>
                  {#if !forecastConfirmed}
                    <div title={$_("reject_forecast")}>
                      <IconButton size="button" class="opacity-1" disabled={disableReject} on:click={() => dispatch("rejectPurchase", item)}>
                        <Icon tag="svg" viewBox="0 0 24 24" class={disableReject ? "grey-text" : "tescoblue-text"}>
                          <path fill="currentColor" d={mdiCancel} />
                        </Icon>
                      </IconButton>
                    </div>
                  {:else}
                    <div title={$_("undo_confirmation")}>
                      <IconButton
                        size="button"
                        class="opacity-1"
                        disabled={allPurchasesRejected || ($authenticatedUser.isBuyer && item.canBuy == "N")}
                        on:click={() => dispatch("undoConfirmation", item)}
                      >
                        <Icon tag="svg" viewBox="0 0 24 24" class={allPurchasesRejected || ($authenticatedUser.isBuyer && item.canBuy == "N") ? "grey-text" : "tescoblue-text"}>
                          <path fill="currentColor" d={mdiArrowULeftTop} />
                        </Icon>
                      </IconButton>
                    </div>
                  {/if}
                  {#if item?.autobuyStatus}
                    <div title={item?.autobuyStatus == "OK" ? $_("autobuy_ok") : $_("autobuy_error", { values: { message: $_(item.autobuyDetail?.toLocaleLowerCase()) } })}>
                      <IconButton size="button" class="opacity-1" disabled>
                        <Icon tag="svg" viewBox="0 0 24 24" class={item?.autobuyStatus == "OK" ? "green-text" : "tescored-text"}>
                          <path fill="currentColor" d={item?.autobuyStatus == "OK" ? mdiCheckCircleOutline : mdiAlertCircle} />
                        </Icon>
                      </IconButton>
                    </div>
                  {/if}
                </Flex>
              {:else}
                <Flex direction="row" justify="start" align="start">
                  {#if ["forecasted"].includes($selectedWeek.status)}
                    <div title={$_("edit_purchase")}>
                      <IconButton size="button" class="opacity-1" disabled={disablePurchaseActions} on:click={() => dispatch("editPurchase", item)}>
                        <Icon tag="svg" viewBox="0 0 24 24" class={disablePurchaseActions ? "grey-text" : "tescoblue-text"}>
                          <path fill="currentColor" d={mdiPencilBoxOutline} />
                        </Icon>
                      </IconButton>
                    </div>
                    <div title={$_("delete")}>
                      <IconButton size="button" class="opacity-1" disabled={disablePurchaseActions} on:click={() => dispatch("deletePurchase", item)}>
                        <Icon tag="svg" viewBox="0 0 24 24" class={disablePurchaseActions ? "grey-text" : "tescoblue-text"}>
                          <path fill="currentColor" d={mdiDelete} />
                        </Icon>
                      </IconButton>
                    </div>
                  {/if}
                  {#if ["purchased"].includes($selectedWeek.status)}
                    {#if item.status != "rejected"}
                      <div title={$_("reject_purchase")}>
                        <IconButton size="button" class="opacity-1" disabled={disablePurchaseReject} on:click={() => dispatch("rejectSupplierPurchase", item)}>
                          <Icon tag="svg" viewBox="0 0 24 24" class={disablePurchaseReject ? "grey-text" : "tescoblue-text"}>
                            <path fill="currentColor" d={mdiCancel} />
                          </Icon>
                        </IconButton>
                      </div>
                    {:else}
                      <div title={$_("undo_reject_purchase")}>
                        <IconButton size="button" class="opacity-1" disabled={disableUndoRejectPurchase} on:click={() => dispatch("undoRejectSupplierPurchase", item)}>
                          <Icon tag="svg" viewBox="0 0 24 24" class={disableUndoRejectPurchase ? "grey-text" : "tescoblue-text"}>
                            <path fill="currentColor" d={mdiArrowULeftTop} />
                          </Icon>
                        </IconButton>
                      </div>
                    {/if}
                  {/if}
                </Flex>
              {/if}
            </Cell>
            <Cell class="{rowStyle} right-splitter-main">{item.warehouseCode}</Cell>
            <Cell class="{rowStyle} right-splitter-sub sticky-col ">{item.rowType == "FORECAST" ? "" : item.supplierName}</Cell>
            <Cell class="{rowStyle} right-splitter-sub">{item.rowType == "FORECAST" ? "" : item.cartonLogistics + " / " + item.palletLogistics}</Cell>
            <Cell class="{rowStyle} right-splitter right-splitter-sub text-center">
              {#if item.rowType != "FORECAST"}
                <span>{item.countryOfOrigin}</span>
                <br />
                <small>{item.variety || ""}</small>
              {/if}
            </Cell>
            <Cell numeric class="{rowStyle} right-splitter-sub {item.d1Promo ? 'promo-price' : ''}" title={item.d1Promo ? $_("promo_price") : ""}>
              {#if item.d1AmendVol && item.d1AmendVol != 0}
                {item.d1Vol + item.d1AmendVol} <br />
                <div class="grey-text">
                  <small
                    >(<span title={$_(originalVolTitle)}> {item.d1Vol ? item.d1Vol : ""}</span>
                    {#if item.d1AmendVol > 0}
                      &nbsp;+&nbsp;
                    {:else}
                      &nbsp;-&nbsp;
                    {/if}
                    <span title={$_("amendment_volume")}>{Math.abs(item.d1AmendVol)}</span>)</small
                  >
                </div>
              {:else}
                {item.d1Vol ? item.d1Vol : ""}
              {/if}
            </Cell>
            <Cell numeric class="{rowStyle} right-splitter-sub {item.d2Promo ? 'promo-price' : ''}" title={item.d2Promo ? $_("promo_price") : ""}>
              {#if item.d2AmendVol && item.d2AmendVol != 0}
                {item.d2Vol + item.d2AmendVol} <br />
                <div class="grey-text">
                  <small
                    >(<span title={$_(originalVolTitle)}> {item.d2Vol}</span>
                    {#if item.d2AmendVol > 0}
                      &nbsp;+&nbsp;
                    {:else}
                      &nbsp;-&nbsp;
                    {/if}
                    <span title={$_("amendment_volume")}>{Math.abs(item.d2AmendVol)}</span>)</small
                  >
                </div>
              {:else}
                {item.d2Vol ? item.d2Vol : ""}
              {/if}
            </Cell>
            <Cell numeric class="{rowStyle} right-splitter-sub {item.d3Promo ? 'promo-price' : ''}" title={item.d3Promo ? $_("promo_price") : ""}>
              {#if item.d3AmendVol && item.d3AmendVol != 0}
                {item.d3Vol + item.d3AmendVol} <br />
                <div class="grey-text">
                  <small
                    >(<span title={$_(originalVolTitle)}> {item.d3Vol}</span>
                    {#if item.d3AmendVol > 0}
                      &nbsp;+&nbsp;
                    {:else}
                      &nbsp;-&nbsp;
                    {/if}
                    <span title={$_("amendment_volume")}>{Math.abs(item.d3AmendVol)}</span>)</small
                  >
                </div>
              {:else}
                {item.d3Vol ? item.d3Vol : ""}
              {/if}
            </Cell>
            <Cell numeric class="{rowStyle} right-splitter-sub {item.d4Promo ? 'promo-price' : ''}" title={item.d4Promo ? $_("promo_price") : ""}>
              {#if item.d4AmendVol && item.d4AmendVol != 0}
                {item.d4Vol + item.d4AmendVol} <br />
                <div class="grey-text">
                  <small
                    >(<span title={$_(originalVolTitle)}> {item.d4Vol}</span>
                    {#if item.d4AmendVol > 0}
                      &nbsp;+&nbsp;
                    {:else}
                      &nbsp;-&nbsp;
                    {/if}
                    <span title={$_("amendment_volume")}>{Math.abs(item.d4AmendVol)}</span>)</small
                  >
                </div>
              {:else}
                {item.d4Vol ? item.d4Vol : ""}
              {/if}
            </Cell>
            <Cell numeric class="{rowStyle} right-splitter-sub {item.d5Promo ? 'promo-price' : ''}" title={item.d5Promo ? $_("promo_price") : ""}>
              {#if item.d5AmendVol && item.d5AmendVol != 0}
                {item.d5Vol + item.d5AmendVol} <br />
                <div class="grey-text">
                  <small
                    >(<span title={$_(originalVolTitle)}> {item.d5Vol}</span>
                    {#if item.d5AmendVol > 0}
                      &nbsp;+&nbsp;
                    {:else}
                      &nbsp;-&nbsp;
                    {/if}
                    <span title={$_("amendment_volume")}>{Math.abs(item.d5AmendVol)}</span>)</small
                  >
                </div>
              {:else}
                {item.d5Vol ? item.d5Vol : ""}
              {/if}
            </Cell>
            <Cell numeric class="{rowStyle} right-splitter-sub {item.d6Promo ? 'promo-price' : ''}" title={item.d6Promo ? $_("promo_price") : ""}>
              {#if item.d6AmendVol && item.d6AmendVol != 0}
                {item.d6Vol + item.d6AmendVol} <br />
                <div class="grey-text">
                  <small
                    >(<span title={$_(originalVolTitle)}> {item.d6Vol}</span>
                    {#if item.d6AmendVol > 0}
                      &nbsp;+&nbsp;
                    {:else}
                      &nbsp;-&nbsp;
                    {/if}
                    <span title={$_("amendment_volume")}>{Math.abs(item.d6AmendVol)}</span>)</small
                  >
                </div>
              {:else}
                {item.d6Vol ? item.d6Vol : ""}
              {/if}
            </Cell>
            <Cell numeric class="{rowStyle} right-splitter-sub {item.d7Promo ? 'promo-price' : ''}" title={item.d7Promo ? $_("promo_price") : ""}>
              {#if item.d7AmendVol && item.d7AmendVol != 0}
                {item.d7Vol + item.d7AmendVol} <br />
                <div class="grey-text">
                  <small
                    >(<span title={$_(originalVolTitle)}> {item.d7Vol}</span>
                    {#if item.d7AmendVol > 0}
                      &nbsp;+&nbsp;
                    {:else}
                      &nbsp;-&nbsp;
                    {/if}
                    <span title={$_("amendment_volume")}>{Math.abs(item.d7AmendVol)}</span>)</small
                  >
                </div>
              {:else}
                {item.d7Vol ? item.d7Vol : ""}
              {/if}
            </Cell>
            <Cell numeric class="{rowStyle} right-splitter-sub">{item.flexVol ? item.flexVol : ""}</Cell>
            <Cell numeric class="{rowStyle} right-splitter-sub">{item.fixVol ? item.fixVol : ""}</Cell>
            <Cell numeric class="{rowStyle} right-splitter-sub">
              {#if item.totalAmendVol && item.totalAmendVol != 0}
                {item.totalVol + item.totalAmendVol} <br />
                <div class="grey-text">
                  <small
                    >(<span title={$_(originalVolTitle)}> {item.totalVol}</span>
                    {#if item.totalAmendVol > 0}
                      &nbsp;+&nbsp;
                    {:else}
                      &nbsp;-&nbsp;
                    {/if}
                    <span title={$_("amendment_volume")}>{Math.abs(item.totalAmendVol)}</span>)</small
                  >
                </div>
              {:else}
                {item.totalVol ? item.totalVol : ""}
              {/if}
            </Cell>
            <Cell class="{rowStyle} right-splitter-sub text-center">{item.rowType == "FORECAST" ? "" : item.currency}</Cell>
            <Cell numeric class="{rowStyle} right-splitter-sub">
              {#if item.rowType != "FORECAST"}
                <span class="text-bold">{item.price}</span>
                <br />
                <div><small>{item.priceEur} EUR</small></div>
              {/if}
            </Cell>
            <Cell class="{rowStyle} right-splitter-sub">{$_(item.status)}</Cell>
            <Cell class="{rowStyle} right-splitter-sub">{item.reasonCode ? $_(item.reasonCode) : ""}</Cell>
            <Cell class={rowStyle}>{item.userComment ? item.userComment : ""}</Cell>
          </Row>
        {/each}
      {/each}
    {/if}
  </Body>
</DataTable>
