<script>
    import { onMount } from "svelte";

    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import { dcList } from "../../../stores/AppConfig";

    import { getSupplier as apiGetSupplier } from "../../../api/Supplier";
    import { getUserManagement as apiGetUserManagement } from "../../../api/User";

    import CountryFilter from "../common/CountryFilterBar.svelte";
    import MultiselectFilterBar from "../common/MultiselectFilterBar.svelte";

    import { snackbarError, authenticatedUser } from "../../../stores/AppStatus";

    export let countrySearchArray = [];
    export let dcSearchArray = [];
    export let supplierSearchArray = [];
    export let buyerSearchArray = [];

    let buyerList = [];
    let supplierList = [];
    let suppliersLoaded = false;
    let buyersLoaded = false;

    $: dcArray = $dcList
        .map((x) => {
            return { id: x.code, label: x.code + " - " + x.name };
        })
        .sort((a, b) => a.displayOrder - b.displayOrder);
    $: console.log({ dcArray });
    $: buyerArray = buyerList.map((x) => {
        return { id: x.id, label: x.label };
    });
    $: console.log({ buyerArray });
    $: supplierArray = supplierList.map((x) => {
        return { id: x.id, label: x.label };
    });
    $: console.log({ supplierArray });

    $: {
        console.log({ countrySearchArray });
    }
    $: {
        console.log({ dcSearchArray });
    }

    onMount(() => {
        suppliersLoaded = false;
        buyersLoaded = false;
        getUserList("Buyer");
        getSupplierList(null, null, null, null);
    });

    const getUserList = (role) => {
        console.log("================= getUserList ==============");
        buyersLoaded = false;
        apiGetUserManagement(null, role)
            .then((res) => {
                buyerList = res
                    .sort((a, b) => a.lastName.localeCompare(b.lastName))
                    .map((x) => {
                        return { id: x.email, label: x.lastName + " " + x.firstName, uuid: x.uuid };
                    });
                console.log({ buyerList });
                if ($authenticatedUser.isBuyer) {
                    console.log("User is buyer...");
                    buyerSearchArray.push([...buyerList].filter((l) => l.uuid == $authenticatedUser.uuid).at(0));
                }
                console.log("BuyerList reading finished");
                buyersLoaded = true;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                $snackbarError.text = $_("load_list_of_buyers_failed", { values: { reason: error.message } });
                $snackbarError.element && $snackbarError.element.open();
                buyerList = [];
            });
    };

    const getSupplierList = (id, rmsSupplierName, status, rmsSupplierNo) => {
        console.log("====================== getSupplierList =========================");
        suppliersLoaded = false;
        apiGetSupplier(id, rmsSupplierName, status)
            .then((res) => {
                supplierList = res
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((x) => {
                        return { id: x.id, label: x.id + " - " + x.name };
                    });
                console.log({ supplierList });
                suppliersLoaded = true;
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                $snackbarError.text = $_("load_list_of_suppliers_failed", { values: { reason: error.message } });
                $snackbarError.element && $snackbarError.element.open();
                supplierList = [];
            });
    };
</script>

<Flex direction="row" justify="start" align="center" class="flex-wrap gap-05 w-100 gray lighten-4 h-100">
    <CountryFilter bind:selection={countrySearchArray} title={$_("country")} size="10" multiselect />

    <MultiselectFilterBar title={$_("dc")} valuesArray={dcArray} bind:selection={dcSearchArray} size="15" sortValues={false} />

    <MultiselectFilterBar title={$_("supplier")} valuesArray={supplierArray} loaded={suppliersLoaded} bind:selection={supplierSearchArray} size="15" sortValues={false} />

    <MultiselectFilterBar title={$_("buyer")} valuesArray={buyerArray} loaded={buyersLoaded} bind:selection={buyerSearchArray} size="15" sortValues={false} />
</Flex>
