import Api from "../services/Api";

// Method to get current List of AmendmentCountry
export const getAmendmentCountry = async (id, forecastId, year, week, country, warehouseId, productId, productDetailId, tpnb, deliveryDate, fileName) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);
    if (forecastId) params.append('forecastId', forecastId);
    if (year) params.append('year', year);
    if (week) params.append('week', week);
    if (country) params.append('country', country);
    if (warehouseId) params.append('warehouseId', warehouseId);
    if (productId) params.append('tpnEn', productId);
    if (productDetailId) params.append('productDetailId', productDetailId);
    if (tpnb) params.append('tpnb', tpnb);
    if (deliveryDate) params.append('deliveryDate', deliveryDate);
    if (fileName) params.append('fileName', fileName);

    console.log('getAmendmentCountry params:', params.toString());

    const response = await Api.get("/amendment/country", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Method to get current List of AmendmentCountry Products
export const getAmendmentCountryProduct = async (productId, forecastId, year, week, country, warehouseId, tpnb, purchasedOnly) => {
  try {
    const params = new URLSearchParams();
    if (productId) params.append('productId', productId);
    if (forecastId) params.append('forecastId', forecastId);
    if (year) params.append('year', year);
    if (week) params.append('week', week);
    if (country) params.append('country', country);
    if (warehouseId) params.append('warehouseId', warehouseId);
    if (tpnb) params.append('tpnb', tpnb);
    if (purchasedOnly) params.append('purchasedOnly', purchasedOnly);

    console.log('getAmendmentCountryProduct params:', params.toString());

    const response = await Api.get("/amendment/country/product", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Method to get a List of Country Amend Changes (history)
export const getSupplierConfirmedVolume = async (id) => {
  try {
    const params = new URLSearchParams();
    if (id) params.append('id', id);

    console.log('getSupplierConfirmedVolume params:', params.toString());

    const response = await Api.get("/amendment/country/audit", params);
    console.log({ response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    console.error(error.response.data.payload);
    throw new Error(error.message, { cause: error });
  }
};

// Create new AmendmentCountry
export const createAmendmentCountry = async (data) => {
  try {
    let url = Array.isArray(data) ? "/amendment/country/array" : "/amendment/country";

    const response = await Api.post(url, data);
    console.log("createAmendmentCountry", { response });
    if (response.status) {
      if (data.idXLS && data.idXLS != null) {
        response.payload[0].idXLS = data.idXLS;
      }
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Update AmendmentCountry data
export const updateAmendmentCountry = async (data) => {
  try {
    const params = new URLSearchParams();
    params.append('id', data?.id);

    const response = await Api.put("/amendment/country", params, data);
    //console.log({response});
    if (response.status) {
      return response.payload;
    } else {
      console.log(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

// Delete AmendmentCountry
export const deleteAmendmentCountry = async (id) => {
  try {
    const params = new URLSearchParams();
    params.append('id', id);

    const response = await Api.delete("/amendment/country", params);
    console.log({ response });

    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.error(error);
    throw new Error(error.message, { cause: error });
  }
};

// Delete AmendmentCountry (multi)
export const deleteAmendmentCountrys = async (ids) => {
  try {
    const response = await Api.post("/amendment/country/arrayDelete", ids);

    console.log({ response });

    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.error(error);
    throw new Error(error.message, { cause: error });
  }
};



export const amendmentCountryItemsExist = async (data) => {
  console.log("amendmentCountryItemsExist input", { data })
  try {
    const response = await Api.post("/amendment/country/exist", data);
    console.log("amendmentCountryItemsExist response: ", { response });
    if (response.status) {
      return response.payload;
    } else {
      console.error(response.payload ? response.payload : response.message);
      throw new Error(response.payload ? response.payload : response.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error.message, { cause: error });
  }
};

