<script lang="ts">
    import { onMount, createEventDispatcher } from "svelte";
    import { writable } from "svelte/store";
    import Paper from "@smui/paper";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import { handleApiError } from "../lib/errorHandler";

    import {
        getAmendmentCountry as apiGetAmendmentCountry,
        createAmendmentCountry as apiCreateAmendmentCountry,
        updateAmendmentCountry as apiUpdateAmendmentCountry,
        deleteAmendmentCountry as apiDeleteAmendmentCountry,
        deleteAmendmentCountrys as apiDeleteAmendmentCountrys,
        getSupplierConfirmedVolume as apiSupplierConfirmedVolume,
    } from "../../../api/AmendmentCountry";
    import { nextRoundSupplierAmend as apiNextRoundSupplierAmend } from "../../../api/AmendmentsSupplier";
    import { isActionEnabled } from "../../modules/lib/Functions";
    import WeekInfo from "../common/WeekInfo.svelte";
    import FilesUploadDialog from "../common/FilesUploadDialog.svelte";
    import GetDataButton from "../common/GetDataButton.svelte";
    import AddItemButton from "../common/AddItemButton.svelte";
    import UploadButton from "../common/UploadButton.svelte";
    import ClearACButton from "../common/ClearButton.svelte";
    import SaveACButton from "../CountryAmendments/AmSaveButton.svelte";
    import ACFilterBar from "../CountryAmendments/AmFilterBar.svelte";
    import ACDataTable from "../CountryAmendments/AmDataTable.svelte";
    import { snackbarSuccess, snackbarWarning, selectedWeek, selectedModule, supplierAmendments, authenticatedUser } from "../../../stores/AppStatus";
    import { dcList } from "../../../stores/AppConfig";
    import AssignQtySuppButton from "./AssignQtySuppButton.svelte";
    import CaHistoryDialog from "./CaHistoryDialog.svelte";

    // Upload data
    import { uploadFiles } from "../../modules/Upload/Upload";
    import { validateUploadedACData, duplicityCheckUploadedACData } from "../Upload/ValidateAmendmentCountry";

    // Add item dialog
    import ConfirmationDialog from "../../dialogs/ConfirmationDialog.svelte";
    import AcItemDetail from "../CountryAmendments/AmDetailDialog.svelte";
    import FullscreenLoader from "../../elementary/FullscreenLoader.svelte";
    import { getSupplierAuditChanges } from "../../../api/AmendmentsSupplier";

    import type { AmendmentCountryItems } from "../../../types/AmendmentCountry";
    import type { SupplierAM } from "../../../types/Amendments";

    export let showUploadDialog = false;
    let selectedDC = [];

    // Search
    let fileNameSearch = "";
    let tpnbSearch = "";
    let ceTpnSearch = "";
    let tpnbDescSearch = "";
    let ceDescSearch = "";
    let statusSearchArray = [];
    let statusErrors = writable([]);
    let statusSearchErrorsArray = [];

    let acScreenMode = "edit"; // Possible values = ['edit', 'upload']
    let acDialogOpen = false;
    let acDialogType = "edit";
    let acItems: AmendmentCountryItems[] = [];
    let asItems: SupplierAM[] = [];
    let item: AmendmentCountryItems;
    let filteredItems: AmendmentCountryItems[] = [];
    let selectedItems = [];
    let confirmationOpen;
    let confirmationText;
    let confirmationAction;
    let confirmDialogTitle;
    let confirmDialogText;
    let confirmDialogType;
    let confirmDialogValueNotAssignable;
    let confirmDialogValueAssignable;
    let confirmDialogData = [];
    let confirmDialogOpen = false;

    let deleteId = "";

    let amendmentCountryDeleteConfirmationOpen = false;

    // File file upload
    let filesUploadDialogOpen = false;
    let filesUploadState;
    let filesStatus = [];
    let filesUploadCount = 0;
    let dataLoaded = false;
    let loadingData = false;
    let unsavedRowsExists = false;
    let rowsValid = false;
    let dataValid = false;

    // Loading...
    let apiCallProcessing = false;
    let apiCallProcessingMessage = "";

    // History / Audit
    let showSADialog = false;
    let loadingHistoryData = false;
    let itemHistoryData = [];

    // Screen action permisssions
    let canInsert = false;
    let canUpdate = false;
    let canDelete = false;

    const dispatch = createEventDispatcher();

    $: rowsValid = acItems && Array.isArray(acItems) && [...acItems].filter((x) => x.errors && x.errors.length > 0).length == 0;
    $: uploadFinishedCount = filesStatus ? filesStatus.filter((x) => ["done", "error", "rejected"].includes(x.uploadStatus)).length : 0;
    $: validationFinishedCount = filesStatus ? filesStatus.filter((x) => ["done", "error", "rejected"].includes(x.validationStatus)).length : 0;
    $: console.log({ selectedDC });

    // create a list of errors while upload mode
    $: if (acScreenMode === "upload") {
        // All errors and warnings
        const allErrorsAndWarnings = acItems.flatMap((item) => [
            ...(item.errors ? item.errors.filter((error) => error !== null && error !== undefined) : []),
            ...(item.warnings ? item.warnings.filter((warning) => warning !== null && warning !== undefined) : []),
        ]);

        const uniqueErrors = Array.from(new Map(allErrorsAndWarnings.map((errorOrWarning) => [errorOrWarning.message, errorOrWarning])).values());

        statusErrors.set(uniqueErrors);
    } else {
        statusErrors.set([]); // Clear statusErrors if not in 'upload' mode
    }

    function performAction(action) {
        console.log("================= performAction ==============");
        console.log({ action });
        console.log({ selectedItems });
        if (selectedItems.length == 0) {
            confirmationAction = action;
            confirmationText = "no_rows_selected_peform_action_on_all_applicable_rows";
            confirmationOpen = true;
        } else {
            switch (action) {
                case "assignQty":
                    console.log("Confirmed Assign Qty All selected rows");
                    batchAssignQty(selectedItems);
                    break;
                default:
                    console.log("Unknown action: " + action);
            }
        }
    }

    function batchActionConfirmed(action) {
        console.log("================= batchActionConfirmed ==============");
        console.log({ action });
        console.log({ filteredItems });
        switch (action) {
            case "assignQty":
                console.log("Confirmed Assign Qty for All filtered rows");
                batchAssignQty(filteredItems);
                break;
            default:
                console.log("Unknown action: " + action);
        }
    }

    function batchAssignQty(data) {
        console.log("================= batchAssignQty ==============");
        console.log({ data });
        let assignableRows = [...data].filter((i) => ["new"].includes(i.status) && i.amendVol && i.amendVol > 0);
        console.log({ assignableRows });
        if (assignableRows.length > 0) {
            if (assignableRows.length == data.length) {
                // All rows can be Assigned
                confirmDialogTitle = "assign_all_rows";
                confirmDialogText = "do_yo_want_to_assign_all_rows";
                confirmDialogType = "all";
                confirmDialogValueNotAssignable = null;
            } else {
                // Not all rows can be Assigned
                confirmDialogTitle = "partially_confirm_rows";
                confirmDialogText = "some_rows_cant_be_assigned_do_you_want_assign_anyway";
                confirmDialogType = "notAll";
                confirmDialogValueNotAssignable = data.length;
            }
            confirmDialogValueAssignable = assignableRows.length;
            confirmDialogData = assignableRows;
            confirmDialogOpen = true;
        } else {
            // No rows can be confirmed
            $snackbarWarning.text = $_("no_rows_can_be_assigned_to_supplier");
            $snackbarWarning.element && $snackbarWarning.element.open();
        }
    }

    const assignQty = (data) => {
        console.log("================= assignQty ==============");
        console.log({ data });
        $supplierAmendments = [];
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("updating_please_wait");
        let failedCount = 0;
        asItems = [];
        apiNextRoundSupplierAmend(data.map((e) => e.id))
            .then((response) => {
                console.log({ response });
                [...data].forEach((r) => {
                    var acRow = [...acItems].filter((i) => i.id == r.id)[0];
                    var asRow = [...response].filter((i) => i.amendCountryId == r.id)[0];
                    console.log({ acRow });
                    console.log({ asRow });
                    if (asRow.id != null && asRow.id > 0) {
                        acRow.status = "in_progress";
                        asItems.push(asRow);
                    } else {
                        failedCount++;
                        acRow.status = "new";
                        acRow.errorMessage = asRow.errorMessage;
                        acRow.errors.push({ message: asRow.errorMessage, field: "n/a", type: "E", priority: 1 });
                    }
                });
                console.log("DONE: ", asItems);
                acItems = acItems;
                apiCallProcessing = false;
                if (failedCount == 0) {
                    $snackbarSuccess.text = $_("amendments_successfully_assigned");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                    $supplierAmendments = asItems;
                    console.log({ $supplierAmendments });
                    $selectedModule = "suppliers";
                } else {
                    $snackbarWarning.text = $_("amendments_assigned_with_errors");
                    $snackbarWarning.element && $snackbarWarning.element.open();
                }
            })
            .catch((error) => {
                console.log({ error });
                apiCallProcessing = false;
                if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            });
    };

    // Find warehouse name
    function findWarehouseName(warehouseId) {
        console.log("==============     findWarehouseName     =============");
        const warehouse = $dcList.find((dc) => dc.id === warehouseId);
        return warehouse ? warehouse.name : "";
    }

    const getACData = async () => {
        console.log("==============     getACData     =============");
        try {
            loadingData = true;
            acScreenMode = "edit";
            const res = await apiGetAmendmentCountry(null, null, $selectedWeek.tesco_year, $selectedWeek.tesco_week);
            console.log({ res });
            res.forEach((x) => {
                x.warehouseCode = $dcList.filter((w) => w.id == x.warehouseId).at(0)?.code;
                x.countryOrder = x.country.at(0).replace("C", "1").replace("S", "2").replace("H", "4");
            });
            acItems = [
                ...res.sort((a, b) =>
                    [a.productDescription, a.countryOrder, a.warehouseCode, a.deliveryDate].join("#").toLowerCase() >
                    [b.productDescription, b.countryOrder, b.warehouseCode, b.deliveryDate].join("#").toLowerCase()
                        ? 1
                        : -1,
                ),
            ];
            if (acItems.length == 0) {
                dataValid = true;
                console.log("getACData().empty");
            } else {
                dataValid = false;
                acItems.forEach((i) => {
                    i.errors = [];
                    i.warnings = [];
                });
                acItems = acItems;
                console.log({ acItems });
            }
            dispatch("getDataRequest");
            dataLoaded = true;
            loadingData = false;
        } catch (error) {
            console.log("getACData().error");
            console.log(error);
            loadingData = false;
            if (!handleApiError($_("get_data_failed"), error, "non-fatal")) throw error;
        }
    };

    const uploadProgress = (fileName, status, message) => {
        console.log("==============     uploadProgress     =============");
        if (fileName) {
            filesStatus.forEach((x) => {
                if (x.fileName == fileName) {
                    x.uploadStatus = status;
                    if (message) {
                        x.errorMessage = message;
                    }
                }
            });
        }
        console.log({ filesStatus });
        filesStatus = filesStatus;
    };

    const validationProgress = (fileName, status, message) => {
        console.log("==============     validationProgress     =============");
        if (fileName) {
            filesStatus.forEach((x) => {
                if (x.fileName == fileName) {
                    x.validationStatus = status;
                    if (message) {
                        x.errorMessage = message;
                    }
                }
            });
        }
        console.log({ filesStatus });
        filesStatus = filesStatus;
    };

    const validateFiles = async (files, validationProgressCallBack) => {
        console.log("==================================================");
        console.log("==============     validateFiles     =============");
        console.log("==================================================");
        console.log({ files });

        if (files && files != null && files.length > 0) {
            filesUploadCount = files.length;
            const filePromises = [...files].map((f) => {
                console.log({ f });
                validationProgressCallBack(f.fileName, "processing");

                return new Promise(async (resolve, reject) => {
                    try {
                        console.log({ f });
                        if (f.errors && f.errors.size > 0) {
                            validationProgressCallBack(f.fileName, "rejected");
                            let ret = [{ fileName: [f.fileName], errors: f.errors }];
                            console.log({ ret });
                            resolve(ret);
                        } else {
                            let acData = [...f.items.flatMap((x) => x)];
                            console.log([...acData]);
                            let ret = validateUploadedACData(acData, $selectedWeek);

                            if (ret) {
                                console.log("Validation finished");
                                console.log({ ret });
                                resolve(ret);
                            } else {
                                console.log("Throw: file_validation_failed");
                                validationProgressCallBack(f.fileName, "error");
                                resolve(ret);
                            }
                        }
                    } catch (error) {
                        console.log({ error });
                        validationProgressCallBack(f.fileName, "error");
                        reject(error);
                    }
                });
            });

            async function allProgress(proms, progressCallBack) {
                // console.log("callback");
                // console.log({proms});
                let d = 0;
                progressCallBack(0);
                for (const p of proms) {
                    p.then((x) => {
                        d++;
                        if (x.at(0)?.tpnb) {
                            progressCallBack(x.at(0).fileName, "done");
                        } else {
                            progressCallBack(x.at(0).fileName, "rejected");
                        }
                    }).catch((x) => {
                        d++;
                        console.log("CATCH");
                        console.log({ x });
                        progressCallBack(x.at(0).fileName, "error");
                    });
                }
                console.log({ proms });
                return Promise.all(proms);
            }

            const responses = await allProgress(filePromises, validationProgressCallBack);

            // // Wait for all promises to be resolved

            console.log({ responses });
            return responses;
        }
    };

    const uploadACData = async (files) => {
        console.log("================= uploadACData ==============");
        try {
            filesUploadDialogOpen = true;
            filesUploadState = "started";
            filesStatus = [];
            acScreenMode = "upload";
            acItems = [];

            for (let f = 0; f < files.length; f++) {
                filesStatus.push({ fileName: files.item(f).name, uploadStatus: "waiting", validationStatus: "waiting" });
            }
            filesStatus = filesStatus;
            console.log({ filesStatus });

            const results = await uploadFiles(files, "AC", uploadProgress);
            console.log("Uploaded result: ", { results });
            if (results && filesUploadState == "started") {
                try {
                    const validationResults = await validateFiles(results, validationProgress);
                    console.log({ validationResults });
                    if (validationResults) {
                        for (let fileRows of validationResults) {
                            console.log({ fileRows });
                            if (fileRows && fileRows.at(0)?.productId) {
                                for (let itemRow of fileRows) {
                                    acItems.push(itemRow);
                                }
                            }
                        }
                        if (filesUploadState == "started") {
                            console.log("In UploadACData before duplicity check");
                            console.log({ acItems });
                            await duplicityCheckUploadedACData(acItems);
                            acItems = acItems;
                        } else {
                            acItems = [];
                        }
                    }
                } catch (error) {
                    console.log("Validation Fails!", { error });
                }
            }

            if (filesUploadState == "started") {
                filesUploadState = "finished";
                console.log("Uploading XLS data finished");
                unsavedRowsExists = true;
                dataLoaded = true;
            } else {
                // cancell requested
                filesUploadDialogOpen = false;
                filesUploadState = "cancelled";
            }
        } catch (error) {
            filesUploadDialogOpen = false;
            console.log({ error });
            if (!handleApiError($_(error.message + " - " + error.cause, { values: { reason: error.message } }), error, "non-fatal")) throw error;
        }
    };

    const clearAllData = () => {
        console.log("================= clearAllData ==============");
        acItems = [];
        acScreenMode = "edit";
        filesUploadDialogOpen = false;
        dataLoaded = false;
        unsavedRowsExists = false;
    };

    const editAmendmentCountry = (mode, data) => {
        console.log("================= editAmendmentCountry ==============");
        acDialogOpen = true;
        acDialogType = "edit";
        acScreenMode = mode;
        item = resetAmendmentCountryItem();
        if (!data.errors && mode === "edit") {
            data = { ...data, errors: [] };
        }
        item = { ...data };
    };

    function resetAmendmentCountryItem(): AmendmentCountryItems {
        console.log("==============     resetAmendmentCountryItem     =============");
        return {
            id: null,
            year: $selectedWeek.tesco_year,
            week: $selectedWeek.tesco_week,
            forecastId: null,
            country: "",
            warehouseId: null,
            productId: null,
            productDetailId: null,
            productDescription: "",
            tpnb: "",
            tpnbDescription: "",
            deliveryDate: null,
            units: "",
            confirmedVol: 0,
            originalVol: 0,
            amendVol: 0,
            status: "new",
            countryComment: "",
            userComment: "",
            fileName: "",
            errors: [],
            warnings: [],
            errorMessage: null,
            selected: false,
        };
    }

    const deleteAc = async (mode, ids) => {
        console.log("================= deleteAC ==============");
        console.log({ ids });
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("processing_wait");
        if (Array.isArray(ids) && ids.length > 0) {
            if (mode === "edit") {
                // Attempt delete each id in array
                console.log("Array edit Bulk deletion");

                // Extract ids
                console.log("idsToDelete", ids);
                try {
                    const deletedIds = await apiDeleteAmendmentCountrys(ids);
                    console.log("Deleted IDs:", deletedIds);

                    // Removing successfully deleted IDs
                    acItems = acItems.filter((item) => !deletedIds.includes(item.id));

                    // Show success message
                    $snackbarSuccess.text = $_("country_amendment_detail_deleted");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                    apiCallProcessing = false;
                } catch (error) {
                    apiCallProcessing = false;
                    console.error("Bulk deletion error", error);
                    // Show error message
                    if (!handleApiError($_("delete_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                }
            } else {
                console.log("Array upload deletion");
                // Filter acItems correctly
                acItems = acItems.filter((item) => !ids.includes(item.id));
                amendmentCountryDeleteConfirmationOpen = false;
                if (acItems.length === 0) {
                    unsavedRowsExists = false;
                    acScreenMode = "edit";
                } else {
                    // Check for duplicity after deletion
                    await duplicityCheckUploadedACData(acItems);
                }
                apiCallProcessing = false;
            }
        } else {
            if (mode === "edit" && ids) {
                try {
                    await apiDeleteAmendmentCountry(ids);
                    console.log("DB Deletion success of single row");

                    // Show success message
                    $snackbarSuccess.text = $_("product_detail_deleted");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                    apiCallProcessing = false;
                } catch (error) {
                    console.log({ error });
                    apiCallProcessing = false;

                    // Show error message
                    if (!handleApiError($_("delete_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                }
            } else {
                console.log("Single upload, not in DB");
            }

            // Remove the deleted item from acItems
            acItems = acItems.filter((item) => item.id !== ids);

            // Perform duplicity check after deletion
            await duplicityCheckUploadedACData(acItems);

            acItems = acItems;

            if (acItems.length === 0) {
                unsavedRowsExists = false;
                acScreenMode = "edit";
            }

            amendmentCountryDeleteConfirmationOpen = false;
            apiCallProcessing = false;
        }
    };

    const saveAmendmentCountryRow = (type, mode, data) => {
        console.log("================= saveAmendmentCountryRow ==============");
        console.log({ type });
        console.log({ mode });
        console.log({ data });
        if (type == "add") {
            // Add New AmendmentCountry
            if (mode == "edit" || mode == "saveAll") {
                apiCallProcessing = true;
                apiCallProcessingMessage = $_("processing_wait");
                apiCreateAmendmentCountry(data)
                    .then((res) => {
                        console.log({ res });
                        // When single row was created copy new id and add new row to table
                        if (mode == "edit") {
                            data.id = res[0].id;
                            acItems.push(data);
                            acItems = acItems;
                            $snackbarSuccess.text = $_("amendment_country_created");
                            $snackbarSuccess.element && $snackbarSuccess.element.open();
                        } else {
                            // When row from XLS upload was created update XLS row id with new row id
                            acItems.filter((x) => x.id == data.idXLS)[0].id = res[0].id;
                        }
                        apiCallProcessing = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        apiCallProcessing = false;
                        if (!handleApiError($_("create_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                    });
            } else {
                acItems.push(data);
                acItems = acItems;
            }
        } else {
            // Update existing AmendmentCountry
            acItems.forEach((x) => {
                if (x.id == data.id) {
                    x.id = data.id;
                    x.week = data.week;
                    x.year = data.year;
                    x.forecastId = data.forecastId;
                    x.country = data.country;
                    x.warehouseId = data.warehouseId;
                    x.productId = data.productId;
                    x.productDetailId = data.productDetailId;
                    x.productDescription = data.productDescription;
                    x.tpnb = data.tpnb;
                    x.deliveryDate = data.deliveryDate;
                    x.units = data.units;
                    x.amendVol = data.amendVol;
                    x.originalVol = data.originalVol;
                    x.status = data.status;
                    x.userComment = data.userComment;
                    x.countryComment = data.countryComment;
                    x.fileName = data.fileName;
                    x.errors = [...data.errors];
                }
            });
            acItems = acItems;

            if (mode == "edit" || mode == "saveAll") {
                apiCallProcessing = true;
                apiCallProcessingMessage = $_("processing_wait");
                apiUpdateAmendmentCountry(data)
                    .then((res) => {
                        console.log({ res });
                        if (mode == "edit") {
                            $snackbarSuccess.text = $_("amendment_country_detail_updated");
                            $snackbarSuccess.element && $snackbarSuccess.element.open();
                            acDialogOpen = false;
                        }
                        apiCallProcessing = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        apiCallProcessing = false;
                        if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                    });
            } else {
                acDialogOpen = false;
            }
        }
    };

    const saveAllACData = (data) => {
        console.log("================= saveAllACData ==============");
        console.log({ data });

        apiCallProcessing = true;
        apiCallProcessingMessage = $_("processing_wait");

        // Set all id to null and status to "new"
        const preparedData = data.map((item) => ({ ...item, id: null, status: "new" }));
        //const preparedData = data.map((item) => ({ ...item, id: null}));

        apiCreateAmendmentCountry(preparedData)
            .then((res) => {
                console.log({ res });

                // Restore the id/status if res did not provide the id
                const restoredRes = res.map((item, index) => {
                    if (item.id === null && data[index] && data[index].id !== null) {
                        return { ...item, id: data[index].id, status: data[index].status };
                    }
                    return item;
                });

                // Check for errors or warnings in response
                const itemsWithIssues = restoredRes.filter((item) => (item.errors && item.errors.length > 0) || (item.warnings && item.warnings.length > 0));

                if (itemsWithIssues.length > 0) {
                    // Show just items with some issues
                    acItems = itemsWithIssues;
                    $snackbarWarning.text = $_("create_failed", { values: { reason: "Data where already present, please check" } });
                    $snackbarWarning.element && $snackbarWarning.element.open();
                } else {
                    // Else show all processed rows
                    acItems = restoredRes;
                    $snackbarSuccess.text = $_("amendment_country_created");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                    unsavedRowsExists = false;
                    acScreenMode = "edit";
                }
                apiCallProcessing = false;
            })
            .catch((error) => {
                apiCallProcessing = false;
                console.log(error);
                if (!handleApiError($_("create_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
            });
    };

    const getSAHistory = async (id) => {
        console.log("getCAHistory");
        try {
            showSADialog = true;
            loadingHistoryData = true;
            const res = await apiSupplierConfirmedVolume(id);

            itemHistoryData = [...res];
            console.log("getCAHistory().success", { itemHistoryData });
            loadingHistoryData = false;
        } catch (error) {
            console.log("getCAHistory().error", { error });
            loadingHistoryData = false;
            handleApiError("get_ca_history", error, true);
        }
    };

    const handleEvents = async (event) => {
        console.log("================= handleEvents ==============");
        console.log({ event });
        switch (event.type) {
            case "acDialogCancel":
                console.log("Event Cancel AmendmentCountry");
                acDialogOpen = false;
                break;
            case "AmendmentCountryDialogSave":
                console.log("Event Save AmendmentCountry Row");
                acDialogOpen = false;
                // Update Product Detail table on screen
                acItems.filter((x) => x.id == event.detail.item.id)[0] = event.detail.item;
                saveAmendmentCountryRow(event.detail.type, acScreenMode, event.detail.item);
                break;
            case "history":
                console.log("Show item row history id: ", event.detail);
                const detailId = event.detail;
                getSAHistory(detailId);
                break;
            case "editAmendmentCountryRow":
                console.log("Event Edit AmendmentCountry Row");
                editAmendmentCountry(acScreenMode, event.detail);
                break;
            case "deleteAmendmentCountryRow":
                console.log("Event Delete AmendmentCountry Row");
                deleteId = null;
                deleteId = event.detail;
                console.log(deleteId);
                amendmentCountryDeleteConfirmationOpen = true;
                break;
            case "batchDeleteAmendmentCountryRows":
                console.log("Event Delete multiple AmendmentCountry Rows");
                console.log({ selectedItems });
                if (selectedItems.length == 0) {
                    $snackbarWarning.text = $_("no_rows_are_selected");
                    $snackbarWarning.element && $snackbarWarning.element.open();
                } else {
                    deleteId = null;
                    deleteId = [...selectedItems].map((x) => x.id);
                    console.log({ deleteId });
                    amendmentCountryDeleteConfirmationOpen = true;
                }
                break;
            case "openFileDialogConfirmed":
                console.log("Event Import AmendmentCountry");
                uploadACData(event.detail);
                break;
            case "saveAllData":
                console.log("Event Save All Imported AmendmentCountry");
                saveAllACData(acItems);
                break;
            case "clearAllData":
                console.log("Event Clear All Imported AmendmentCountry");
                clearAllData();
                break;
            default:
                console.log("Unknown event: " + event.type);
        }
    };

    const weekDataInvalidated = () => {
        console.log("==============     weekDataInvalidated     =============");
        dataLoaded = false;
        acItems = [];
        unsavedRowsExists = false;
        updatePrivileges();
    };

    onMount(() => {
        updatePrivileges();
    });

    const updatePrivileges = () => {
        console.log("==============     updatePrivileges     =============");
        if ($selectedWeek && $selectedWeek.status) {
            canInsert = isActionEnabled("amendments", "insert", $selectedWeek.status);
            canUpdate = isActionEnabled("amendments", "edit", $selectedWeek.status);
            canDelete = isActionEnabled("amendments", "delete", $selectedWeek.status);
            dataValid = false;
        } else {
            canInsert = canUpdate = canDelete = false;
        }
    };
</script>

<section class="main-data-view">
    <section class="w-100 header tescoblue-border-bottom-thin top-splitter">
        <Flex direction="row" class="grey lighten-3 tescoblue-text bs-bb pr-4 w-100 gap-1">
            <WeekInfo disabled={acScreenMode == "upload"} on:weekChanged={weekDataInvalidated} on:weekStatusChanged={updatePrivileges} />
            <Flex direction="row" class="w-100">
                {#if acScreenMode != "upload"}
                    <GetDataButton approvalNeeded={unsavedRowsExists} approvalMessage={unsavedRowsExists ? $_("discard_unsaved_changes") : ""} on:get={getACData} />
                {/if}
                <Flex direction="row" justify="end" class="w-100 gap-1">
                    {#if acScreenMode != "upload"}
                        <AddItemButton
                            disabled={!canInsert}
                            hint={dataLoaded ? "" : $_("load_week_data_first")}
                            on:addItemBtnClicked={() => {
                                console.log("on:addItemBtnClicked");
                                item = resetAmendmentCountryItem();
                                acDialogType = "add";
                                acDialogOpen = true;
                            }}
                        />

                        <UploadButton bind:dialogOpen={showUploadDialog} disabled={!canInsert} on:openFileDialogConfirmed={handleEvents} />
                        {#if $authenticatedUser.isAdmin}
                            <AssignQtySuppButton
                                disabled={!canUpdate || filteredItems.length == 0}
                                on:assignQtyButtonClick={() => {
                                    performAction("assignQty");
                                }}
                            />
                        {/if}
                    {/if}
                    {#if acScreenMode == "upload"}
                        <ClearACButton title={$_("amendment_country")} on:clearAllData={handleEvents} />
                        <SaveACButton dataValidFlag={acScreenMode != "upload" || (acScreenMode == "upload" && rowsValid)} on:saveAllData={handleEvents} />
                    {/if}
                </Flex>
            </Flex>
        </Flex>
    </section>

    <section class="filters">
        <Paper class="grey lighten-3 w-100 bs-bb pb-1 pt-1">
            <div class="smui-paper__content bs-bb">
                <section class="w-100">
                    <ACFilterBar
                        bind:ceTpnSearch
                        bind:ceDescSearch
                        bind:tpnbSearch
                        bind:tpnbDescSearch
                        bind:selectedDC
                        bind:statusSearchArray
                        bind:fileNameSearch
                        bind:statusSearchErrorsArray
                        {acScreenMode}
                        {statusErrors}
                    />
                </section>
            </div>
        </Paper>
    </section>

    <section class="tescoblue-border-top-thin">
        <Paper class="grey lighten-3 w-100 bs-bb pt-3">
            <div class="smui-paper__content bs-bb table-container">
                <ACDataTable
                    items={acItems}
                    {loadingData}
                    {ceTpnSearch}
                    productDescEnSearch={ceDescSearch}
                    {tpnbSearch}
                    {tpnbDescSearch}
                    {statusSearchErrorsArray}
                    {fileNameSearch}
                    {canUpdate}
                    {canDelete}
                    bind:dataValid
                    bind:filteredItems
                    bind:selectedItems
                    selectedDC={selectedDC.map((x) => x.id)}
                    selectedStatus={statusSearchArray.map((x) => x.id)}
                    screenMode={acScreenMode}
                    on:history={handleEvents}
                    on:editAmendmentCountryRow={handleEvents}
                    on:deleteAmendmentCountryRow={handleEvents}
                    on:batchDeleteAmendmentCountryRows={handleEvents}
                />
            </div>
        </Paper>
    </section>
</section>

<FullscreenLoader open={apiCallProcessing} text={apiCallProcessingMessage} />

{#key item}
    <AcItemDetail
        open={acDialogOpen}
        title={acDialogType == "add" ? $_("add_item") : $_("edit_item")}
        item={{ ...item }}
        screenMode={acScreenMode}
        type={acDialogType}
        dcList={$dcList}
        warehouseNameForDisplay={findWarehouseName(item?.warehouseId)}
        on:reject={() => {
            item = resetAmendmentCountryItem();
            acDialogOpen = false;
        }}
        on:AmendmentCountryDialogSave={handleEvents}
    ></AcItemDetail>
{/key}

<ConfirmationDialog
    open={amendmentCountryDeleteConfirmationOpen}
    title={$_("delete_amendment_country")}
    noBtnText={$_("no")}
    itemData={null}
    yesBtnText={$_("yes")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        amendmentCountryDeleteConfirmationOpen = false;
    }}
    on:confirm={() => {
        deleteAc(acScreenMode, deleteId);
        amendmentCountryDeleteConfirmationOpen = false;
    }}
>
    {$_("delete_selected_amendment_country", { values: { rows: deleteId.length } })}
</ConfirmationDialog>

<ConfirmationDialog
    open={confirmationOpen}
    title={$_("no_rows_are_selected")}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={false}
    headerColors="tescoblue white-text"
    itemData={null}
    on:reject={() => {
        confirmationOpen = false;
    }}
    on:confirm={() => {
        confirmationOpen = false;
        batchActionConfirmed(confirmationAction);
    }}
>
    {$_(confirmationText, { values: { action: confirmationAction } })}
</ConfirmationDialog>

<ConfirmationDialog
    open={confirmDialogOpen}
    title={$_(confirmDialogTitle)}
    noBtnText={$_("no")}
    yesBtnText={$_("yes")}
    big={false}
    itemData={null}
    headerColors="tescoblue white-text"
    on:reject={() => {
        confirmDialogOpen = false;
    }}
    on:confirm={() => {
        confirmDialogOpen = false;
        assignQty(confirmDialogData);
    }}
>
    {$_(confirmDialogText, { values: { assignable: confirmDialogValueAssignable, notAssignable: confirmDialogValueNotAssignable } })}
</ConfirmationDialog>

<FilesUploadDialog
    bind:filesUploadDialogOpen
    bind:filesUploadState
    {uploadFinishedCount}
    {filesUploadCount}
    {filesStatus}
    {validationFinishedCount}
    items={acItems}
    on:clearAllData={handleEvents}
/>

<CaHistoryDialog
    open={showSADialog}
    {itemHistoryData}
    bind:loadingHistoryData
    on:closeHistoryDialog={() => {
        showSADialog = false;
    }}
    on:downloadRow={handleEvents}
/>

<style>
    section.header {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        width: 100%;
    }
</style>
