<script>
    import { _ } from "svelte-i18n";
    import Flex from "svelte-flex";
    import Paper from "@smui/paper";

    import { getExchangeRates, saveExchangeRate, updateExchangeRate, deleteExchangeRate } from "../../../../api/ExchangeRate";
    import { currencyList } from "../../../../stores/AppConfig";
    import { snackbarSuccess } from "../../../../stores/AppStatus";
    import { handleApiError } from "../../../../stores/errorStore";

    import FullscreenLoader from "../../../elementary/FullscreenLoader.svelte";
    import ConfirmationDialog from "../../../dialogs/ConfirmationDialog.svelte";
    import AddItemButton from "../../common/AddItemButton.svelte";
    import ExchRateDataTable from "./ExchRateDataTable.svelte";
    import ExchRateDialog from "./ExchRateDialog.svelte";
    import ExchRateFilterBar from "./ExchRateFilterBar.svelte";

    let showDetailDialog = false;
    let detailDialogMode = "add";
    let showConfirmationDialog = false;
    let confirmationDialogQuestion = "";
    let rowId = {};

    let rowData = {};
    let allExchangeRates = [];
    let loadingData = false;

    let apiCallProcessing = false;
    let apiCallProcessingMessage = "";

    //Filter
    let currencySearchArray = [];
    let validFromSearch = "";
    let validToSearch = "";
    let validNow = false;

    // Fetch exchange rates on search input
    const apiGetExchangeRates = async (currencyIdArray, validFromSearch, validToSearch, validNow) => {
        loadingData = true;
        getExchangeRates(null, currencyIdArray, validFromSearch, validToSearch, validNow)
            .then((response) => {
                allExchangeRates = response;

                if (allExchangeRates.length == 0) {
                    console.log("getUserDelegation().empty");
                } else if (allExchangeRates.length == null) {
                    console.log("getUserDelegation().null");
                } else {
                    // Add currency detail to each exchange rate
                    allExchangeRates = allExchangeRates.map((rate) => {
                        const currencyData = $currencyList.find((c) => c.id === rate.currencyId);
                        return {
                            ...rate,
                            currencyCode: currencyData ? currencyData.code : "",
                            currencySign: currencyData ? currencyData.sign : "",
                            currencyName: currencyData ? currencyData.description : "",
                            currencyFlag: currencyData ? currencyData.flag : "",
                        };
                    });
                }
                console.log("Exchange rate list: ", allExchangeRates);
                loadingData = false;
            })
            .catch((error) => {
                console.error("getExchangeRates error", error);
                if (error?.cause?.response?.status == 401) {
                    throw error;
                }
                // Show error message
                handleApiError("failed_to_load_exchange_rates", error, true);
                loadingData = false;
            });
    };

    const deleteRow = async () => {
        if (rowId) {
            apiCallProcessing = true;
            apiCallProcessingMessage = $_("processing_wait");
            try {
                await deleteExchangeRate(rowId);
                allExchangeRates = allExchangeRates.filter((x) => x.id !== rowId);
                $snackbarSuccess.text = $_("exchange_rate_deleted");
                $snackbarSuccess.element && $snackbarSuccess.element.open();
                apiCallProcessing = false;
            } catch (error) {
                apiCallProcessing = false;
                console.error("Deletion error", error);
                if (error?.cause?.response?.status == 401) {
                    throw error;
                }
                // Show error message
                handleApiError("delete_ex_rate_row", error, true);
            }
            rowId = null;
        }
    };

    const saveChanges = async () => {
        apiCallProcessing = true;
        apiCallProcessingMessage = $_("processing_wait");

        // Ensure valueInEur is a string and replace ',' with '.'
        if (typeof rowData.valueInEur === "string") {
            rowData.valueInEur = Number(rowData.valueInEur.replace(",", "."));
        } else {
            rowData.valueInEur = Number(rowData.valueInEur);
        }

        try {
            if (detailDialogMode === "add") {
                const inputRate = rowData;
                const newRate = await saveExchangeRate(inputRate);
                apiCallProcessing = false;
                // Add currency data
                const currencyData = $currencyList.find((c) => c.id === newRate.currencyId);
                if (currencyData) {
                    rowData.currencySign = currencyData.sign;
                    rowData.currencyCode = currencyData.code;
                    rowData.currencyName = currencyData.description;
                    rowData.currencyFlag = currencyData.flag;
                }
                allExchangeRates = [...allExchangeRates, rowData];
                $snackbarSuccess.text = $_("exchange_rate_created");
            } else {
                await updateExchangeRate(rowData);
                const index = allExchangeRates.findIndex((x) => x.id === rowData.id);
                allExchangeRates[index] = rowData;
                allExchangeRates = [...allExchangeRates];
                apiCallProcessing = false;
                $snackbarSuccess.text = $_("exchange_rate_updated");
            }
            $snackbarSuccess.element && $snackbarSuccess.element.open();
        } catch (error) {
            apiCallProcessing = false;
            console.error("Save error", error);
            if (error?.cause?.response?.status == 401) {
                throw error;
            }
            // Show error message
            handleApiError("save_failed", error, true);
        }
    };

    // Function to validate and convert valueInEur
    function validateValueInEur(value) {
        if (typeof value === "string") {
            // Replace comma with dot and check if the resulting string is a finite number
            return Number.isFinite(+value.replace(",", "."));
        } else {
            // Directly check if the value is a finite number
            return Number.isFinite(value);
        }
    }

    const handleEvents = (event) => {
        console.log("handleExchangeRateEvents");
        console.log({ event });

        switch (event.type) {
            case "loadList":
                console.log("loadList");
                // Extract the parameters from event.detail
                const { currencySearchArray, validFromSearch, validToSearch, validNow } = event.detail;
                const currencyIdArray = currencySearchArray.map((currency) => currency.id);
                apiGetExchangeRates(currencyIdArray, validFromSearch, validToSearch, validNow);
                break;
            case "addItemBtnClicked":
                console.log("addItemBtnClicked");
                rowData = {};
                detailDialogMode = "add";
                showDetailDialog = true;
                break;
            case "editRow":
                console.log("editRow");
                rowData = allExchangeRates.find((rate) => rate.id === event.detail);
                detailDialogMode = "edit";
                showDetailDialog = true;
                break;
            case "deleteRow":
                console.log("deleteRow");
                const data = event.detail;
                rowId = data.id;
                confirmationDialogQuestion = $_("do_you_want_to_delete_this_exchange_rate", {
                    values: { name: $_(data.currencyName), value: data.valueInEur, sign: data.currencySign, validFrom: data.validFrom },
                });
                showConfirmationDialog = true;
                break;
            default:
                console.log("Unknown event: " + event.type);
        }
    };
</script>

<section class="w-100 header tescoblue-border-bottom-thin sticky-week-header">
    <Flex direction="row" class="grey lighten-3 tescoblue-text w-100 bs-bb pr-4 gap-1 pt-3 pb-3 pl-3 pr-3" justify="between">
        <Flex direction="row" justify="between" class="gap-1 w-100">
            <div class="w-100 font-weight-bold">
                {$_("exchange_rates")}
            </div>
            <Flex direction="row" justify="end" class="w-100 gap-1">
                <AddItemButton name="add_new_exch_rate" hint="add_new_exch_rate" on:addItemBtnClicked={handleEvents} />
            </Flex>
        </Flex>
    </Flex>
</section>

<section class="filters">
    <Paper class="grey lighten-3 w-100 bs-bb pt-2 pb-2">
        <Flex direction="column" class="gap-1" align="stretch">
            <section class="w-100">
                <ExchRateFilterBar bind:currencySearchArray bind:validFromSearch bind:validToSearch bind:validNow on:loadList={handleEvents} />
            </section>
        </Flex>
    </Paper>
</section>

<section class="data w-100 tescoblue-border-top-thin">
    <Paper class="grey lighten-3 w-100 bs-bb pt-3">
        <div class="smui-paper__content bs-bb">
            <ExchRateDataTable
                {allExchangeRates}
                {currencySearchArray}
                {validFromSearch}
                {validToSearch}
                {validNow}
                {loadingData}
                on:editRow={handleEvents}
                on:deleteRow={handleEvents}
            />
        </div>
    </Paper>
</section>

<FullscreenLoader open={apiCallProcessing} text={apiCallProcessingMessage} />

<ConfirmationDialog
    open={showDetailDialog}
    title={detailDialogMode === "add" ? $_("add_new_exchange_rate") : $_("edit_exchange_rate")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    itemData={null}
    headerColors="tescoblue white-text"
    dataValid={rowData.currencyId && rowData.validFrom && validateValueInEur(rowData.valueInEur)}
    on:reject={() => {
        showDetailDialog = false;
        rowData = {};
    }}
    on:confirm={() => {
        showDetailDialog = false;
        saveChanges();
    }}
>
    <ExchRateDialog bind:rowData />
</ConfirmationDialog>

<ConfirmationDialog
    open={showConfirmationDialog}
    title={$_("delete")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    itemData={null}
    on:reject={() => {
        showConfirmationDialog = false;
    }}
    on:confirm={() => {
        showConfirmationDialog = false;
        deleteRow();
    }}
>
    {confirmationDialogQuestion}
</ConfirmationDialog>
