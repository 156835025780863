import { _ } from 'svelte-i18n';
import { getAmendmentCountry as apiGetAmendmentCountry, getAmendmentCountryProduct as apiGetAmendmentCountryProduct } from '../../../api/AmendmentCountry';
import { getProduct as apiGetProduct } from '../../../api/Product';
import { isDateValueToCheck, formatDate, formatDateTime, excelDateToDate, validateDateField } from "../lib/Functions"

function isNumber(value) {
	return typeof value === 'number' && !isNaN(value);
}

export function validateDataType(fieldValue, fieldName) {
	if (!isNumber(fieldValue)) {
		return { priority: getPriorityForField(fieldName), message: `${fieldName}_must_be_number`, field: fieldName, type: 'E' };
	}
	return null;
}

function getPriorityForField(fieldName) {
	const priorities = {
		week: 1,
		productId: 3,
		warehouseId: 2,
		tpnb: 3,
		units: 5,
		amendVol: 4
	};
	return priorities[fieldName] || 4;
}

export function validateVolume(volume, field, originalVol) {
	// Datatype check
	const typeError = validateDataType(volume, field);
	if (typeError) return typeError;

	if (volume === null || volume === undefined) {
		return { priority: 4, message: `amend_volume_missing`, field, type: 'E' };
	}

	if (volume < 0) {
		if (volume < 0 && -volume > originalVol) {
			return { priority: 4, message: `negative_amend_volume_higher_than_original_purchase_volume`, field, type: 'E' };
		}
	}
	if (volume > 10000000) {
		return { priority: 4, message: `amend_volume_out_of_range`, field, type: 'E' };
	}

	return null;
}

export function validateUnits(units, productUnits) {
	const errorField = 'units';
	if (units === null || units === undefined) {
		return { priority: 5, message: 'missing_units', field: errorField, type: 'E' };
	}

	if (units.toLowerCase() !== productUnits.toLowerCase()) {
		return {
			priority: 5, message: 'units_mismatch_or_missing', field: errorField, type: 'E'
		};
	}
	return null;
}

export function validateTPNBExistence(productDetail) {
	console.log('================= validateTPNBExistence ==============');
	if (!productDetail) {
		return { priority: 3, message: 'missing_country_tpnb_or_forecast_not_exist', field: 'tpnb', type: 'E' };
	}
	return null;
}

export async function validateExistence(productId, warehouseId, field, productMap, productDetail) {
	console.log('================= validateExistence ==============');
	console.log('productId = '+productId);
	console.log({productMap});
	var product = [...Array.from(productMap.values())].filter(i => (i.id == productId && i.warehouseId == warehouseId))[0];
	console.log({product});
	if (!product) {
		product = [...Array.from(productMap.values())].filter(i => (i.id == productId))[0];
		if (!product) {
			// Check if product exist
			const ceProduct = await apiGetProduct(productId);
			console.log({ceProduct});
			if (!ceProduct){
				return { priority: 3, message: 'product_not_found', field, type: 'E' };
			} else {
				if (productId != productDetail.id){
					return { priority: 3, message: 'invalid_cetpn_and_tpnb_combination', field: field, type: 'E' };
				} else {
					return { priority: 3, message: 'product_has_no_forecast', field, type: 'E' };
				}
			}
		} else {
			if (productId != productDetail.id){
				return { priority: 3, message: 'invalid_cetpn_and_tpnb_combination', field: field, type: 'E' };
			} else {
				return { priority: 3, message: 'product_has_no_forecast', field, type: 'E' };
			}
		}
	} else {
		if (productId != productDetail.id){
			return { priority: 3, message: 'invalid_cetpn_and_tpnb_combination', field: field, type: 'E' };
		} else if (product.purchasedInd == 'N') {
			return { priority: 3, message: 'product_on_this_forecast_not_bought', field, type: 'E' };
		}
	}
	return null;
}

export const duplicityCheckUploadedACData = async (data) => {
	console.log('================= duplicityCheckUploadedACData ==============');

	let idsList = [...data]
		.filter(item => item.tpnb && item.warehouseId && item.deliveryDate)
		.map(item => [item.tpnb, item.warehouseId, item.deliveryDate].join('|'));
	let duplicities = idsList.filter((item, index) => idsList.indexOf(item) !== index);

	data.forEach(x => {
		const duplicityIndex = duplicities.indexOf([x.tpnb, x.warehouseId, x.deliveryDate].join('|'));
		const errorMessageIndex = x.errors.findIndex(error => error.message === 'duplicate_dc_item_delivery_date_in_file');

		if (x.tpnb && x.warehouseId && duplicityIndex !== -1) {
			// Duplicity found but no error message exists => add the error message
			if (errorMessageIndex === -1) {
				x.errors.push({ priority: 3, message: 'duplicate_dc_item_delivery_date_in_file', field: 'tpnb', type: 'E' });
			}
		} else {
			// Duplicity not found and error message exists => remove the error message
			if (errorMessageIndex !== -1) {
				x.errors.splice(errorMessageIndex, 1);
			}
		}
	});
};

export const validateACExists = async (r, acMap) => {
	console.log('================= validateACExists ==============');
	console.log({ r });
	console.log({ acMap });

	let ac = null;
	let err = null;
	if (!acMap || !(acMap instanceof Map)) {
		console.log('Empty acMap or is not Map. Calling WS to get data');
		var res = await apiGetAmendmentCountry(null, null, r.year, r.week, null, r.warehouseId, r.productId, null, r.tpnb, r.deliveryDate, null)
		console.log({res});
		if (res && res.length > 0) {
			// Country Amendment exists already
			if (res[0].status == 'new'){
				// Existing country amendment has status NEW => Warning
				err = { message: 'country_amendment_exists_already', field: 'tpnb', type: 'W', acId: res[0].id };
			} else {
				// Existing country amendment has status != NEW => Error -> can't overwrite when it is in rogress
				err = { message: 'country_amendment_in_progress_exists_already', field: 'tpnb', type: 'E', acId: res[0].id };
			}
		}
	} else {
		console.log('acMap contains data');
		let key = r.country+'-'+r.warehouseId+'-'+/*p.id+'-'+*/r.tpnb+'_'+r.deliveryDate;
		console.log({key});
		if (acMap.has(key)) { ac = acMap.get(key); }
		console.log({ac});
		if (ac && ac.id) {
			// Country Amendment exists already
			console.log('Add warning');
			if (ac.status == 'new'){
				// Existing country amendment has status NEW => Warning
				err = { message: 'country_amendment_exists_already', field: 'tpnb', type: 'W', acId: ac.id };
			} else {
				// Existing country amendment has status != NEW => Error -> can't overwrite when it is in rogress
				err = { message: 'country_amendment_in_progress_exists_already', field: 'tpnb', type: 'E', acId: ac.id };
			}
		}
	}

	return err;
}

export const validateUploadedACData = async (data, selectedWeek) => {
	console.log('================= validateUploadedACData ==============');

	try {
		const [productResponse, amendmentResponse] = await Promise.all([
			apiGetAmendmentCountryProduct(null, null, selectedWeek.tesco_year, selectedWeek.tesco_week, null, null, null, 'N'),
			apiGetAmendmentCountry(null, null, selectedWeek.tesco_year, selectedWeek.tesco_week, null, null, null, null, null, null, null)
		]);

		const productMap = new Map(productResponse.map(p => [p.country+'-'+p.warehouseId+'-'+/*p.id+'-'+*/p.tpnb, p]));
		const amendmentCountryMap = new Map(amendmentResponse.map(p => [p.country+'-'+p.warehouseId+'-'+/*p.id+'-'+*/p.tpnb+'_'+p.deliveryDate, p]));
		console.log({productMap});
		console.log({amendmentCountryMap});
		data.forEach(x => x.year = selectedWeek.tesco_year);
		
		// Create list of possible delivery dates in week
		let weekDatesList = [];
		for (let i = 0; i <=6; i++ ){
			weekDatesList.push({day: i+1, date: (new Date(selectedWeek.week_start)).addDays(i), wsDate: (new Date(selectedWeek.week_start)).addDays(i).toWSDate()});
		} 
		console.log({weekDatesList});

		for (let r of data) {
			r.errors = [];
			r.selected = false;
			// Week validation
			if (r.week != selectedWeek.tesco_week) {
				r.errors.push({ priority: 1, message: 'wrong_week_number', field: 'week', type: 'E' });
			}

			// Validate tpnb
			const dataTypeError = validateDataType(r.tpnb, 'tpnb');
			if (dataTypeError) r.errors.push(dataTypeError);
			else {
				const detailKey = `${r.country}-${r.warehouseId}-${r.tpnb}`;
				console.log({detailKey});
				const productDetail = productMap.get(detailKey);
				const tpnbError = validateTPNBExistence(productDetail);
				const productError = (!tpnbError)? await validateExistence(r.productId, r.warehouseId, 'productId', productMap, productDetail): null;
				if (productError) r.errors.push(productError);

				let deliveryDate,
					createdDate;

				// Delivery Date checking
				if (isDateValueToCheck(r.deliveryDate)) {
					deliveryDate = excelDateToDate(r.deliveryDate);
					console.log('deliveryDate = '+deliveryDate);
					r.deliveryDate = formatDate(deliveryDate);
					const deliveryDateError = validateDateField(new Date(r.deliveryDate), 'deliveryDate', selectedWeek.week_start, selectedWeek.week_end);
					if (deliveryDateError) {
						r.errors.push(deliveryDateError);
					} else if (!tpnbError && !productError)  {
						// Get original volume
						let dayId = [...weekDatesList].filter(d => (d.wsDate == r.deliveryDate))[0].day;
						r.originalVol = productDetail['d' + dayId + 'PurchasedVol'] ?? 0;

						// Validate amendment volume
						const volumeError = validateVolume(r.amendVol, 'amendVol', r.originalVol);
						if (volumeError) {
							r.errors.push(volumeError);
						}
					}
				} else {
					r.errors.push({ priority: 9, message: 'delivery_date_invalid_or_empty', field: 'deliveryDate', type: 'E' });
				}

				// Created (amendment) Date checking
				console.log('r.created = '+r.created);
				if (isDateValueToCheck(r.created)) {
					createdDate = excelDateToDate(r.created);
					console.log('createdDate = '+createdDate);
					r.created = formatDateTime(createdDate);
					console.log('r.created = '+r.created);
					const createdDateError = validateDateField(new Date(r.created), 'created', null, null);
					if (createdDateError) {
						r.errors.push(createdDateError);
					}
				} else {
					r.errors.push({ priority: 9, message: 'amendment_date_invalid_or_empty', field: 'created', type: 'W' });
				}


				if (tpnbError) 
					r.errors.push(tpnbError);
				else {
					r.tpnbDescription = productDetail.tpnbDescription;
					r.productDetailId = productDetail.productDetailId;
					r.forecastId = productDetail.forecastId;
					r.productDetail = productDetail;
					r.productDescription = productDetail.productDescription;

					if (r.units === "" || r.units === null || r.units === undefined) {
						r.units = productDetail.units;
					} else {
						const unitsError = validateUnits(r.units, productDetail.units);
						if (unitsError) r.errors.push(unitsError);
					}

					// Check if Country amendment exists already
					const amendmentCountryExists = await validateACExists(r, amendmentCountryMap);
					console.log({amendmentCountryExists});
					if (amendmentCountryExists) {
						if (amendmentCountryExists.type == 'E'){
							r.errors.push(amendmentCountryExists);
						} else {
							r.warnings.push(amendmentCountryExists);
						}
						r.id = amendmentCountryExists.acId;
					}
				}
			}

		}
	} catch (err) {
		console.error("Error fetching Product/Local item data in validateUploadedACData:", err);
	}

	return data;
};