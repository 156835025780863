<script>
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import CountryFilter from "../common/CountryFilterBar.svelte";

    export let countrySearch = [];
</script>

<Flex direction="row" justify="start" align="center" class="flex-wrap gap-05 w-100 gray lighten-4 h-100">
    <CountryFilter bind:selection={countrySearch} title={$_("country")} size="10" multiselect />
</Flex>
