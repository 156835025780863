<script lang="ts">
    import DataTable, { Body, Row, Cell, Head, Label } from "@smui/data-table";
    import DTInputCell from "../../common/DTInputCell.svelte";
    import DTCountrySelectCellAll from "../../common/DTCountrySelectCellAll.svelte";
    import { _ } from "svelte-i18n";
    import Flex from "svelte-flex";
    import { getRmsSupp } from "../../../../api/ProduceApi";

    import Icon from "@smui/textfield/icon";
    import Textfield from "@smui/textfield";
    import HelperText from "@smui/textfield/helper-text";
    import Button from "@smui/button";
    import { mdiDelete } from "@mdi/js";
    import IconButton from "@smui/icon-button";
    import type { SuppRmsLink } from "../../../../types/Management";

    export let presentCountries;
    export let suppRmsData: SuppRmsLink = {
        id: 0,
        supplierId: 0,
        supplierName: "",
        country: "",
        rmsSupplierNo: null,
        rmsSupplierName: "",
        currency: "",
        status: 0,
        orgContact: [],
        contact: [],
        supplierDetails: [],
        tradingPartnerId: "",
    };
    export let dataValid: boolean;
    let contactEmailChanged: boolean = false;
    export let suppRmsDialogType;
    export let subOpen = false;

    let rmskDataCheck;
    let notEditable = true;
    let errorNoHelper = false;

    function clearFields() {
        suppRmsData = {
            ...suppRmsData,
            rmsSupplierName: "",
            currency: "",
            status: null,
            tradingPartnerId: "",
        };
    }

    // Determine the editability
    $: isEditable = suppRmsDialogType === "add";

    async function fetchSupplierData() {
        console.log("Fetch");
        // Prevent on load Fetch
        if (!isEditable) {
            return [];
        }
        if (!suppRmsData.rmsSupplierNo || suppRmsData.rmsSupplierNo.toString().length !== 5 || !suppRmsData.country) {
            clearFields();
            return [];
        }
        try {
            const response = await getRmsSupp(suppRmsData.country, suppRmsData.rmsSupplierNo);

            if (response && response.length > 0) {
                const [supplier] = response;

                // Update rowData based on the supplier response
                suppRmsData = {
                    ...suppRmsData,
                    id: suppRmsData.id,
                    supplierId: suppRmsData.supplierId,
                    country: supplier.country,
                    rmsSupplierNo: supplier.supplier,
                    rmsSupplierName: supplier.name,
                    currency: supplier.currency,
                    tradingPartnerId: supplier.tradingPartnerId,
                    // Convert RMS db status
                    status: supplier.status === "Active" ? 1 : 0,
                    contact: suppRmsData.contact,
                };

                // Check if user did not change data after WS call
                rmskDataCheck = { ...suppRmsData };
                suppRmsData = suppRmsData;
                errorNoHelper = false;
            } else {
                console.log("No supplier data received");
            }
        } catch (error) {
            console.error("Error fetching suppliers:", error);
            if (error?.cause?.response?.status == 401) {
                throw error;
            }

            errorNoHelper = true;

            // Clear the fields
            clearFields();
        }
    }

    // Status
    let statusDisplay = suppRmsData?.status === 1 ? $_("active") : suppRmsData?.status === 0 ? $_("inactive") : "";
    $: statusDisplay = suppRmsData?.status === 1 ? $_("active") : suppRmsData?.status === 0 ? $_("inactive") : "";

    // Check of data changes by user for confirmation
    function deleteContact(index) {
        suppRmsData.supplierDetails = suppRmsData.supplierDetails.filter((_, i) => i !== index);
    }

    $: {
        contactEmailChanged = false;
        if (suppRmsDialogType === "edit" && suppRmsData.orgContact && suppRmsData.supplierDetails) {
            if (suppRmsData.orgContact.length !== suppRmsData.supplierDetails.length) {
                contactEmailChanged = true;
            } else {
                // Check each email for any changes
                for (let i = 0; i < suppRmsData.orgContact.length; i++) {
                    if (suppRmsData.orgContact[i].email !== suppRmsData.supplierDetails[i].email) {
                        contactEmailChanged = true;
                        break; // Exit if any change is detected
                    }
                }
            }
        }
        dataValid =
            suppRmsData &&
            suppRmsData.rmsSupplierName.trim() !== "" &&
            ((rmskDataCheck && rmskDataCheck.country == suppRmsData.country && rmskDataCheck.rmsSupplierNo == suppRmsData.rmsSupplierNo) ||
                (contactEmailChanged && suppRmsDialogType == "edit"));
    }
</script>

<DataTable class="w-100 input-form no-border pa-2">
    <Body>
        <Row>
            <Cell class="form-label">{$_("country")}</Cell>
            <DTCountrySelectCellAll bind:value={suppRmsData.country} editable={isEditable} {presentCountries} classe="white tescoblue-text custom-icon with-parameter w-100" />
        </Row>
        <Row>
            <Cell class="form-label">{$_("rms_supplier_no")}</Cell>
            <Textfield
                variant="outlined"
                type="text"
                disabled={!isEditable}
                bind:value={suppRmsData.rmsSupplierNo}
                withTrailingIcon={errorNoHelper}
                invalid={errorNoHelper}
                input$maxlength={5}
                input$autocomplete="rmsSupplierNo"
                on:input={() => fetchSupplierData()}
                required
                style="min-width: 545px;"
                class="w-100 {isEditable ? '' : 'grey lighten-2'}"
            >
                <svelte:fragment slot="trailingIcon">
                    {#if errorNoHelper}
                        <Icon class="material-icons">cancel</Icon>
                    {/if}
                </svelte:fragment>
                <svelte:fragment slot="helper">
                    {#if errorNoHelper}
                        <HelperText persistent validationMsg>{$_("supplier_not_found")}</HelperText>
                    {/if}
                </svelte:fragment>
            </Textfield>
        </Row>
        <Row>
            <Cell class="form-label">{$_("rms_supplier_name")}</Cell>
            <DTInputCell colspan={2} bind:value={suppRmsData.rmsSupplierName} editable={false} numeric={false} class="w-100 {notEditable ? 'grey lighten-2' : ''}" />
        </Row>
        <Row>
            <Cell class="form-label">{$_("currency")}</Cell>
            <DTInputCell colspan={2} bind:value={suppRmsData.currency} editable={false} numeric={false} class="w-100 {notEditable ? 'grey lighten-2' : ''}" />
        </Row>
        <Row>
            <Cell class="form-label">{$_("status")}</Cell>
            <DTInputCell colspan={2} bind:value={statusDisplay} editable={false} numeric={false} class="w-100 {notEditable ? 'grey lighten-2' : ''}" />
        </Row>
    </Body>
</DataTable>

<Flex direction="column" align="center" class="gap-1">
    <DataTable class="input-form no-border grey lighten-3" table$aria-label="Supplier RMS Contact" style="width: 100%;">
        <Head>
            <Row>
                <Cell columnId="actions" class="datatable-header">
                    <Label>{$_("actions")}</Label>
                </Cell>
                <Cell columnId="contact_email" class="datatable-header">
                    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                        <Label>{$_("contact_email")}</Label>
                        <Button title={$_("add_supplier_rms_contact_email")} class="tescoblue-text white-tescoblue-outlined pr-4 nowrap" on:click={() => (subOpen = true)}>
                            <Icon class="material-icons">add_circle</Icon>
                            {$_("add_supplier_rms_contact_email")}
                        </Button>
                    </div>
                </Cell>
            </Row>
        </Head>
        <Body>
            {#if suppRmsData.supplierDetails && suppRmsData.supplierDetails.length > 0}
                {#each suppRmsData.supplierDetails as suppDetail, index}
                    <Row>
                        <Cell>
                            <IconButton title={$_("delete")} size="button" class="tescoblue-text" on:click={() => deleteContact(index)}>
                                <svg viewBox="0 0 24 24" class="pa-0 ma-0">
                                    <path fill="currentColor" d={mdiDelete} />
                                </svg>
                            </IconButton>
                        </Cell>
                        <DTInputCell
                            colspan={2}
                            value={`${suppDetail.lastName} ${suppDetail.firstName} (${suppDetail.email})`}
                            editable={false}
                            numeric={false}
                            class="w-100 grey lighten-2"
                        />
                    </Row>
                {/each}
            {:else}
                <Row class="datatable-content">
                    <Cell colspan={10} class="text-center" style="width: 100%">{$_("no_linked_contact")}</Cell>
                </Row>
            {/if}
        </Body>
    </DataTable>
</Flex>
