<script>
    import { _ } from "svelte-i18n";

    import { selectedModule } from "../../stores/AppStatus";

    import RPScreen from "./Reports/RPScreen.svelte";
    import TescoAppLogo from "../../components/elementary/TescoAppLogo.svelte";
</script>

{#if $selectedModule}
    <RPScreen />
{:else}
    <section class="list">
        <TescoAppLogo position="center" size="big" />
    </section>
{/if}
