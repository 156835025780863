<script>
    import Paper from "@smui/paper";
    import Flex from "svelte-flex";
    import { _ } from "svelte-i18n";

    import { createListRow, updateListRow, deleteListRow } from "../../../../api/LOV";
    import { unitList, itemCategory, countryList, temperatureList } from "../../../../stores/AppConfig";
    import { snackbarSuccess } from "../../../../stores/AppStatus";
    import { handleApiError } from "../../lib/errorHandler";

    import ConfirmationDialog from "../../../dialogs/ConfirmationDialog.svelte";
    import LOVSelector from "./LOVSelector.svelte";
    import LOVDCDataTable from "./LOVDCDataTable.svelte";
    import LOVAddButton from "./LOVAddButton.svelte";
    import LOVDetailDialog from "./LOVDetailDialog.svelte";

    let selectedList;
    let selectedListIdType;
    let showDetailDialog = false;
    let detailDialogMode = "add";

    let showConfirmationDialog = false;
    let confirmationDialogQuestion = "";
    let confirmationDialogData = {};

    let rowData = {};
    let backupRowData = {};
    let workingList;

    let idNotUnique = true;

    //TODO: find better way how to define list to store connection
    let availableLists = [
        {
            id: "unitSale",
            name: $_("units"),
            list: $unitList,
            idType: "number",
        },
        {
            id: "productGroup",
            name: $_("product_group"),
            list: $itemCategory,
            idType: "number",
        },
        {
            id: "temperature",
            name: $_("temperature"),
            list: $temperatureList,
            idType: "number",
        } /*,
        {
            id: 'country' ,
            name: $_('country'),
            list: $countryList,
            idType: 'number'
        }*/,
    ];

    // Change working list on selectedList change
    $: if (selectedList) {
        workingList = availableLists.find((x) => x.id == selectedList).list;
        selectedListIdType = availableLists.find((x) => x.id == selectedList).idType;
    }

    // Verify that ID is unique on ID change
    $: if ((detailDialogMode == "add" && rowData && rowData.id) || (detailDialogMode != "add" && rowData && rowData.id && rowData.id != backupRowData.id)) {
        idNotUnique = !idIsUnique();
    } else {
        idNotUnique = false;
    }

    // Check ID, if is unique - for string lists
    const idIsUnique = () => {
        if (workingList && rowData?.id) {
            let cnt = workingList.filter((x) => x.id == rowData.id).length;

            return (detailDialogMode == "add" && cnt == 0) || (detailDialogMode == "edit" && cnt == 1);
        }

        return true;
    };

    // Get next free number for number ID type list
    const getNextId = (list) => {
        let maxId =
            list
                .map((x) => x.id)
                .sort((a, b) => a - b)
                .at(-1) || 0;
        maxId++;
        return maxId;
    };

    // Prepare new row data - ID is automatically prefiled for number type lists
    const prepareNewRow = () => {
        if (selectedListIdType == "string") {
            rowData = {
                id: null,
                description: null,
                active: true,
                orderNo: 0,
            };
        } else if (selectedListIdType == "number") {
            rowData = {
                id: getNextId(workingList),
                description: null,
                active: true,
                orderNo: 0,
            };
        } else {
            rowData = {};
        }

        detailDialogMode = "add";
        showDetailDialog = true;
    };

    // Update data in list
    const editRow = (id) => {
        rowData = workingList.filter((dc) => dc.id == id).at(0);
        backupRowData = { ...rowData };
        detailDialogMode = "edit";
        showDetailDialog = true;
    };

    // Ask for delete confirmation
    const showDeleteRowConfirmation = (id) => {
        confirmationDialogData = { id: id };
        confirmationDialogQuestion = $_("do_you_want_delete_value");
        showConfirmationDialog = true;
    };

    // Delete row
    const deleteRow = () => {
        if (confirmationDialogData && confirmationDialogData?.id && selectedList) {
            deleteListRow(selectedList, confirmationDialogData?.id)
                .then((res) => {
                    console.log({ res });

                    workingList = [...workingList.filter((x) => x.id != confirmationDialogData?.id)];

                    availableLists.find((x) => x.id == selectedList).list = [...workingList];

                    // Update original List
                    syncList(selectedList);

                    // Trigger screen refresh
                    workingList = workingList;

                    console.log({ selectedList });
                    $snackbarSuccess.text = $_("deleted");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("delete_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        }
    };

    // Revert changes for row edit
    const revertChanges = (rowData, action, idType) => {
        if (action == "edit") {
            workingList = [...workingList.filter((x) => x.id != rowData.id), backupRowData].sort((x, y) =>
                idType == "string" ? (x.id.toLocaleLowerCase() > y.id.toLocaleLowerCase() ? 1 : -1) : Number(x.id) > Number(y.id) ? 1 : -1,
            );

            // Trigger screen refresh
            workingList = workingList;
        }

        console.log({ workingList });
    };

    // Save changed into list
    const saveChanges = (rowData, action, idType) => {
        if (action == "add") {
            createListRow(selectedList, rowData)
                .then((res) => {
                    console.log({ res });
                    // Trigger screen refresh
                    rowData.id = res.at(0)?.id;
                    workingList = [...workingList, rowData].sort((x, y) => (x.id > y.id ? 1 : -1));

                    // Update original List
                    availableLists.find((x) => x.id == selectedList).list = [...workingList];

                    // Update original List
                    syncList(selectedList);

                    // Trigger screen refresh
                    workingList = workingList;

                    console.log({ workingList });
                    $snackbarSuccess.text = $_("created");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("create_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        } else {
            updateListRow(selectedList, rowData)
                .then((res) => {
                    console.log({ res });
                    // Update original List
                    availableLists.find((x) => x.id == selectedList).list = [...workingList];

                    // Update original List
                    syncList(selectedList);

                    // Trigger screen refresh
                    workingList = workingList;

                    console.log({ workingList });
                    $snackbarSuccess.text = $_("updated");
                    $snackbarSuccess.element && $snackbarSuccess.element.open();
                })
                .catch((error) => {
                    console.log(error);
                    if (!handleApiError($_("update_failed", { values: { reason: error.message } }), error, "non-fatal")) throw error;
                });
        }
    };

    // Sync list in store - TODO: do it Better!
    const syncList = (selectedList) => {
        if (selectedList == "unitSale") {
            $unitList = [...workingList];
        }

        if (selectedList == "productGroup") {
            $itemCategory = [...workingList];
        }

        if (selectedList == "temperature") {
            $temperatureList = [...workingList];
        }

        if (selectedList == "country") {
            $countryList = [...workingList];
        }
    };

    // $: {
    //     console.log({selectedList});
    //     console.log({workingList});
    //     console.log("idIsUnique", idIsUnique(rowData.id));
    //     console.log({availableLists});
    // }
</script>

<section class="w-100 header tescoblue-border-bottom-thin sticky-week-header">
    <Flex direction="row" class="grey lighten-3 tescoblue-text w-100 bs-bb pr-4 gap-1 pt-3 pb-3 pl-3 pr-3" justify="between">
        <Flex direction="row" justify="between" class="gap-1 w-100">
            <div class="w-100 font-weight-bold">
                {$_("list_of_values")}
            </div>

            <LOVSelector options={availableLists} bind:selectedList />

            <Flex direction="row" justify="end" class="w-100 gap-1">
                <LOVAddButton on:click={prepareNewRow} disabled={!selectedList} />
            </Flex>
        </Flex>
    </Flex>
</section>

<section class="data w-100">
    <Paper class="grey lighten-3 w-100 bs-bb pt-3">
        <div class="smui-paper__content bs-bb">
            {#if selectedList}
                <Flex direction="column" class="">
                    <LOVDCDataTable
                        rows={workingList}
                        on:editRow={(e) => {
                            console.log({ e });
                            editRow(e.detail);
                        }}
                        on:deleteRow={(e) => {
                            showDeleteRowConfirmation(e.detail);
                        }}
                    />
                </Flex>
            {:else}
                <Flex direction="column" class="w-100 pt-4">
                    <div class="tescoblue-text fs-1rem">
                        {$_("no_list_selected")}
                    </div>
                </Flex>
            {/if}
        </div>
    </Paper>
</section>

<ConfirmationDialog
    open={showDetailDialog}
    title={detailDialogMode == "add" ? $_("add_value") : $_("edit_value")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={true}
    itemData={null}
    headerColors="tescoblue white-text"
    dataValid={rowData.id && rowData.description && !idNotUnique}
    on:reject={() => {
        showDetailDialog = false;
        revertChanges(rowData, detailDialogMode, selectedListIdType);
    }}
    on:confirm={() => {
        showDetailDialog = false;
        saveChanges(rowData, detailDialogMode, selectedListIdType);
    }}
>
    <LOVDetailDialog bind:rowData mode={detailDialogMode} {idNotUnique} IdType={selectedListIdType} />
</ConfirmationDialog>

<ConfirmationDialog
    open={showConfirmationDialog}
    title={$_("delete")}
    noBtnText={$_("cancel")}
    yesBtnText={$_("ok")}
    big={false}
    headerColors="tescoblue white-text"
    on:reject={() => {
        showConfirmationDialog = false;
    }}
    on:confirm={() => {
        showConfirmationDialog = false;
        deleteRow();
    }}
>
    {confirmationDialogQuestion}
</ConfirmationDialog>
